import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Login from "./components/login/Login";
import Main from "./components/Main";
import IsLogin from "./security/IsLogin";
import { allPaths } from "./service/helper/allPaths";

import "antd/dist/antd.css";

const paths = [
  "/dtm",
  "/dtm/edit",
  "/virtualTests",
  "/virtualTests/edit",
  "/anotherTests",
  "/anotherTests/edit",
  "/users",
  "/statistics",
  "/settings",
  "/wrongQuestions",
  allPaths.NEWS,
  allPaths.NEWS_ID,
  allPaths.ADD_NEWS
];

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact path={paths}>
          <IsLogin>
            <Main />
          </IsLogin>
        </Route>
        <Router>
          <div>404 ERROR</div>
        </Router>
      </Switch>
      {/* <Redirect to="/dtm" /> */}
    </Router>
  );
}

export default App;
