import types from './types'
import {SettingsAction} from './actionTypes.js'
import {Dispatch} from "redux";

export const openCreateModalAction = (isOpen: boolean) => {
  return (dispatch: Dispatch<SettingsAction>) => {
    dispatch({
      type: types.SETTINGS_OPEN_CREATE_MODAL,
      payload: isOpen
    })
  }
}