export const addIdToData = (arr) => {
  if (arr.length === 0) return arr

  return arr.map((item) => ({ ...item, id: item.label }))
}

export const renameData = (arr) => {
  if (arr.length === 0) return arr

  return arr.map((item) => {
    if (item.label === '1') {
      return { ...item, label: 'Virtual' }
    }
    if (item.label === '0') {
      return { ...item, label: 'Mavzuli' }
    }
    return { ...item, label: 'DTM' }
  })
}
