import React from 'react'
import { Switch, Button, Popconfirm, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

const TableItem = (props) => {
  const {
    component,
    data,
    index,
    handleSwitch,
    handleDelete,
    handleEdit,
    currentPage,
  } = props

  return (
    <tr className="table_item">
      <td>{currentPage * 10 + index - 9}</td>
      <td className="fan ">{`${data.subject} (${data.question_count})`}</td>

      {component === 'lesson' ? <td>{data.lesson}</td> : null}

      <td>{data.language}</td>

      {component === 'dtm' ? <td>{data.year}</td> : null}
      {component === 'dtm' ? <td>№{data.variant}</td> : null}
      <Tooltip placement="right" title={`Yaratilgan sana: ${data.create_date}`}>
        <td>{data.create_date.split(' ')[0]}</td>
      </Tooltip>

      <td>
        <Switch
          checked={parseInt(data.status)}
          size="small"
          onChange={() => {
            handleSwitch(index)
          }}
        />
      </td>
      <td>
        <Button
          onClick={() => {
            handleEdit(index)
          }}
          type="default"
          icon={<EditOutlined />}
        />

        <Popconfirm
          placement="rightTop"
          title={"O'chirasizmi"}
          onConfirm={() => {
            handleDelete(index)
          }}
          okText="Ha"
          cancelText="Yo'q"
        >
          <Button icon={<DeleteOutlined />} />
        </Popconfirm>
      </td>
    </tr>
  )
}

export default TableItem
