import React, {Component} from 'react';
import { Typography} from "antd";
// import {AiOutlineSearch} from "react-icons/all";
import UsersBody from "./UsersBody";

import "../settings/Settings.css"
import "./Users.css"

class Users extends Component {
    render() {
        return (
            <div>
                <Typography className={"sectionTitle"} style={{marginTop: '3vh'}}>
                    Foydalanuvchilar
                </Typography> 
                <UsersBody />
            </div>
        );
    }
}

export default Users;
