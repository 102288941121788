import { Checkbox, Space, Spin } from "antd";
import React, { FC, memo, useEffect, useState } from "react";
import Request from "../../../service/request/Request";

interface LessonFilterDrawerProps {
  id: number;
  setSectionChange: React.Dispatch<React.SetStateAction<any[]>>;
  sectionChange: any[];
  activeId: number;
}

const LessonFIlterDrawerSection: FC<LessonFilterDrawerProps> = ({
  id,
  setSectionChange,
  sectionChange,
  activeId,
}) => {
  const [sectionData, setSectionData] = useState({ loading: false, data: [] });
  const [sectionIdGroup, setSectionIdGroup] = useState<any>([]);

  useEffect(() => {
    setSectionData({ loading: true, data: [] });
    Request.get("/section-all-with-subject?subject_id=" + id)
      .then((res) => {
        setSectionData({
          loading: false,
          data: res?.data,
        });
      })
      .catch(() => {
        setSectionData({ loading: false, data: [] });
      });
  }, [id]);

  useEffect(() => {
    if (id === activeId) {
      setSectionChange(sectionIdGroup);
    }
    // eslint-disable-next-line
  }, [id, activeId, sectionIdGroup]);

  const changeSection = (e: any) => {
    setSectionIdGroup([...e]);
    setSectionChange([...e]);
  };

  return (
    <Spin spinning={sectionData.loading}>
      <Checkbox.Group
        onChange={changeSection}
        value={sectionIdGroup}
        disabled={id !== activeId}
      >
        <Space direction="vertical">
          {sectionData?.data.map((i: any) => (
            <Checkbox value={i?.id} defaultChecked={false}>
              {i?.name}
            </Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
    </Spin>
  );
};

export default memo(LessonFIlterDrawerSection);
