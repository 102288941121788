export const BASE_URL_ARTICLES = "https://universalblog.akam.uz/api";

export const allReqUrl = {
  ALL_ARTICLES: BASE_URL_ARTICLES + "/article/view",
  CREATE_ARTICLES: BASE_URL_ARTICLES + "/article/create",
  UPDATE_ARTICLES: BASE_URL_ARTICLES + "/article/update",
  CHANGE_STATUS_ARTICLES:
    BASE_URL_ARTICLES + "/article/status/change?article_id=",
  DELETE_ARTICLES: BASE_URL_ARTICLES + "/article/delete?article_id=",
  ALL_CATEGORY: BASE_URL_ARTICLES + "/category/view"
};
