import { message, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { actions } from "../../../redux/actions";
import Request from "../../../service/request/Request";
import { CreateMeta } from "./CreateMeta";
import QuestionList from "./QuestionList";
import SubjectForm from "./SubjectForm";
import TemplateDownloads from "./TemplateDownloads";
import queryString from "query-string";

const { clearOnUnmount, addBlankQuestion, setSelect, setEditingQuestions } =
  actions;

const initialState = [{ correctAnswer: 1, isChecked: false, id: null }];
const TestCreate = ({
  clearOnUnmount,
  component,
  editing,
  select,
  addBlankQuestion,
  setEditingQuestions,
  setSelect,
}) => {
  // console.log('CREATE', editing);
  const [formEditData, setFormEditData] = useState({
    lang_id: 0,
    language_name: "",
    section_id: 0,
    section_name: "",
    subject_id: 0,
    subject_name: "",
    test_id: 0,
    test_name: "",
  });

  const [isChecked, setIsChecked] = useState(editing);
  const [selectValues, setSelectValues] = useState({});
  const [id, setId] = useState(null);
  const { location, push } = useHistory();
  const [loading, setLoadning] = useState(false);
  const [isTestLoading, setIsTestLoading] = useState(false);
  const getSelectValues = useCallback(() => {
    Request.get(`/${component}-test-info`)
      .then((res) => {
        // console.log(res.data);
        setSelectValues({ ...res.data });
      })
      .catch((err) => console.log(err.message));
  }, [component, setSelectValues]);

  useEffect(() => {
    const queryString = new URLSearchParams(location.search);
    const id = queryString.get("id");
    Request.get(`/${component}-test-info`)
      .then((res) => {
        setSelectValues({ ...res.data });
      })
      .catch((err) => console.log(err.message));

    if (id) {
      setIsTestLoading(true);
      Request.post(`/${component}-test-edit`, { id })
        .then((res) => {
          setEditingQuestions(res.data.questions);
        })
        .catch((err) => {
          message.error("Test topilmadi");
          push("/");
        })
        .finally(() => setIsTestLoading(false));
      setId(id);
      return;
    }
    getSelectValues();
  }, [
    push,
    getSelectValues,
    clearOnUnmount,
    editing,
    select.id,
    setSelect,
    setEditingQuestions,
    location,
    component,
  ]);

  useEffect(() => {
    if (queryString.parse(location.search)?.id) {
      Request.get(
        "/lesson-test-create?test_id=" + queryString.parse(location.search)?.id
      ).then((res) => {
        setFormEditData(res.data);
      });
    }
  }, [location]);

  const handleConfirm = (formInput) => {
    if (component === "lesson") {
      if (queryString.parse(location.search)?.id) {
        Request.put(`/lesson-test-create`, {
          test_id: queryString.parse(location.search)?.id,
          test_name: formInput.test_name,
          language_id: formInput?.language,
          section_id: formInput?.section,
        }).then(() => {
          message.success("Test o'zgartirildi");
        });
      } else {
        Request.post(`/lesson-test-create`, {
          test_name: formInput.test_name,
          language_id: formInput?.language,
          section_id: formInput?.section,
        }).then(() => {
          message.success("Test yaratildi");
        });
      }
    } else {
      setLoadning(true);
      Request.post(`/${component}-test-create`, {
        ...formInput,
        variant: parseInt(formInput.variant),
      })
        .then((res) => {
          // console.log(res.data);
          addBlankQuestion(initialState);
          setId(res.data.id);
          setLoadning(false);
          setIsChecked((prev) => !prev);
        })
        .catch((err) => console.log(err.message));
      console.log({ ...formInput, variant: parseInt(formInput.variant) });
    }
  };

  return (
    <div className="content_body">
      <div className={`${component}_content`}>
        <SubjectForm
          selectValues={selectValues}
          handleConfirm={handleConfirm}
          isChecked={isChecked}
          component={component}
          loading={loading}
          formData={formEditData}
        />
        {isTestLoading ? (
          <Spin spinning tip="testlar yuklanmoqda">
            <div
              style={{ width: "100%", margin: "0 auto", height: "400px" }}
            ></div>
          </Spin>
        ) : (
          <>
            {component === "lesson" ? <CreateMeta /> : null}
            {isChecked ? (
              <QuestionList
                isLoading={isTestLoading}
                editing={editing}
                collectionId={id}
              />
            ) : null}
          </>
        )}
      </div>
      <div className="content_side">
        <TemplateDownloads id={id} isChecked={isChecked} />
      </div>
    </div>
  );
};
const state2props = (state) => {
  return {
    select: state.selectValues,
  };
};
export default connect(state2props, {
  clearOnUnmount,
  addBlankQuestion,
  setSelect,
  setEditingQuestions,
})(TestCreate);
