import { Table } from 'antd'
import Column from 'antd/lib/table/Column'
import React from 'react'

const TestTableInModal = ({ type, data }) => {
  return (
    <Table
      // loading={loading}
      showSizeChanger={true}
      className="table-action-cell"
      size={'middle'}
      dataSource={data}
      scroll={{ y: '53vh' }}
      pagination={{ position: ['none', 'bottomLeft'] }}
      style={{ width: '100%', padding: '3%' }}
    >
      <Column
        title="№"
        key="id"
        render={(_, __, index) => <span>{index + 1}</span>}
      />
      <Column
        title="Fan"
        key="subject"
        render={(record) => <span>{record.subject}</span>}
      />
      {type === 'lesson' ? (
        <Column
          title="Mavzu"
          key="lesson"
          render={(record) => <span>{record.lesson}</span>}
        />
      ) : null}
      {/* {type === 'dtm' ? (
        <Column
          title="Yil"
          key="year"
          render={(record) => <span>{record.year}</span>}
        />
      ) : null} */}
      <Column
        title="Til"
        key="language"
        render={(record) => <span>{record.language}</span>}
      />
      <Column
        title="Sana"
        key="create_date"
        render={(record) => <span>{record.create_date}</span>}
      />
      <Column
        title="Soni"
        key="question_count"
        render={(record) => <span>{record.question_count}</span>}
      />
    </Table>
  )
}

export default TestTableInModal
