import React, { useEffect, useState } from "react";
import { Input, Select, Button, Form } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import Request from "../../../service/request/Request";

const SubjectForm = (props) => {
  const [sectionData, setSectionData] = useState({
    loading: false,
    data: [],
  });
  const {
    loading,
    component,
    handleConfirm,
    isChecked,
    selectValues,
    select,
    formData,
  } = props;
  const [form] = Form.useForm();
  const { Option } = Select;
  const [subject_id, setSubject_id] = React.useState(null);

  useEffect(() => {
    setSubject_id(formData.subject_id === 0 ? null : formData.subject_id);
    form.setFieldsValue({
      subject: formData.subject_id === 0 ? null : formData.subject_id,
      language: formData.lang_id === 0 ? null : formData.lang_id,
      section: formData.section_id === 0 ? null : formData.section_id,
      test_name: formData.test_name === "" ? null : formData.test_name,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, selectValues]);

  useEffect(() => {
    Request.get("/section-all-with-subject?subject_id=" + subject_id).then(
      (res) => {
        setSectionData({
          data: res.data,
          loading: false,
        });
      }
    );
  }, [subject_id]);

  console.log(sectionData);

  const handleChange = (value) => {
    setSubject_id(value);
    form.setFieldsValue({ section: null });
  };

  const handleError = (error) => {
    console.log("error", error);
  };

  return (
    <Form
      className="subjectForm"
      name="form"
      onFinish={handleConfirm}
      onFinishFailed={handleError}
      form={form}
    >
      <div className="selectBox">
        <p>Fan</p>
        <Form.Item
          name="subject"
          rules={[
            {
              required: true,
              message: "Iltimos, fanni tanlang.",
            },
          ]}
        >
          <Select
            // aria-describedby = {'kdljfa'}
            disabled={isChecked}
            className="select"
            placeholder={select.subject ? select.subject : "Fan nomi"}
            onChange={handleChange}
          >
            {selectValues &&
              Array.isArray(selectValues.subjects) &&
              selectValues.subjects.map((subj) => (
                <Option value={subj.id} key={subj.id}>
                  {subj.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </div>
      <div className="selectBox">
        <p>Til</p>
        <Form.Item
          name="language"
          rules={[{ required: true, message: "Iltimos, tilni tanlang" }]}
        >
          <Select
            disabled={isChecked}
            className="select"
            placeholder={select.language ? select.language : "Tili"}
            // onChange={handleChange}
          >
            {selectValues &&
              Array.isArray(selectValues.languages) &&
              selectValues.languages.map((lang) => (
                <Option value={lang.id} key={lang.id}>
                  {lang.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </div>
      {component === "dtm" ? (
        <div className="selectBox">
          <p>Yil</p>
          <Form.Item
            name="year"
            rules={[{ required: true, message: "Iltimos, yilni tanlang" }]}
          >
            <Select
              disabled={isChecked}
              className="select"
              placeholder={select.year ? select.year : "Yili"}
              // onChange={handleChange}
            >
              {selectValues &&
                Array.isArray(selectValues.years) &&
                selectValues.years.map((year) => (
                  <Option value={year.id} key={year.id}>
                    {year.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
      ) : (
        ""
      )}
      {component === "dtm" ? (
        <div className="selectBox">
          <p>Variant</p>
          <Form.Item
            rules={[{ required: true, message: "Iltimos, variantni tanlang" }]}
            name="variant"
          >
            <Input
              disabled={isChecked}
              className="select"
              placeholder={select.variant ? select.variant : "Variant nomeri"}
              // onChange={handleChange}
            ></Input>
          </Form.Item>
        </div>
      ) : (
        ""
      )}
      {component === "lesson" ? (
        <>
          <div className="selectBox">
            <p>Bo'lim</p>
            <Form.Item
              name="section"
              rules={[{ required: true, message: "Iltimos, bo'limni tanlang" }]}
            >
              <Select
                disabled={isChecked}
                className="select subject_select"
                placeholder={"Bo'lim tanlang"}
                // onChange={handleChange}
              >
                {sectionData.data &&
                  sectionData.data.map((section) => (
                    <Option value={Number(section.id)} key={section.id}>
                      {section.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div className="selectBox">
            <p>Mavzu</p>
            <Form.Item
              name="test_name"
              rules={[{ required: true, message: "Iltimos, mavzuni kiriting" }]}
            >
              <Input placeholder="Mavzuni kiritng" />
            </Form.Item>
          </div>
        </>
      ) : (
        ""
      )}
      {isChecked ? (
        ""
      ) : (
        <Button
          type="primary"
          htmlType="submit"
          className="subjectForm_check"
          loading={loading}
          icon={<CheckOutlined />}
        ></Button>
      )}
    </Form>
  );
};

export default connect((state) => ({ select: state.selectValues }))(
  SubjectForm
);
