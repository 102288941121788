export const allPaths = {
  DTM: "/dtm",
  DTM_EDIT: "/dtm/edit",
  VIRTUAL_TEST: "/virtualTests",
  VIRTUAL_TEST_EDIT: "/virtualTests/edit",
  ANOTHER_TEST: "/anotherTests",
  ANOTHER_TEST_EDIT: "/anotherTests/edit",
  USERS: "/users",
  STATISTICS: "/statistics",
  SETTINGS: "/settings",
  WRONG_QUESTIONS: "/wrongQuestions",
  NEWS: "/news",
  NEWS_ID: "/news/:id",
  ADD_NEWS: "/add-news"
};
