import React from "react";

function AddIcon() {
  return (
    <svg
      width="57"
      height="31"
      viewBox="0 0 57 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="53" height="27" rx="6" fill="white" />
      <rect
        x="1.25"
        y="1.25"
        width="54.5"
        height="28.5"
        rx="6.75"
        stroke="#2B65F0"
        strokeOpacity="0.5"
        strokeWidth="1.5"
      />
      <path
        d="M33.875 17.25H29.325V21.725H27.225V17.25H22.675V15.275H27.225V10.775H29.325V15.275H33.875V17.25Z"
        fill="#2B65F0"
      />
    </svg>
  );
}

export default AddIcon;
