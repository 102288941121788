import types from './types'
const {
  QUESTION_ADD,
  QUESTION_CLEAR,
  QUESTION_REMOVE,
  QUESTION_PHOTO_REMOVE,
  QUESTION_CHANGE,
  QUESTION_CONFIRM,
  QUESTION_SET_IMPORT,
  QUESTION_SET_FETCHED,
  QUESTION_EDIT,
} = types
// const initialState = [{correctAnswer: 1, isChecked: false, id:null}];
const initialState = []

export const questionReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case QUESTION_ADD:
      return state.concat(payload)

    case QUESTION_CLEAR:
      // console.log('clear!!!!!!!')
      return payload

    case QUESTION_REMOVE:
      // console.log(payload.index)
      const temp = state.slice()
      temp.splice(payload.index, 1)
      return temp

    case QUESTION_PHOTO_REMOVE:
      const temp1 = state.slice()
      temp1.splice(payload.index, 1, {
        ...temp1[payload.index],
        [payload.targetName]: null,
        isChecked: false,
      })
      document.getElementById(payload.targetName + payload.index).value = null
      return temp1

    case QUESTION_CHANGE:
      const temp2 = state.slice()
      temp2.splice(payload.index, 1, { ...payload.input, isChecked: false })
      return temp2

    case QUESTION_CONFIRM:
      const temp3 = state.slice()
      temp3[payload.index].isChecked = true
      return temp3

    case QUESTION_SET_IMPORT:
      const imported = payload.questions.map((questionObj) => {
        const { answers, question } = questionObj
        let correctAnswer = 0
        if (answers.a.is_correct) {
          correctAnswer = 1
        } else if (answers.b.is_correct) {
          correctAnswer = 2
        } else if (answers.c.is_correct) {
          correctAnswer = 3
        } else if (answers.d.is_correct) {
          correctAnswer = 4
        }
        return {
          isChecked: true,
          id: question.id,
          question: question.name,
          a: answers.a.name,
          b: answers.b.name,
          c: answers.c.name,
          d: answers.d.name,
          correctAnswer,
        }
      })
      const temp4 = state.slice()
      imported.map((item) => temp4.push(item))
      return temp4

    case QUESTION_EDIT:
      // console.log(payload.data)
      const editingQuestions = payload.data.map((questionObj) => {
        const { question, answers } = questionObj
        let correctAnswer = 0
        if (answers.a.is_correct) {
          correctAnswer = 1
        } else if (answers.b.is_correct) {
          correctAnswer = 2
        } else if (answers.c.is_correct) {
          correctAnswer = 3
        } else if (answers.d.is_correct) {
          correctAnswer = 4
        }
        return {
          isChecked: true,
          id: question.id,
          question: question.name,
          question_photo: question.image,
          a: answers.a.name,
          a_photo: answers.a.image,
          b: answers.b.name,
          b_photo: answers.b.image,
          c: answers.c.name,
          c_photo: answers.c.image,
          d: answers.d.name,
          d_photo: answers.d.image,
          correctAnswer,
        }
      })

      const temp7 = state.slice()
      editingQuestions.map((question) => temp7.push(question))

      return temp7

    case QUESTION_SET_FETCHED:
      const temp5 = state.slice()
      const { question, index } = payload
      temp5.splice(index, 1, { ...temp5[index], id: question.question_id })
      return temp5

    default:
      return state
  }
}
