import { message, Modal } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import Request from '../../../service/request/Request'
// import { saveImportQuestion } from '../../../redux/actions'

const QuestionsViewModal = ({
  visible,
  setVisible,
  data,
  test_id,
  saveImportQuestion,
}) => {
  const dispatch = useDispatch()
  const onCancel = () => {
    setVisible(false)
  }
  const onOk = () => {
    Request.post('/createtest', { tests: data, test_id })
      .then((res) => {
        dispatch(saveImportQuestion(res.data.questions))
        message.success('Test import qilindi!')
        setVisible(false)
      })
      .catch((err) => {
        console.log(err)
        message.error("Xatolik! Qayta urinib ko'ring")
      })
  }
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      width={1000}
      centered
      title="Test savollarini ko'rish"
      cancelText="Bekor qilish"
      okText="Tasdiqlash"
    >
      <div className="questionsView">
        {data?.map((question, index) => {
          const setClassName = (str) => {
            if (str.charAt(0) === '*') {
              return 'trueAnswer'
            }
          }

          const customQuestion = (que) => {
            if (que.charAt(0) === '*') {
              return que.substring(1)
            } else {
              return que
            }
          }
          return (
            <>
              <p className="que">
                {index + 1}. {question.savol}
              </p>
              <p className={setClassName(question.A)}>
                A) {customQuestion(question.A)}
              </p>
              <p className={setClassName(question.B)}>
                B) {customQuestion(question.B)}
              </p>
              <p className={setClassName(question.C)}>
                C) {customQuestion(question.C)}
              </p>
              <p className={setClassName(question.D)}>
                D) {customQuestion(question.D)}
              </p>
            </>
          )
        })}
      </div>
    </Modal>
  )
}

export default QuestionsViewModal
