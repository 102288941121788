import React, { useState } from 'react'
import QuestionStats from './QuestionStats'
import StaffStats from './StaffStats'
import './statisctics.scss'
import TestStats from './TestStats'

const StatisticsPage = () => {
  const [page2show, setPage2show] = useState('staff')

  return (
    <div className="stats">
      <h1 className="stats_title">Statistika</h1>
      <div className="stats_body">
        <div className="stats_controls">
          <div
            className={`${page2show === 'tests' ? 'active' : ''} settingsMenuItem`}
            onClick={() => setPage2show('tests')}
          >
            Testlar
          </div>
          <div
            className={`${
              page2show === 'questions' ? 'active' : ''
            } settingsMenuItem`}
            onClick={() => setPage2show('questions')}
          >
            Savollar
          </div>
          <div
            className={`${page2show === 'staff' ? 'active' : ''} settingsMenuItem`}
            onClick={() => setPage2show('staff')}
          >
            Hodimlar
          </div>
        </div>

        {page2show === 'tests' ? (
          <TestStats />
        ) : page2show === 'questions' ? (
          <QuestionStats />
        ) : (
          <StaffStats />
        )}
      </div>
    </div>
  )
}

export default StatisticsPage
