import React, { useRef, useState } from 'react'
import Column from 'antd/es/table/Column'
import Icon, { CloseOutlined } from '@ant-design/icons'
import { AiOutlineDelete, BsPencil } from 'react-icons/all'
import {
  Button,
  Checkbox,
  Empty,
  Form,
  Image,
  message,
  Modal,
  Popover,
  Radio,
  Switch,
  Table,
  Popconfirm,
} from 'antd'

import Request, { BASE_IMAGE_URL } from '../../../../service/request/Request'
import Mathjax, { MathjaxContext } from '../../common/Mathjax'
import mountainSvg from '../../common/mountainSvg'
import TextArea from 'antd/lib/input/TextArea'
import { isFile } from '../../../../service/common/isFile'
// import '../../common/common.css'

const MountainIcon = (props) => <Icon component={mountainSvg} {...props} />

const WrongQuestionsTable = (props) => {
  const {
    data,
    index,
    handleDelete,
    handleStatusSwitch,
    tableLoading,
    switchLoading,
  } = props

  const [editLoading, setEditLoading] = useState([])
  const [modalShown, setModalShown] = useState(false)
  const [previewShown, setPreviewShown] = useState(false)
  const [modalSubmitLoading, setModalSubmitLoading] = useState(false)
  const [input, setInput] = useState({})

  const pageNumber = useRef(1)

  const imgPreview = (img, width = 250, targetName) => {
    let src = ''
    if (isFile(img)) {
      src = URL.createObjectURL(img)
    } else {
      src = BASE_IMAGE_URL + img
    }
    const props = {
      src,
      width,
      alt: 'image',
    }
    return (
      <div className="img">
        {width >= 250 ? <Image {...props} /> : <img {...props} alt="logo" />}

        {width >= 250 ? (
          <Button
            danger
            size="small"
            shape="circle"
            onClick={() => {
              removeQuestionPhoto(targetName)
            }}
          >
            <CloseOutlined />
          </Button>
        ) : null}
      </div>
    )
  }

  const removeQuestionPhoto = (targetName) => {
    setInput({ ...input, [targetName]: null })
  }

  const onCheckboxChange = () => {
    setPreviewShown((prev) => {
      return !prev
    })
  }

  const handleConfirm = () => {
    const question = input
    const data = {
      question: {
        id: question.id,
        text: question.question,
      },
      answers: {
        a: {
          text: question.a,
          isCorrect: question.correctAnswer === 1 ? true : false,
        },
        b: {
          text: question.b,
          isCorrect: question.correctAnswer === 2 ? true : false,
        },
        c: {
          text: question.c,
          isCorrect: question.correctAnswer === 3 ? true : false,
        },
        d: {
          text: question.d,
          isCorrect: question.correctAnswer === 4 ? true : false,
        },
      },
    }
    const formData = new FormData()
    formData.append('question', JSON.stringify(data))
    formData.append('id', input.collectionId)

    question.question_photo &&
      isFile(question.question_photo) &&
      formData.append(`q`, question.question_photo, 'q.png')
    question.a_photo &&
      isFile(question.a_photo) &&
      formData.append(`a`, question.a_photo, 'a.png')
    question.b_photo &&
      isFile(question.b_photo) &&
      formData.append(`b`, question.b_photo, 'b.png')
    question.c_photo &&
      isFile(question.c_photo) &&
      formData.append(`c`, question.c_photo, 'c.png')
    question.d_photo &&
      isFile(question.d_photo) &&
      formData.append(`d`, question.d_photo, 'd.png')

    setModalSubmitLoading(true)
    Request.post('/add-question', formData)
      .then((res) => {
        // console.log(res.data)
        setModalSubmitLoading(false)
        setModalShown(false)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  const handleQuestionChange = (e) => {
    if (e.target.type === 'file') {
      console.log(e.target.files[0])
      if (e.target.files.length > 0 && e.target.files[0].size > 2 * 1024 * 1024) {
        return message.error(`Rasm uchun maksimal hajm 2 MegaBayt!`)
      }
      return setInput({ ...input, [e.target.name]: e.target.files[0] })
    }
    setInput({ ...input, [e.target.name]: e.target.value })
  }

  const handleEdit = (index, id) => {
    const copy = editLoading.slice()
    copy[index] = true
    setEditLoading(copy)
    Request.post('/question-view', { id: parseInt(id) }).then((res) => {
      const { question, answers } = res.data.questions[0]
      let correctAnswer = 0
      if (answers.a.is_correct) {
        correctAnswer = 1
      } else if (answers.b.is_correct) {
        correctAnswer = 2
      } else if (answers.c.is_correct) {
        correctAnswer = 3
      } else if (answers.d.is_correct) {
        correctAnswer = 4
      }
      setInput({
        collectionId: question.test_id,
        id: question.id,
        question: question.name,
        question_photo: question.image,
        a: answers.a.name,
        b: answers.b.name,
        c: answers.c.name,
        d: answers.d.name,
        a_photo: answers.a.image,
        b_photo: answers.b.image,
        c_photo: answers.c.image,
        d_photo: answers.d.image,
        correctAnswer,
      })
      copy[index] = false
      setEditLoading(copy)
      setModalShown(true)
    })
  }

  const handleOk = () => {
    setModalShown(false)
  }

  const handleCancel = () => {
    setInput({})
    setModalShown(false)
  }

  //this array fixes the bug with deletion of specific question
  //use it when
  const fields = [
    { name: ['question'], value: input.question },
    { name: ['a'], value: input.a },
    { name: ['b'], value: input.b },
    { name: ['c'], value: input.c },
    { name: ['d'], value: input.d },
  ]

  return (
    <MathjaxContext>
      <div>
        <Table
          loading={tableLoading}
          showSizeChanger={true}
          className="table-action-cell"
          onChange={(currentPageNumber) =>
            (pageNumber.current = currentPageNumber?.current)
          }
          size={'middle'}
          dataSource={data}
          pagination={{ position: ['none', 'bottomLeft'] }}
          style={{ width: '100%', padding: '3%' }}
        >
          <Column
            title="№"
            key="id"
            dataIndex="id"
            render={(_, __, index) => {
              return <span>{index + 1}</span>
            }}
          />
          <Column
            title="Xatololik Turi"
            key="type"
            dataIndex="error"
            render={(error) => <span>{error}</span>}
          />
          <Column
            className="feedback-text"
            title="Xatolik matni"
            key={'feedbackMessage'}
            dataIndex="message"
            render={(message) => (
              <span>
                {message &&
                  message.slice(0, 60) + (message.length > 60 ? '...' : '')}
              </span>
            )}
          />
          <Column
            title="Ismi"
            key="username"
            dataIndex="username"
            render={(username) => <span>{username}</span>}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(status, record, index) => (
              <Switch
                loading={switchLoading[index]}
                size="small"
                checked={Number.isInteger(status) ? status : parseInt(status)}
                onChange={() => {
                  handleStatusSwitch(
                    (pageNumber.current - 1) * 10 + index,
                    record.id
                  )
                }}
              />
            )}
          />
          <Column
            title="Action"
            key="action"
            dataIndex="status"
            render={(_, record, index) => {
              // console.log(_, record)
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <Button
                    loading={editLoading[index]}
                    onClick={() => {
                      handleEdit(index, record.id)
                    }}
                    icon={<BsPencil />}
                    className={'btn'}
                  ></Button>
                  <Popconfirm
                    title={"O'chirasizmi?"}
                    okText="Ha"
                    cancelText="Yo'q"
                    onConfirm={() => {
                      handleDelete((pageNumber.current - 1) * 10 + index)
                    }}
                  >
                    <Button icon={<AiOutlineDelete />} className={'btn'}></Button>
                  </Popconfirm>
                </div>
              )
            }}
          />
        </Table>

        <Modal
          className="wrongQuestionModal"
          visible={modalShown}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <h2>Xatolikni tuzatish</h2>
          <Checkbox onChange={onCheckboxChange} checked={previewShown}>
            Prevyuni ko'rsatish
          </Checkbox>
          <Form
            fields={fields}
            className="questionAdd wrongQuestionForm"
            onFinish={() => {
              handleConfirm(index)
            }}
          >
            <div className="question">
              <p>Savol</p>
              <Form.Item
                name="question"
                rules={[{ required: true, message: 'Iltimos savolni kiriting' }]}
              >
                <TextArea
                  id={'question' + data.id}
                  autoSize
                  name="question"
                  onChange={handleQuestionChange}
                />
              </Form.Item>

              <Popover
                content={
                  input.question_photo ? (
                    imgPreview(input.question_photo, 250, 'question_photo')
                  ) : (
                    <Empty description={<p>Hozircha rasm yo'q</p>} />
                  )
                }
                placement="top"
              >
                <label
                  style={input.question_photo ? { background: 'none' } : null}
                  htmlFor={`question_photo${index}`}
                  className="photoLabel"
                >
                  <input
                    accept="image/*"
                    name="question_photo"
                    id={`question_photo${index}`}
                    type="file"
                    onChange={handleQuestionChange}
                    className="d-none photoInput"
                  />
                  {input.question_photo ? (
                    imgPreview(input.question_photo, 30)
                  ) : (
                    <MountainIcon />
                  )}
                </label>
              </Popover>
            </div>

            <div
              className={
                previewShown && input.question && input.question.length > 0
                  ? 'preview'
                  : 'd-none'
              }
              id="test"
            >
              <Mathjax expression={input.question} />
            </div>

            <ol className="variantList">
              <Radio.Group
                name="correctAnswer"
                onChange={handleQuestionChange}
                value={input.correctAnswer}
              >
                <li>
                  <p>A</p>
                  <Form.Item
                    name="a"
                    rules={[
                      {
                        required: true,
                        message: 'Iltimos javobning A variantini kiriting',
                      },
                    ]}
                  >
                    <TextArea
                      id={'a' + data.id}
                      autoSize
                      name="a"
                      onChange={handleQuestionChange}
                    />
                  </Form.Item>

                  <Popover
                    content={
                      input.a_photo ? (
                        imgPreview(input.a_photo, 250, 'a_photo')
                      ) : (
                        <Empty description={<p>Hozircha rasm yo'q</p>} />
                      )
                    }
                    placement="top"
                  >
                    <label
                      style={input.a_photo ? { background: 'none' } : null}
                      htmlFor={`a_photo${index}`}
                      className="photoLabel"
                    >
                      <input
                        accept="image/*"
                        name="a_photo"
                        id={`a_photo${index}`}
                        type="file"
                        onChange={handleQuestionChange}
                        className="d-none photoInput"
                      />
                      {input.a_photo ? (
                        imgPreview(input.a_photo, 30)
                      ) : (
                        <MountainIcon />
                      )}
                    </label>
                  </Popover>

                  <Radio value={1}></Radio>
                </li>

                {previewShown && input.a && input.a.length > 0 ? (
                  <div className="preview">
                    <Mathjax expression={input.a} />
                  </div>
                ) : null}

                <li>
                  <p>B</p>
                  <Form.Item
                    name="b"
                    rules={[
                      {
                        required: true,
                        message: 'Iltimos javobning B variantini kiriting',
                      },
                    ]}
                  >
                    <TextArea
                      id={'b' + data.id}
                      autoSize
                      name="b"
                      onChange={handleQuestionChange}
                    />
                  </Form.Item>

                  <Popover
                    content={
                      input.b_photo ? (
                        imgPreview(input.b_photo, 250, 'b_photo')
                      ) : (
                        <Empty description={<p>Hozircha rasm yo'q</p>} />
                      )
                    }
                    placement="top"
                  >
                    <label
                      style={input.b_photo ? { background: 'none' } : null}
                      htmlFor={`b_photo${index}`}
                      className="photoLabel"
                    >
                      <input
                        accept="image/*"
                        name="b_photo"
                        id={`b_photo${index}`}
                        type="file"
                        onChange={handleQuestionChange}
                        className="d-none photoInput"
                      />
                      {input.b_photo ? (
                        imgPreview(input.b_photo, 30)
                      ) : (
                        <MountainIcon />
                      )}
                    </label>
                  </Popover>

                  <Radio value={2}></Radio>
                </li>

                {previewShown && input.b && input.b.length > 0 ? (
                  <div className="preview">
                    <Mathjax expression={input.b} />
                  </div>
                ) : null}

                <li>
                  <p>C</p>
                  <Form.Item
                    name="c"
                    rules={[
                      {
                        required: true,
                        message: 'Iltimos javobning C variantini kiriting',
                      },
                    ]}
                  >
                    <TextArea
                      id={'c' + data.id}
                      autoSize
                      name="c"
                      onChange={handleQuestionChange}
                    />
                  </Form.Item>

                  <Popover
                    content={
                      input.c_photo ? (
                        imgPreview(input.c_photo, 250, 'c_photo')
                      ) : (
                        <Empty description={<p>Hozircha rasm yo'q</p>} />
                      )
                    }
                    placement="top"
                  >
                    <label
                      style={input.c_photo ? { background: 'none' } : null}
                      htmlFor={`c_photo${index}`}
                      className="photoLabel"
                    >
                      <input
                        accept="image/*"
                        name="c_photo"
                        id={`c_photo${index}`}
                        type="file"
                        onChange={handleQuestionChange}
                        className="d-none photoInput"
                      />
                      {input.c_photo ? (
                        imgPreview(input.c_photo, 30)
                      ) : (
                        <MountainIcon />
                      )}
                    </label>
                  </Popover>

                  <Radio value={3}></Radio>
                </li>
                {previewShown && input.c && input.c.length > 0 ? (
                  <div className="preview">
                    <Mathjax expression={input.c} />
                  </div>
                ) : null}

                <li>
                  <p>D</p>
                  <Form.Item
                    name="d"
                    rules={[
                      {
                        required: true,
                        message: 'Iltimos javobning D variantini kiriting',
                      },
                    ]}
                  >
                    <TextArea
                      id={'d' + data.id}
                      autoSize
                      name="d"
                      onChange={handleQuestionChange}
                    />
                  </Form.Item>

                  <Popover
                    content={
                      input.d_photo ? (
                        imgPreview(input.d_photo, 250, 'd_photo')
                      ) : (
                        <Empty description={<p>Hozircha rasm yo'q</p>} />
                      )
                    }
                    placement="top"
                  >
                    <label
                      style={input.d_photo ? { background: 'none' } : null}
                      htmlFor={`d_photo${index}`}
                      className="photoLabel"
                    >
                      <input
                        accept="image/*"
                        name="d_photo"
                        id={`d_photo${index}`}
                        type="file"
                        onChange={handleQuestionChange}
                        className="d-none photoInput"
                      />
                      {input.d_photo ? (
                        imgPreview(input.d_photo, 30)
                      ) : (
                        <MountainIcon />
                      )}
                    </label>
                  </Popover>

                  <Radio value={4}></Radio>
                </li>
                {previewShown && input.d && input.d.length > 0 ? (
                  <div className="preview">
                    <Mathjax expression={input.d} />
                  </div>
                ) : null}
              </Radio.Group>
            </ol>
            <Button
              htmlType="submit"
              type="primary"
              className="save-btn"
              loading={modalSubmitLoading}
            >
              Saqlash
            </Button>
          </Form>
        </Modal>
      </div>
    </MathjaxContext>
  )
}

export default WrongQuestionsTable
