import {
  TOGGLE_STATS_MODAL,
  STATS_SET_TEST_STATS,
  STATS_SET_TEST_TYPE,
  STATS_SET_TEST_SUBJECT,
  STATS_FETCH_STAFF,
  STATS_GET_STAFF_MODAL,
  SET_FILTER_DATA,
  CLEAR_FILTER_DATA,
  STATS_CLEAR_STAFF_MODAL,
} from './types'

const initialState = {
  data: {},
  isModalOpen: false,
  tests_for_type: [],
  tests_for_subject: [],
  staffStats: [],
  filterData: {},
  staffTableData: { dtm: [], virual: [], mavzuli: [] },
}

const statsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_STATS_MODAL:
      return { ...state, isModalOpen: payload.isOpen }

    case STATS_SET_TEST_STATS:
      return {
        ...state,
        tests_for_type: payload.tests_for_type,
        tests_for_subject: payload.tests_for_subject,
      }

    case STATS_SET_TEST_TYPE:
      return {
        ...state,
        tests_for_type: payload.tests_for_type,
      }

    case STATS_SET_TEST_SUBJECT:
      return {
        ...state,
        tests_for_subject: payload.tests_for_subject,
      }
    case STATS_FETCH_STAFF:
      return {
        ...state,
        staffStats: payload.tests_for_type,
      }
    case SET_FILTER_DATA:
      return {
        ...state,
        filterData: payload.filter,
      }

    case STATS_GET_STAFF_MODAL:
      // console.log(payload.data)
      return {
        ...state,
        staffTableData: payload.data,
      }

    case STATS_CLEAR_STAFF_MODAL:
      // console.log('clear')
      return {
        ...state,
        staffTableData: { dtm: [], virual: [], mavzuli: [] },
      }

    case CLEAR_FILTER_DATA:
      return {
        ...state,
        filterData: payload.filter,
      }

    default:
      return state
  }
}

export default statsReducer
