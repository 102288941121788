import React, { FC } from "react";

const EditIcon: FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9512 14.0074C15.2543 14.0074 15.5 14.2483 15.5 14.5454C15.5 14.8177 15.2935 15.0428 15.0257 15.0784L14.9512 15.0833H9.22583C8.92274 15.0833 8.67705 14.8425 8.67705 14.5454C8.67705 14.273 8.8835 14.0479 9.15136 14.0123L9.22583 14.0074H14.9512ZM9.38269 1.68047C10.4217 0.66201 12.1069 0.66201 13.1459 1.68047L14.2245 2.73779C15.2635 3.75625 15.2635 5.40816 14.2245 6.42663L6.11717 14.3738C5.65359 14.8282 5.02506 15.0832 4.36901 15.0832H1.04878C0.740346 15.0832 0.492487 14.8341 0.500174 14.5319L0.583693 11.2478C0.600298 10.6271 0.859392 10.0354 1.30722 9.59641L9.38269 1.68047ZM8.755 3.81579L2.08332 10.3572C1.83438 10.6012 1.69012 10.9307 1.68089 11.2753L1.61121 14.007L4.36901 14.0073C4.69352 14.0073 5.00559 13.8954 5.2519 13.6931L5.34108 13.613L12.0458 7.04079L8.755 3.81579ZM12.3698 2.44124C11.7594 1.84293 10.7691 1.84293 10.1588 2.44124L9.53167 3.05496L12.8217 6.27996L13.4484 5.66587C14.0249 5.1008 14.0569 4.20356 13.5445 3.60159L13.4484 3.49855L12.3698 2.44124Z"
        fill="#616E7B"
      />
    </svg>
  );
};

export default EditIcon;
