import React, { FC } from "react";

const DraggableIcon: FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      style={{ cursor: "grab" }}
      width="7"
      height="14"
      viewBox="0 0 7 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83564 2.32899C6.47877 2.32899 7.00014 1.80763 7.00014 1.1645C7.00014 0.521363 6.47877 0 5.83564 0C5.19251 0 4.67114 0.521363 4.67114 1.1645C4.67114 1.80763 5.19251 2.32899 5.83564 2.32899Z"
        fill="black"
      />
      <path
        d="M1.1645 2.32899C1.80763 2.32899 2.32899 1.80763 2.32899 1.1645C2.32899 0.521363 1.80763 0 1.1645 0C0.521363 0 0 0.521363 0 1.1645C0 1.80763 0.521363 2.32899 1.1645 2.32899Z"
        fill="black"
      />
      <path
        d="M5.83564 8.03358C6.47877 8.03358 7.00014 7.51222 7.00014 6.86909C7.00014 6.22595 6.47877 5.70459 5.83564 5.70459C5.19251 5.70459 4.67114 6.22595 4.67114 6.86909C4.67114 7.51222 5.19251 8.03358 5.83564 8.03358Z"
        fill="black"
      />
      <path
        d="M1.1645 8.03358C1.80763 8.03358 2.32899 7.51222 2.32899 6.86909C2.32899 6.22595 1.80763 5.70459 1.1645 5.70459C0.521363 5.70459 0 6.22595 0 6.86909C0 7.51222 0.521363 8.03358 1.1645 8.03358Z"
        fill="black"
      />
      <path
        d="M5.83564 13.7379C6.47877 13.7379 7.00014 13.2166 7.00014 12.5734C7.00014 11.9303 6.47877 11.4089 5.83564 11.4089C5.19251 11.4089 4.67114 11.9303 4.67114 12.5734C4.67114 13.2166 5.19251 13.7379 5.83564 13.7379Z"
        fill="black"
      />
      <path
        d="M1.1645 13.7379C1.80763 13.7379 2.32899 13.2166 2.32899 12.5734C2.32899 11.9303 1.80763 11.4089 1.1645 11.4089C0.521363 11.4089 0 11.9303 0 12.5734C0 13.2166 0.521363 13.7379 1.1645 13.7379Z"
        fill="black"
      />
    </svg>
  );
};

export default DraggableIcon;
