import React from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import { connect } from 'react-redux'
import { actions } from '../../../../redux/actions'
const { getModalData } = actions

const TableItem = ({ data, getModalData }) => {
  const handleClick = () => {
    getModalData(data.user_id, data.label)
  }
  return (
    <tr>
      <td>{data.label}</td>
      <td>{data.value}</td>
      <td>
        <AiOutlineEye className="eyeIcon" onClick={handleClick} />
      </td>
    </tr>
  )
}

export default connect(null, { getModalData })(TableItem)
