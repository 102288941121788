import React, {useState} from "react"
import {Button, Input, message, Popconfirm, Table, Typography} from "antd";
import Column from "antd/es/table/Column";
import {AiOutlineDelete, BsPencil, IoMdClose} from "react-icons/all";
import Modal from "antd/es/modal/Modal";

function Mistake({data, editMistakeFunc, deleteMistakeFunc, addNewSubjectModal, closeAddNewItemModal, addNewItemFunc, loading}) {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editModalData, setEditModalData] = useState(null);
    const [newSubjectName, setNewSubjectName] = useState(null);


    return (
        <div>
            <Table
                showSizeChanger={true}
                loading={loading}
                size={"middle"}
                dataSource={data}
                scroll={{ y: "53vh" }}
                pagination={{position: ["none", "bottomLeft"]}}
                style={{width: '100%', padding: '3%'}}
            >
                <Column width={"5%"} title="Id" key="id" render={record => (
                    <span>{record.id}</span>
                )}/>
                <Column title="Xatolik nomi" key="name" render={record => (
                    <span>{record.name}</span>
                )}/>
                <Column width={"15%"} title="Action" key="status" render={record => (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '50%',
                        justifyContent: 'space-between'
                    }}>
                        <BsPencil
                            className={"settingsDtmActionsIcon"}
                            onClick={() => {
                                setEditModalData(record)
                                setEditModalOpen(true)
                            }}/>
                        <Popconfirm
                            title={"Tasdiqlash"}
                            okText={"O'chirish"}
                            cancelText={"Ortga"}
                            onConfirm={() => {
                                message.warn("O'chirish xizmati o'chgan!")
                                // deleteMistakeFunc(record)
                            }}>
                            <AiOutlineDelete className={"settingsDtmActionsIcon"}/>
                        </Popconfirm>
                    </div>
                )}/>
            </Table>
            <Modal
                visible={editModalOpen}
                closable={false}
                onCancel={() => {
                    setEditModalOpen(false)
                }}
                footer={null}
            >
                <div>
                    <div
                        onClick={() => {
                            setEditModalOpen(false)
                        }}
                        className={"settingsSubjectEditModalCloseIconCont"}>
                        <IoMdClose/>
                    </div>
                    <Typography
                        className={"settingsSubjectEditModalTitle"}>
                        Yangilash
                    </Typography>
                    <div style={{
                        display: 'flex',
                        alignItems: "center",
                        width: '80%',
                        margin: '1vw auto'
                    }}>
                        <label className={"nameEditInputLabel"} htmlFor={"nameEditInput"}>Nomi</label>
                        <Input
                            value={editModalData && editModalData.name}
                            id={"nameEditInput"}
                            onChange={(e) => {
                                setEditModalData({
                                    ...editModalData,
                                    name: e.target.value
                                })
                            }}/>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <Button
                            type="primary"
                            className={"settingsEditModalSaveButton"}
                            onClick={() => {
                                if(editModalData.name) {
                                    editMistakeFunc(editModalData)
                                    setEditModalOpen(false)
                                } else {
                                    message.error("Xatolik kiritilmagan!")
                                }
                            }}>
                            Saqlash
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={addNewSubjectModal}
                closable={false}
                onCancel={() => {
                    closeAddNewItemModal()
                }}
                footer={null}
            >
                <div>
                    <div
                        onClick={() => {
                            closeAddNewItemModal()
                        }}
                        className={"settingsSubjectEditModalCloseIconCont"}>
                        <IoMdClose/>
                    </div>
                    <Typography
                        className={"settingsSubjectEditModalTitle"}>
                        Xatolik qo'shish
                    </Typography>
                    <div style={{
                        display: 'flex',
                        alignItems: "center",
                        width: '80%',
                        margin: '1vw auto'
                    }}>
                        <label className={"nameEditInputLabel"} htmlFor={"nameEditInput"}>Nomi</label>
                        <Input
                            id={"nameEditInput"}
                            onChange={(e) => {
                                setNewSubjectName(e.target.value)
                            }}/>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <Button
                            type="primary"
                            className={"settingsEditModalSaveButton"}
                            onClick={() => {
                                if(newSubjectName) {
                                    addNewItemFunc(newSubjectName)
                                } else {
                                    message.error("Xatolik kiritilmagan!")
                                }
                            }}>
                            Saqlash
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Mistake