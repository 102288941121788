import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import Request from '../../../service/request/Request'
import { connect } from 'react-redux'
import { actions } from '../../../redux/actions'
import QuestionsViewModal from './QuestionsViewModal'

const { saveImportQuestion } = actions

const TemplateDownloads = (props) => {
  const { isChecked, id } = props
  const [file, setFile] = useState(null)
  const [questionsView, setquestionsView] = useState([])
  const [visibleQuestionViewModal, setVisibleQuestionViewModal] = useState(false)

  useEffect(() => {
    if (file) {
      const formData = new FormData()
      formData.append('file', file)
      Request.post('/import', formData, { testId: id })
        .then((res) => {
          setquestionsView(res.data)
          setVisibleQuestionViewModal(true)
          setFile(null)
        })
        .catch((err) => {
          console.log(err)
          message.error(err.message)
        })
    }
  }, [file, id])

  const onChange = (e) => {
    const Inputfile = e.target.files[0]
    setFile(Inputfile)
    e.target.value = null
  }

  return (
    <div className="templateDownloads">
      <a
        href="https://back2.test.aim.uz/frontend/web/upload/Shablon.docx"
        className="download-btn"
        type="primary"
        // icon={}
      >
        <DownloadOutlined /> Shablon yuklab olish
      </a>
      <label
        htmlFor="import"
        className={`${isChecked ? '' : 'disabled'} import-btn`}
      >
        <DownloadOutlined rotate={90} /> Test import qilish
        <input
          disabled={!isChecked}
          accept=".doc,.docx"
          name="question_photo"
          id="import"
          type="file"
          onChange={onChange}
          className="d-none photoInput"
        />
      </label>

      <p>
        Testlarni import qilishdan oldin <span>“Shablon yuklab olish” </span>
        tugmasi orqali testlarni shablon asosida shakllantirilganini tekshiring!
      </p>
      <QuestionsViewModal
        visible={visibleQuestionViewModal}
        setVisible={setVisibleQuestionViewModal}
        data={questionsView}
        test_id={id}
        saveImportQuestion={saveImportQuestion}
      />
    </div>
  )
}
const dispatch2props = {
  saveImportQuestion,
}

export default connect(null, dispatch2props)(TemplateDownloads)
