import { Collapse, Drawer, Radio, Space } from "antd";
import React, { FC, memo, useEffect, useState } from "react";
import Request from "../../../service/request/Request";
import LessonFIlterDrawerSection from "./LessonFIlterDrawerSection";

interface LessonFilterDrawerProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  activeId: any;
  setActiveId: React.Dispatch<any>;
  activeStatus: string;
  setActiveStatus: React.Dispatch<React.SetStateAction<string>>;
  sectionChange: any[];
  setSectionChange: React.Dispatch<React.SetStateAction<any[]>>;
}

const LessonFilterDrawer: FC<LessonFilterDrawerProps> = ({
  visible,
  setVisible,
  activeId,
  setActiveId,
  activeStatus,
  setActiveStatus,
  sectionChange,
  setSectionChange,
}) => {
  const [subjectAll, setSubjectAll] = useState([]);
  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    Request.get("/subject-all").then((res) => {
      setSubjectAll(res.data.subjects);
    });
  }, []);

  const changeSubject = (e: any) => {
    setActiveId(e.target.value);
  };

  const changeStatus = (e: any) => {
    setActiveStatus(e.target.value);
  };

  const handleClear = () => {
    setActiveId(0);
    setActiveStatus("");
    setSectionChange([]);
  };

  return (
    <Drawer
      visible={visible}
      onClose={handleClose}
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Filter</span>
          <span onClick={handleClear} style={{ cursor: "pointer" }}>
            Tozalash
          </span>
        </div>
      }
      closable={false}
    >
      <Collapse bordered={false} defaultActiveKey={["1"]}>
        <Collapse.Panel header="Status" key="1">
          <Radio.Group onChange={changeStatus} value={activeStatus}>
            <Space direction="vertical">
              <Radio value="1">Faol</Radio>
              <Radio value="0">O'chirilgan</Radio>
            </Space>
          </Radio.Group>
        </Collapse.Panel>

        <Collapse.Panel header="Fanlar" key="2">
          <Radio.Group onChange={changeSubject} value={activeId}>
            <Space direction="vertical">
              {subjectAll.length > 0 &&
                subjectAll.map((subject: any, index) => {
                  return (
                    <Radio key={index} value={subject?.id}>
                      <Collapse bordered={false} activeKey={activeId}>
                        <Collapse.Panel
                          header={subject?.name}
                          key={subject?.id}
                        >
                          <LessonFIlterDrawerSection
                            id={subject?.id}
                            setSectionChange={setSectionChange}
                            sectionChange={sectionChange}
                            activeId={activeId}
                          />
                        </Collapse.Panel>
                      </Collapse>
                    </Radio>
                  );
                })}
            </Space>
          </Radio.Group>
        </Collapse.Panel>
      </Collapse>
    </Drawer>
  );
};

export default memo(LessonFilterDrawer);
