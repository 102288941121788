import {
  Button,
  Empty,
  message,
  Pagination,
  Select,
  Spin,
  Switch,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Request from "../../../service/request/Request";
import TestTableItem from "./TestTableItem";
import { connect } from "react-redux";
import { actions } from "../../../redux/actions";
import { arrayMoveImmutable } from "array-move";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import DraggableIcon from "../../../assets/icons/DraggableIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import RefreshCircleIcon from "../../../assets/icons/RefreshCircleIcon";
import CloseCircleIcon from "../../../assets/icons/CloseCircleIcon";
import "./lesson.scss";
import SingleCellularIcon from "../../../assets/icons/SingleCellularIcon";
import CreateSectionModal from "./CreateSectionModal";

const { setEditingQuestions, setSelect, clearOnUnmount } = actions;

const DragHandle = SortableHandle(() => <DraggableIcon />);

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const TestTableList = ({
  component,
  setSelect,
  clearOnUnmount,
  activeId,
  activeStatus,
  sectionChange,
}) => {
  const [swichLoading, setSwichLoading] = useState({ id: 0, loading: false });
  const [pageSize, setPageSize] = useState(30);
  const [openSectionModal, setOpenSectionModal] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState([]);
  const [selectPosition, setSelectPosition] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const { push, location } = useHistory();
  const [collections, setCollections] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {
      key: "0",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Test nomi(Oldingi tizimda mavzu nomi edi)",
      dataIndex: "lesson",
      key: "lesson",
      width: 300,
    },
    {
      title: "Bo’lim",
      dataIndex: "section",
      key: "section",
    },
    {
      title: "Fan",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Til",
      dataIndex: "language",
      key: "language",
    },
    {
      title: "Sana",
      dataIndex: "create_date",
      key: "create_date",
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      render: (text, record) => (
        <div className="edit-btn-container">
          <Switch
            checked={record?.status}
            loading={
              record?.id === swichLoading.id ? swichLoading.loading : false
            }
            onChange={(checked) => {
              setSwichLoading({ id: record.id, loading: true });
              Request.post("/test-change-status", {
                id: record?.id,
                status: checked,
              })
                .then((res) => {
                  setDataSource(
                    dataSource.map((item) => {
                      return {
                        ...item,
                        status: item.id === record.id ? checked : item.status,
                      };
                    })
                  );
                  setSwichLoading({ id: record.id, loading: false });
                })
                .catch((err) => {
                  message.error("Statusni o'zgartirib bo'lmadi!");
                  setSwichLoading({ id: record.id, loading: false });
                });
            }}
          />
          <Button
            onClick={() => push(location.pathname + "/edit?id=" + record.id)}
          >
            <EditIcon />
          </Button>
          <Button>
            <DeleteIcon />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setTableLoading(true);
    Request.get(
      `/${component}-test-menu?page=${currentPage}&size=${pageSize}&subject_id=${activeId}&status=${activeStatus}&section_ids=${sectionChange.join(
        ","
      )}`
    )
      .then((res) => {
        setCollections([]);
        setDataSource([]);
        res.data && setCollections(res.data.tests);
        res.data &&
          setDataSource(
            res.data.tests.map((item, index) => {
              return {
                ...item,
                index: index,
              };
            })
          );
        setPages(res.data.count);
        setTableLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [component, currentPage, pageSize, activeId, activeStatus, sectionChange]);

  const handleSwitch = (index) => {
    const copy = collections.slice();
    Request.post(`/test-change-status`, {
      id: collections[index].id,
      status: !parseInt(copy[index].status),
    }).then((res) => {
      copy.splice(index, 1, { ...copy[index], status: res.data.status });
      res.data && message.info(res.data.message);
      setCollections(copy);
    });
  };

  const handleDelete = (index) => {
    const copy = collections.slice();
    copy.splice(index, 1);
    Request.post(`/test-delete`, { id: collections[index].id }).then((res) => {
      res.data && message.info(res.data.message);
      setCollections(copy);
    });
  };
  const handleEdit = (index) => {
    setSelect(collections[index]);
    clearOnUnmount();
    push(location.pathname + "/edit?id=" + collections[index].id);
  };

  const handlePage = (page) => {
    setCurrentPage(page);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);

      const newCollections = newData.map((item, index) => ({
        ...item,
        place: index,
      }));

      Request.post(`/test-change-place`, [
        ...newCollections.map((item) => ({
          test_id: item.id,
          place: item.place,
        })),
      ]).then(() => {
        message.success("Amal muvoffaqqiyatli bajarildi!");
      });
      setDataSource(newCollections);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource?.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const handlePerformance = () => {
    if (selectPosition) {
      if (selectPosition === "select") {
        setOpenSectionModal(true);
      } else if (selectPosition === "delete") {
        message.info("Jiyan o'zingni bos!");
      }
    } else {
      message.info("Jiyan amalni tanla!");
    }
  };

  const handlePageSize = (pageSize) => {
    setPageSize(pageSize);
  };

  return component === "lesson" ? (
    <>
      <Table
        rowSelection={{
          onChange: (selectedRowKeys, record) => {
            setSelectedLesson(record.map((item) => item.id));
          },
        }}
        pagination={false}
        dataSource={[...dataSource]}
        loading={tableLoading}
        columns={columns}
        rowKey="index"
        className="lessonTableContainer"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        scroll={{ y: "calc(100vh - 300px)" }}
      />
      <div className="lesson_table_footer">
        <div>
          Har bir sahifadagi qatorlar soni:{" "}
          <Select defaultValue={30} onChange={handlePageSize}>
            <Select.Option value={30}>30</Select.Option>
            <Select.Option value={50}>50</Select.Option>
            <Select.Option value={100}>100</Select.Option>
          </Select>
        </div>
        <Pagination
          size="small"
          onChange={handlePage}
          defaultCurrent={1}
          pageSize={pageSize}
          total={pages}
          showSizeChanger={false}
        />
        <div className="lesson_table_footer_right">
          <Select
            placeholder=" Amalni tanlang"
            onChange={(e) => setSelectPosition(e)}
            className="selectPosition"
            suffixIcon={<SingleCellularIcon />}
            dropdownClassName="selectPositionDropdown"
            dropdownMatchSelectWidth={false}
          >
            <Select.Option value={"select"}>
              <RefreshCircleIcon /> Joylashuvni o’zgartirish
            </Select.Option>
            <Select.Option value={"delete"}>
              <CloseCircleIcon />
              O’chirish
            </Select.Option>
          </Select>
          <Button
            disabled={!selectedLesson.length > 0}
            type="primary"
            onClick={handlePerformance}
          >
            Bajarish
          </Button>
        </div>
      </div>
      <CreateSectionModal
        data={selectedLesson}
        setVisible={setOpenSectionModal}
        visible={openSectionModal}
      />
    </>
  ) : (
    <table className="content_body list_table">
      <thead>
        {component === "dtm" ? (
          <tr className="table_head">
            <td>№</td>
            <td className="fan">Fan</td>
            <td>Til</td>
            <td>Yil</td>
            <td>Variant</td>
            <td>Sana</td>
            <td>Status</td>
            <td>Action</td>
          </tr>
        ) : null}
        {component === "virtual" ? (
          <tr className="table_head">
            <td>№</td>
            <td className="fan">Fan</td>
            <td>Til</td>
            <td>Sana</td>
            <td>Status</td>
            <td>Action</td>
          </tr>
        ) : null}
        {component === "lesson" ? (
          <tr className="table_head">
            <td>№</td>
            <td className="fan">Fan</td>
            <td className="mavzu_title">Mavzu</td>
            <td>Til</td>
            <td>Sana</td>
            <td>Status</td>
            <td>Action</td>
          </tr>
        ) : null}
      </thead>
      <tbody className="table_wrapper">
        {tableLoading ? (
          <tr className="spin">
            <Spin size="large" />
          </tr>
        ) : collections.length > 0 ? (
          collections.map((item, index) => {
            return (
              <TestTableItem
                // loading={false}
                handleDelete={handleDelete}
                component={component}
                handleSwitch={handleSwitch}
                handleEdit={handleEdit}
                data={item}
                index={index}
                currentPage={currentPage}
                key={item.id}
              />
            );
          })
        ) : (
          <Empty
            description={<p>Hozircha testlar yo'q</p>}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </tbody>
      <Pagination
        size="small"
        onChange={handlePage}
        defaultCurrent={1}
        total={pages}
        showSizeChanger={false}
      />
    </table>
  );
};

export default connect(null, {
  setEditingQuestions,
  setSelect,
  clearOnUnmount,
})(TestTableList);
