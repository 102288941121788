import React from "react";

const SingleCellularIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1504_4649)">
        <path
          d="M1.12095 6.72792L6.72764 12.3346L12.3342 6.72803L1.12095 6.72792Z"
          fill="#0C4292"
        />
      </g>
      <defs>
        <clipPath id="clip0_1504_4649">
          <rect
            width="9.51487"
            height="9.51469"
            fill="white"
            transform="translate(6.72754) rotate(45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SingleCellularIcon;
