import types from './types'
import {backEndType, getMeta, MetaActions} from './actionTypes.js'

interface metaType {
  selectedSection: string | backEndType[] | getMeta[]
  subjects: backEndType[] | string | getMeta[]
  tests: backEndType[] | string | getMeta[]
  getTestsMeta: getMeta[] | string | backEndType[]
  getSubjectsMeta: getMeta[] | string | backEndType[]
  getPagesMeta: getMeta[] | string | backEndType[]
}

const initialState = {
  selectedSection: 'subject',
  subjects: [],
  tests: [],
  getTestsMeta: [],
  getSubjectsMeta: [],
  getPagesMeta: []
}

const metaReducer = (state: metaType = initialState, action: MetaActions): metaType => {
  switch (action.type) {
    case types.META_CHANGE_SECTION:
      return {...state, selectedSection: action.payload}
    case types.META_GET_SUBJECTS:
      return {...state, subjects: action.payload}
    case types.META_GET_TESTS:
      return {...state, tests: action.payload}
    case types.META_SUBJECTS:
      return {...state, getSubjectsMeta: action.payload}
    case types.META_TESTS:
      return {...state, getTestsMeta: action.payload}
    case types.META_PAGES:
      return {...state, getPagesMeta: action.payload}
    case types.META_BACK_TO_INITIAL:
      return initialState
    default:
      return state
  }
}

export default metaReducer
