import React from 'react'
import { connect, useSelector } from 'react-redux'
import { toggleStatsModal, actions } from '../../../../redux/actions'
import './table.scss'
import TableItem from './TableItem'
import StatsTableModal from './StatsTableModal'

const { clearModalData } = actions

const StatsTable = ({ isModalOpen, toggleStatsModal, clearModalData }) => {
  const staffStats = useSelector(({ stats }) => stats.staffStats)
  const handleCloseModal = () => {
    toggleStatsModal(false)
    clearModalData()
  }

  return (
    <>
      <table>
        <thead>
          <tr>
            <td>Admin</td>
            <td>Test(ta)</td>
            <td> </td>
          </tr>
        </thead>
        <tbody>
          {staffStats?.map((item, index) => (
            <TableItem data={item} key={item.user_id} index={index} />
          ))}
        </tbody>
      </table>
      <StatsTableModal
        handleCloseModal={handleCloseModal}
        isModalOpen={isModalOpen}
      />
    </>
  )
}

const state2props = (state) => ({ isModalOpen: state.stats.isModalOpen })
export default connect(state2props, { toggleStatsModal, clearModalData })(StatsTable)
