import {
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  Empty,
  Modal,
  Popover,
  Select,
  message,
} from 'antd'
import React, { createRef, useCallback, useState, useEffect } from 'react'
import moment from 'moment'

import { CloseOutlined } from '@ant-design/icons'
import { isFile } from '../../../service/common/isFile'
import Request from '../../../service/request/Request'
import phoneNumber from '../../../service/common/phoneNumber'

const { Option } = Select

const UsersModal = (props) => {
  const { title, inputData, isOpen, toggleModal, handleSubmit } = props
  const photoRef = createRef()
  const [innerData, setInnerData] = useState({ region: null, image: null })
  const [regions, setRegions] = useState([])

  const initalFetchRegions = useCallback(() => {
    if (innerData.city_id) {
      Request.post('/address-region', { id: innerData.city_id }).then((res) => {
        setRegions(res.data.regions)
      })
    }
  }, [innerData.city_id])

  useEffect(() => {
    initalFetchRegions()
  }, [initalFetchRegions])

  useEffect(() => {
    // console.log(inputData)
    setInnerData({ ...inputData })
  }, [inputData])

  const imgPreview = (img, width = 250) => {
    let src = ''
    if (isFile(img)) {
      src = URL.createObjectURL(img)
    } else {
      src = 'https://backend.test.aim.uz/frontend/web/img/' + img
    }
    const props = {
      src,
      width,
      alt: 'image',
    }
    return (
      <div className="img">
        {width >= 250 ? <Image {...props} /> : <img {...props} alt="logo" />}

        {width >= 250 ? (
          <Button
            danger
            size="small"
            shape="circle"
            onClick={() => {
              removeUserPhoto()
            }}
          >
            <CloseOutlined />
          </Button>
        ) : null}
      </div>
    )
  }

  const removeUserPhoto = () => {
    Request.post('/remove-user-image', { user_id: innerData.user_id }).then(
      (res) => {
        message.success(res.data.message)
      }
    )
    photoRef.current.value = null
    setInnerData({ ...innerData, image: null })
  }

  const handleInnerData = (e, name) => {
    // console.log(e, name)
    if (name === 'region') {
      return setInnerData({ ...innerData, region_id: e, [name]: e })
    }
    if (name) {
      return setInnerData({ ...innerData, [name]: e })
    }
    if (e.target.type === 'file') {
      return setInnerData({ ...innerData, [e.target.name]: e.target.files[0] })
    }
    setInnerData({ ...innerData, [e.target.name]: e.target.value })
  }

  // const momentDateObject = moment(Date.now())

  // const handleRegionChange = (regionId) => {
  //   console.log(regionId)
  //   setInnerData({ ...innerData, region: regionId })
  // }

  const handleFinish = (data) => {
    // const birthday = moment(data.birthday).format('YYYY-MM-DD')
    const dataToSend = {
      ...data,
      address: innerData.region_id,
      login: phoneNumber.removeMinuses(innerData.login),
      id: parseInt(innerData.id),
      usersIndex: innerData.usersIndex,
    }
    // console.log(dataToSend)
    handleSubmit(dataToSend, innerData.image)
  }

  const fetchRegions = (city_id) => {
    Request.post('/address-region', { id: city_id }).then((res) => {
      setRegions(res.data.regions)
      setInnerData({ ...innerData, region: null, city_id })
    })
  }

  const fields = [
    {
      name: ['city'],
      value: innerData.city_id ? parseInt(innerData.city_id) : null,
    },
    {
      name: ['region'],
      value: innerData.region_id ? parseInt(innerData.region_id) : null,
    },
    { name: ['full_name'], value: innerData.full_name },
    {
      name: ['birthday'],
      value: innerData.birthday ? moment(innerData.birthday) : null,
    },
    {
      name: ['login'],
      value: innerData.login ? phoneNumber.addMinuses(innerData.login) : null,
    },
    {
      name: ['gender'],
      value: innerData.gender ? parseInt(innerData.gender) : null,
    },
    { name: ['email'], value: innerData.email },
  ]

  return (
    <Modal
      className="usersModal"
      visible={isOpen}
      onOk={toggleModal}
      onCancel={toggleModal}
    >
      <Form onFinish={handleFinish} fields={fields}>
        <h2 className="usersModal_title">{title}</h2>

        <div className="usersModal_item">
          <p>
            To'liq Ismi <span style={{ color: 'red' }}>*</span>
          </p>
          <Form.Item
            name="full_name"
            rules={[{ required: true, message: "Iltimos to'liq ismni kiriting!" }]}
          >
            <Input
              placeholder="Alisher Rustamov"
              name="full_name"
              onChange={handleInnerData}
            />
          </Form.Item>
        </div>

        <div className="usersModal_item">
          <p>
            Login <span style={{ color: 'red' }}>*</span>
          </p>
          <Form.Item
            name="login"
            rules={[
              {
                required: true,
                message: 'Iltmos loginni kiriting! Misol: 93-777-66-55',
              },
              {
                min: 12,
                message: "Loginnig uzunligi 9 ta raqam bo'lishi kerak",
              },
            ]}
          >
            <Input
              placeholder="93-777-66-55"
              name="login"
              onChange={handleInnerData}
            />
          </Form.Item>
        </div>

        <div className="usersModal_item birthday">
          <p>Tug'ilgan Sana</p>
          <Form.Item name="birthday">
            <DatePicker
              placeholder="1991-08-31"
              onChange={(_, value) => {
                handleInnerData(value, 'birthday')
              }}
            />
          </Form.Item>
        </div>

        <div className="usersModal_item">
          <p>Jinsi </p>
          <Form.Item name="gender">
            <Select
              placeholder="Erkak"
              onChange={(value) => {
                handleInnerData(value, 'gender')
              }}
            >
              <Option value={1}>Erkak</Option>
              <Option value={2}>Ayol</Option>
            </Select>
          </Form.Item>
        </div>

        <div className="usersModal_item">
          <p>Viloyat</p>
          <Form.Item name="city">
            <Select onChange={fetchRegions} placeholder="Navoiy">
              {innerData.cities &&
                innerData.cities.map((city) => (
                  <Option value={city.id} key={city.id}>
                    {city.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <div className="usersModal_item">
          <p>Tuman</p>
          <Form.Item name="region">
            <Select
              placeholder="Zarafshon"
              onChange={(value) => {
                handleInnerData(value, 'region')
              }}
            >
              {regions.map((region) =>
                typeof region === 'object' ? (
                  <Option value={region.id} key={region.id}>
                    {region.name}
                  </Option>
                ) : null
              )}
            </Select>
          </Form.Item>
        </div>

        <div className="usersModal_item">
          <p>E-mail </p>
          <Form.Item name="email">
            <Input
              placeholder="Alisher91@gmail.com"
              type="email"
              name="email"
              onChange={handleInnerData}
            />
          </Form.Item>
        </div>
        <div className="usersModal_item">
          <p>Rasm </p>
          <Popover
            content={
              innerData.image ? (
                imgPreview(innerData.image, 250)
              ) : (
                <Empty description={<p>Hozircha rasm yo'q</p>} />
              )
            }
            placement="right"
          >
            <label htmlFor={`image${title.split(' ')[1]}`} className="photoLabel">
              <input
                ref={photoRef}
                accept="image/*"
                name="image"
                id={`image${title.split(' ')[1]}`}
                type="file"
                onChange={handleInnerData}
                style={{ display: 'none', position: 'absolute' }}
              />
              {/* Test rasm yuklash */}
              {innerData.image ? "Rasmni ko'rish" : `Rasm yuklash`}
            </label>
          </Popover>
        </div>
        <p style={{ marginLeft: '22%' }}>
          <span style={{ color: 'red' }}>*</span> larni kiritish shart
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Button htmlType="submit" type="primary" className="usersModal_button">
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default UsersModal
