import { Form, Button, DatePicker, Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Request from '../../../service/request/Request'
import { DiagramPie } from './DiagramPie'
import StatsTable from './statsTable/StatsTable'
import data from './templateData.json'
import { actions, getSortedData, fetchStaffStats } from '../../../redux/actions'
import { arrayReducer } from '../../../service/common/arrayReducer'

const { clearFilter } = actions
const { RangePicker } = DatePicker
const { Option } = Select

const StaffStats = ({ fetchStaffStats, getSortedData, staffStats, clearFilter }) => {
  const [subjects, setSubjects] = useState([])

  useEffect(() => {
    Request.get(`/dtm-test-info`).then(({ data }) => {
      setSubjects(data?.subjects)
    })
    fetchStaffStats()
    // eslint-disable-next-line
  }, [])

  const handleFinish = ({ date, subject }) => {
    let begin, end
    if (date) {
      console.log('shals')
      begin = moment(date[0]).format('YYYY-MM-DD')
      end = moment(data[1]).format('YYYY-MM-DD')
    }
    getSortedData({ begin, end, subject })
  }

  const onFieldsClear = (fieldName, value) => {
    if (!value) {
      clearFilter(fieldName)
    }
  }
  return (
    <div className="stats_content" style={{ marginTop: '1rem' }}>
      <div>
        <Form onFinish={handleFinish} className="stats_filter">
          <Form.Item name="date">
            <RangePicker onChange={(value) => onFieldsClear('date', value)} />
          </Form.Item>
          <Form.Item name="subject" style={{ width: '11rem' }}>
            <Select
              placeholder="Fanni tanlang"
              style={{ width: '25%' }}
              allowClear
              onChange={(value) => onFieldsClear('subject', value)}
            >
              {subjects.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Ko'rish
          </Button>
        </Form>
      </div>
      <div className="stats_view">
        <div className="stats_diagram">
          <DiagramPie data={staffStats} />
          <p style={{ textAlign: 'center' }}>
            Jami: {arrayReducer(staffStats, 'value')}
          </p>
        </div>
        <div className="stats_list">
          <StatsTable />
        </div>
      </div>
    </div>
  )
}

const state2props = ({ stats }) => ({
  staffStats: stats.staffStats,
})

export default connect(state2props, { fetchStaffStats, getSortedData, clearFilter })(
  StaffStats
)
