import axios from "axios";
import { message } from "antd";

export const BASE_URL = "https://oracle.akam.uz/backend";
export const BASE_IMAGE_URL = "https://oracle.akam.uz/frontend/web/upload/";
class Request {
  getHeaders() {
    try {
      return {
        Bearer: JSON.parse(localStorage.getItem("user")).auth_key
      };
    } catch (e) {
      return console.log(e.message);
    }
  }

  errorMessages(data) {
    if (data?.message) message.error(data.message);
  }

  getBlob(url, headers, responseType = "blob") {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url,
        // url: BASE_URL + url,
        responseType,
        headers: { ...this.getHeaders(), ...headers }
        // data: data
      })
        .then((res) => {
          resolve(res);
          // console.log('then REquest', res)
        })
        .catch((err) => {
          reject(err);
          console.log(err.response);
        });
    });
  }

  get(url, headers = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        //url: url,
        url: BASE_URL + url,
        headers: { ...this.getHeaders(), ...headers }
        // data: data
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.errorMessages(err.response?.data);
          console.log(err.response);
        });
    });
  }

  getArticles(url, headers = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        //url: url,
        url: url,
        headers: { ...this.getHeaders(), ...headers }
        // data: data
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.errorMessages(err.response?.data);
          console.log(err.response);
        });
    });
  }

  patchArticleStatus(url, data, headers) {
    return new Promise((resolve, reject) => {
      axios({
        method: "PATCH",
        //url: url,
        url: url,
        headers: { ...this.getHeaders(), ...headers },
        data: data
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.errorMessages(err.response?.data);
          console.log(err.response, err);
        });
    });
  }

  deleteArticle(url, data, headers = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "delete",
        //url: url,
        url: url,
        headers: { ...this.getHeaders(), ...headers },
        data: data
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.errorMessages(err.response?.data, 3);
          console.log(err.response, err);
        });
    });
  }

  getArticlesCategory(url, headers = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        //url: url,
        url: url,
        headers: { ...this.getHeaders(), ...headers }
        // data: data
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.errorMessages(err.response?.data);
          console.log(err.response);
        });
    });
  }

  postArticle(url, data, headers = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        //url: url,
        url: url,
        headers: { ...this.getHeaders(), ...headers },
        data: data
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.errorMessages(err.response?.data, 3);
          console.log(err.response, err);
        });
    });
  }

  putArticle(url, data, headers = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        //url: url,
        url: url,
        headers: { ...this.getHeaders(), ...headers },
        data: data
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.errorMessages(err.response?.data);
          console.log(err.response, err);
        });
    });
  }

  put(url, data, headers = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        //url: url,
        url: BASE_URL + url,
        headers: { ...this.getHeaders(), ...headers },
        data: data
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.errorMessages(err.response?.data);
          console.log(err.response, err);
        });
    });
  }

  post(url, data, headers = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        //url: url,
        url: BASE_URL + url,
        headers: { ...this.getHeaders(), ...headers },
        data: data
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.errorMessages(err.response?.data, 3);
          console.log(err.response, err);
        });
    });
  }

  delete(url, data, headers = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: "delete",
        //url: url,
        url: BASE_URL + url,
        headers: { ...this.getHeaders(), ...headers },
        data: data
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.errorMessages(err.response?.data, 3);
          console.log(err.response, err);
        });
    });
  }
}
export default new Request();
