import React from 'react'
import MathJax from 'react-mathjax2'

const Mathjax = ({ expression }) => {
  return <MathJax.Text text={expression} />
}

export default Mathjax

export const MathjaxContext = ({ children }) => {
  // const script =
  //   'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=AM_HTMLorMML'
  return (
    <MathJax.Context
      // onLoad={() => console.log("Loaded MathJax script!")}
      onError={(_MathJax, error) => {
        console.warn(error)
        // console.log('Encountered a MathJax error, re-attempting a typeset!')
        // MathJax.Hub.Queue(MathJax.Hub.Typeset())
      }}
      // script={script}
      options={{
        asciimath2jax: {
          useMathMLspacing: true,
          delimiters: [['$$', '$$']],
          inlineMath: [['$', '$']],
          preview: 'none',
        },
        tex2jax: {
          inlineMath: [['@', '@']],
          displayMath: [['@@', '@@']],
          processEscapes: true,
          processEnvironments: true,
          preview: 'none',
        },
      }}
    >
      {children}
    </MathJax.Context>
  )
}
