import { Button, Form, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Request from '../../../service/request/Request'
type MetaInput = {
  description: string
  keywords: string
}
interface Props {}

export const CreateMeta = (props: Props) => {
  const testId = useSelector<any>((state) => state.selectValues?.id)
  const [form] = useForm()

  useEffect(() => {
    if (testId) {
      const fetchMeta = async () => {
        const { data } = await Request.get('/seotest?test_id=' + testId)
        if (data) {
          form.setFieldsValue({
            description: data?.description,
            keywords: data?.meta,
          })
        }
      }
      fetchMeta()
    }
  }, [testId, form])

  const onFinish = async (values: MetaInput) => {
    const { data } = await Request.post('/seotest', {
      description: values.description,
      meta: values.keywords,
      test_id: testId,
    })
    if (data) {
      return message.success("Jo'natildi")
    }
    return message.error('Xatolik mavjud')
  }
  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
    >
      <hr />
      <h4>Mavzuli test uchun metani kiriting</h4>
      <Form.Item
        label="Meta description"
        name="description"
        rules={[{ required: true, message: 'Description ni kiriting!' }]}
      >
        <TextArea autoSize />
      </Form.Item>
      <Form.Item
        label="Meta keywords"
        name="keywords"
        rules={[{ required: true, message: 'Description ni kiriting!' }]}
      >
        <TextArea autoSize />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Jo'natish
        </Button>
      </Form.Item>
      <hr />
    </Form>
  )
}
