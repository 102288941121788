import React from 'react'
import LoginService from '../service/login/LoginService'
import { Redirect } from 'react-router-dom'

const IsLogin = (props) => {
  const isLogIn = LoginService.isLogIn()
  // console.log(isLogIn);
  if (isLogIn) {
    // console.log("working",isLogIn);
    return props.children
  }
  return <Redirect to={'/'} />
}

export default IsLogin
