const phoneNumber = {
  addMinuses(text) {
    const arr = text.replaceAll('-', '').split('').slice(0, 9)
    if (arr.length > 2) arr.splice(2, 0, '-')
    if (arr.length > 6) arr.splice(6, 0, '-')
    if (arr.length > 9) arr.splice(9, 0, '-')
    return arr.join('')
  },

  removeMinuses(text) {
    return text.replaceAll('-', '').slice(0, 9)
  },
}

export default phoneNumber
