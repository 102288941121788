import React, { useState } from "react";
import {
  BsFileText,
  FiUsers,
  BsCheckBox,
  HiOutlinePencilAlt,
  RiDatabase2Line,
  AiOutlineSetting,
  IoWarningOutline,
  BiLogOut
} from "react-icons/all";
import { Link, useLocation, Redirect } from "react-router-dom";
import NewsIcon from "../assets/icons/NewsIcon";
import LoginService from "../service/login/LoginService";

const Navbar = () => {
  const [isLogOut, setIsLogOut] = useState(false);
  const handleLogOut = () => {
    setIsLogOut(true);
    LoginService.logOut();
  };

  const location = useLocation();
  return (
    <>
      {isLogOut ? (
        <Redirect to={"/"} />
      ) : (
        <div className={"navBarCont"}>
          <Link
            to={"/dtm"}
            className={"navBarMenuItemsCont"}
            style={
              location.pathname === "/dtm"
                ? {
                    color: "#2C66F0"
                  }
                : null
            }
          >
            <RiDatabase2Line className={"navBarMenuItemsIcon"} />
            <div>DTM</div>
          </Link>

          <Link
            to={"/virtualTests"}
            className={"navBarMenuItemsCont"}
            style={
              location.pathname === "/virtualTests"
                ? {
                    color: "#2C66F0"
                  }
                : null
            }
          >
            <HiOutlinePencilAlt className={"navBarMenuItemsIcon"} />
            <div>Virtual test</div>
          </Link>

          <Link
            to={"/anotherTests"}
            className={"navBarMenuItemsCont"}
            style={
              location.pathname === "/anotherTests"
                ? {
                    color: "#2C66F0"
                  }
                : null
            }
          >
            <BsFileText className={"navBarMenuItemsIcon"} />
            <div>Mavzuli testlar</div>
          </Link>

          <Link
            to={"/statistics"}
            className={"navBarMenuItemsCont"}
            style={
              location.pathname === "/statistics"
                ? {
                    color: "#2C66F0"
                  }
                : null
            }
          >
            <BsCheckBox className={"navBarMenuItemsIcon"} />
            <div>Statistika</div>
          </Link>

          <Link
            to={"/users"}
            className={"navBarMenuItemsCont"}
            style={
              location.pathname === "/users"
                ? {
                    color: "#2C66F0"
                  }
                : null
            }
          >
            <FiUsers className={"navBarMenuItemsIcon"} />
            <div>Foydalanuvchilar</div>
          </Link>

          <Link
            to={"/wrongQuestions"}
            className={"navBarMenuItemsCont"}
            style={
              location.pathname === "/wrongQuestions"
                ? {
                    color: "#2C66F0"
                  }
                : null
            }
          >
            <IoWarningOutline className={"navBarMenuItemsIcon"} />
            <div>Xatoliklar</div>
          </Link>

          <Link
            to={"/settings"}
            className={"navBarMenuItemsCont"}
            style={
              location.pathname === "/settings" ? { color: "#2C66F0" } : null
            }
          >
            <AiOutlineSetting className={"navBarMenuItemsIcon"} />
            <div>Sozlamalar</div>
          </Link>

          <Link
            to={"/news"}
            className={"navBarMenuItemsCont navBarMenuItemsCont2"}
            style={location.pathname === "/news" ? { color: "#2C66F0" } : null}
          >
            <NewsIcon
              color={location.pathname === "/news" ? "#2C66F0" : "#9ba5ac"}
            />
            <div>Yangiliklar</div>
          </Link>

          <Link
            to="/"
            onClick={handleLogOut}
            className={"navBarMenuItemsCont"}
            style={{ color: "#cf2d2d", marginTop: "auto" }}
          >
            <BiLogOut className={"navBarMenuItemsIcon"} />
            <div>Chiqish</div>
          </Link>
        </div>
      )}
    </>
  );
};

export default Navbar;
