import {
  Button,
  message,
  Pagination,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tooltip
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import AddIcon from "../../../assets/icons/AddIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import { actions } from "../../../redux/actions";
import { allPaths } from "../../../service/helper/allPaths";
import { allReqUrl } from "../../../service/helper/allReqUrl";
import Request from "../../../service/request/Request";

import "./News.scss";

type TableDataType = {
  current_page: number;
  data: {
    article_id?: number;
    category_id?: number;
    created_time?: string;
    description_ru?: string;
    description_uz?: string;
    name_ru?: string;
    name_uz?: string;
    site_id?: number;
    status?: string;
    views_count?: number | null;
    language_id: number;
  }[];
  total: number;
};

type ColumnData = {
  article_id?: number;
  category_id?: number;
  language_id: number;
  created_time?: string;
  description_ru?: string;
  description_uz?: string;
  name_ru?: string;
  name_uz?: string;
  site_id?: number;
  status?: string;
  views_count?: number | null;
};

const { Option } = Select;

const { Column } = Table;

function News() {
  // dispatch
  const dispatch = useDispatch();

  // router history
  const history = useHistory();

  // all data
  const [tableData, setTableData] = useState<TableDataType>();

  // column number
  const [columnNumber, setColumnNumber] = useState<number>(10);

  // loading table
  const [loadingTable, setLoadingTable] = useState<boolean>(true);

  // page Number
  const [pageNumber, setPageNumber] = useState<number>(1);

  // table data
  useEffect(() => {
    Request.getArticles(
      allReqUrl.ALL_ARTICLES + `?page=${pageNumber}&size=${columnNumber}`
    )
      .then((data) => {
        setTableData(data.data);
        setLoadingTable(false);
      })
      .catch(() => message.error("So'rovda xatolik"));
  }, [pageNumber, columnNumber]);

  // change column number
  const changeColumnNumber = (e: number) => {
    setColumnNumber(e);
    setLoadingTable(true);
  };

  // change page
  const changePage = (e: number) => {
    setPageNumber(e);
    setLoadingTable(true);
  };

  // change Switch
  const changeSwitch = (id: number, e: boolean) => {
    setLoadingTable(true);
    Request.patchArticleStatus(allReqUrl.CHANGE_STATUS_ARTICLES + `${id}`, {
      status: e ? "active" : "passive"
    })
      .then(() => {
        Request.getArticles(
          allReqUrl.ALL_ARTICLES + `?page=${pageNumber}&size=${columnNumber}`
        )
          .then((data) => {
            setTableData(data.data);
            setLoadingTable(false);
          })
          .catch(() => message.error("So'rovda xatolik"));
      })
      .catch(() => message.error("O'zgartirib bo'lmadi"));
    console.log(e);
  };

  // delete Article
  const deleteArticle = (id: number) => {
    setLoadingTable(true);
    Request.deleteArticle(allReqUrl.DELETE_ARTICLES + `${id}`)
      .then(() =>
        Request.getArticles(
          allReqUrl.ALL_ARTICLES + `?page=${pageNumber}&size=${columnNumber}`
        )
          .then((data) => {
            setTableData(data.data);
            setLoadingTable(false);
          })
          .catch(() => message.error("So'rovda xatolik"))
      )
      .catch(() => message.error("O'chirib bo'lmadi"));
  };

  // edit Article
  const editArticle = (id: any) => {
    dispatch(actions.articleIdAction(id));
    setTimeout(() => {
      history.push(`${allPaths.NEWS}/${id.article_id}`);
    }, 300);
  };

  return (
    <>
      <div className="news">
        <div className="add_news">
          <h2>Yangiliklar</h2>
          <Tooltip title="Maqola qo'shish" placement="left">
            <Link to={allPaths.ADD_NEWS}>
              <AddIcon />
            </Link>
          </Tooltip>
        </div>
        <div className="add_news_table">
          <Table
            className="add_all_news"
            pagination={false}
            dataSource={tableData?.data}
            loading={loadingTable}
            // columns={columns}
          >
            <Column
              title="№"
              key="id"
              dataIndex="id"
              render={(_, __, index) => {
                return <span>{index + 1}</span>;
              }}
            />
            <Column title="Maqola nomi" dataIndex="name" key="name" />
            <Column title="Sana" dataIndex="created_time" key="created_time" />
            <Column
              title="Tili"
              key="lang"
              render={(e: ColumnData) => (
                <p>{e.language_id === 1 ? "O'zbek tili" : "Русский язык"}</p>
              )}
            />
            <Column
              title="Holati"
              key="status"
              render={(e) => (
                <Tooltip title="Yangilikning holati" placement="left">
                  <Switch
                    checked={e?.status === "active" ? true : false}
                    onChange={(event: boolean) =>
                      changeSwitch(e.article_id, event)
                    }
                  />
                </Tooltip>
              )}
            />
            <Column
              title=""
              key="edit"
              render={(e) => (
                <Tooltip title="Yangilikni tahrirlash" placement="left">
                  <Button onClick={() => editArticle(e)}>
                    <EditIcon />
                  </Button>
                </Tooltip>
              )}
            />
            <Column
              title=""
              key="delete"
              render={(e) => (
                <Popconfirm
                  title="Ushbu yangilikni o'chirishni xohlaysizmi ?"
                  okText="Ha"
                  cancelText="Yo'q"
                  placement="left"
                  onConfirm={() => deleteArticle(e.article_id)}
                >
                  <Button onClick={() => console.log(e.index)}>
                    <DeleteIcon />
                  </Button>
                </Popconfirm>
              )}
            />
          </Table>
          <div className="news_pagination">
            <div className="news_pagination_size">
              <p>Har bir sahifadagi qatorlar soni:</p>
              <Select value={columnNumber} onChange={changeColumnNumber}>
                <Option value={10}>10</Option>
                <Option value={30}>30</Option>
                <Option value={50}>50</Option>
                <Option value={100}>100</Option>
              </Select>
            </div>
            <Pagination
              className="paginationNews"
              total={tableData?.total}
              onChange={changePage}
              current={pageNumber}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(News);
