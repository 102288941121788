import React, { useState, useRef } from 'react'
import {
  Button,
  Input,
  Radio,
  Popconfirm,
  Form,
  Popover,
  message,
  Image,
  Empty,
  Checkbox,
} from 'antd'
import Icon, {
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { BiCircle } from 'react-icons/bi'
import mountainSvg from './mountainSvg'
import Mathjax from './Mathjax'
import { connect } from 'react-redux'
import { actions } from '../../../redux/actions'
import Request, { BASE_IMAGE_URL } from '../../../service/request/Request'
import { isFile } from '../../../service/common/isFile'

const { removeQuestion, clearOnUnmount, saveQuestionChange, removeQuestionPhoto } =
  actions
const initialState = [{ correctAnswer: 1, isChecked: false, id: null }]
const { TextArea } = Input

const QuestionAdd = (props) => {
  // console.log('AD!!!!!!!!!!')
  const {
    removeQuestion,
    removeQuestionPhoto,
    clearOnUnmount,
    saveQuestionChange,
    questionsArrayLength,
    index,
    loading,
    handleConfirm,
    input,
  } = props

  const timeout = useRef(null)
  const [preview, setPreview] = useState({
    question: input.question,
    a: input.a,
    b: input.b,
    c: input.c,
    d: input.d,
  })
  const [previewShown, setPreviewShown] = useState(false)
  const MountainIcon = (props) => <Icon component={mountainSvg} {...props} />
  const handleRemove = async () => {
    if (input.id) {
      await Request.post('/question-delete', { id: input.id })
      questionsArrayLength <= 1
        ? clearOnUnmount(initialState)
        : removeQuestion(index)
    } else {
      questionsArrayLength <= 1
        ? clearOnUnmount(initialState)
        : removeQuestion(index)
    }
  }

  //this array fixes the bug with deletion of specific question
  const fields = [
    { name: ['question'], value: input.question },
    { name: ['a'], value: input.a },
    { name: ['b'], value: input.b },
    { name: ['c'], value: input.c },
    { name: ['d'], value: input.d },
  ]

  const handleQuestionChange = (e) => {
    if (e.target.type === 'file') {
      if (e.target.files.length > 0 && e.target.files[0].size > 2 * 1024 * 1024) {
        return message.error(`Rasm uchun maksimal hajm 2 MegaBayt!`)
      }
      return saveQuestionChange(
        { ...input, [e.target.name]: e.target.files[0] },
        index
      )
    }
    if (timeout) {
      clearTimeout(timeout.current)
    }

    if (e.target.props) {
      saveQuestionChange({ ...input, [e.target.props.name]: e.target.value }, index)
      timeout.current = setTimeout(() => {
        setPreview({ ...preview, [e.target.name]: e.target.value })
      }, 500)
    } else {
      timeout.current = setTimeout(() => {
        setPreview({ ...preview, [e.target.name]: e.target.value })
      }, 500)
      saveQuestionChange({ ...input, [e.target.name]: e.target.value }, index)
    }
  }

  const imgPreview = (img, width = 250, targetName) => {
    let src = ''
    if (isFile(img)) {
      src = URL.createObjectURL(img)
    } else {
      src = BASE_IMAGE_URL + img
    }
    const props = {
      src,
      width,
      alt: 'image',
    }
    return (
      <div className="img">
        {width >= 250 ? <Image {...props} /> : <img {...props} alt="logo" />}

        {width >= 250 ? (
          <Button
            danger
            size="small"
            shape="circle"
            onClick={async () => {
              if (input.id) {
                try {
                  const res = await Request.post('/remove-question-image', {
                    id: input.id,
                    type: targetName.charAt(0),
                  })
                  removeQuestionPhoto(index, targetName)
                  res.data.message && message.success(res.data.message)
                } catch (e) {
                  console.log(e.message)
                }
              }
            }}
          >
            <CloseOutlined />
          </Button>
        ) : null}
      </div>
    )
  }
  return (
    <Form
      fields={fields}
      className="questionAdd"
      onFinish={() => {
        handleConfirm(index)
      }}
    >
      <div style={{ marginBottom: '0.5rem' }}>
        <Checkbox
          on
          onChange={(e) => {
            setPreviewShown(e.target.checked)
          }}
        >
          prevyuni ko'rsatish
        </Checkbox>
      </div>
      <div className="question">
        <p>{index + 1}</p>
        <Form.Item
          name="question"
          rules={[{ required: true, message: 'Iltimos savolni kiriting' }]}
          // id = {'quasdfavfewfvestasdfasdf' + input.id && input.id && index}
        >
          <TextArea
            id={'question' + input.id}
            autoSize
            name="question"
            onChange={handleQuestionChange}
          />
        </Form.Item>

        <Popover
          content={
            input.question_photo ? (
              imgPreview(input.question_photo, 250, 'question_photo')
            ) : (
              <Empty description={<p>Hozircha rasm yo'q</p>} />
            )
          }
          placement="top"
        >
          <label
            style={input.question_photo ? { background: 'none' } : null}
            htmlFor={`question_photo${index}`}
            className="photoLabel"
          >
            <input
              accept="image/*"
              name="question_photo"
              id={`question_photo${index}`}
              type="file"
              onChange={handleQuestionChange}
              className="d-none photoInput"
            />
            {input.question_photo ? (
              imgPreview(input.question_photo, 30)
            ) : (
              <MountainIcon />
            )}
          </label>
        </Popover>

        <Popconfirm
          placement="rightTop"
          title={"O'chirasizmi?"}
          onConfirm={handleRemove}
          okText="Ha"
          cancelText="Yo'q"
        >
          <Button type="primary" danger className="trash-btn">
            <DeleteOutlined />
          </Button>
        </Popconfirm>

        <Button
          loading={loading}
          id={`testSubmit${index}`}
          type={`${input.isChecked ? 'primary' : 'default'}`}
          htmlType="submit"
          className="check-btn"
          icon={input.isChecked ? <CheckOutlined /> : <BiCircle />}
          style={
            !input.isChecked
              ? { borderColor: 'red', color: 'red' }
              : { backgroundColor: '#20BF55', borderColor: '#20BF55' }
          }
        />
      </div>

      {previewShown && input.question && input.question.length > 0 ? (
        <div className="preview">
          <Mathjax expression={preview.question} />
        </div>
      ) : null}

      <ol className="variantList">
        <Radio.Group
          name="correctAnswer"
          onChange={handleQuestionChange}
          value={input.correctAnswer}
        >
          <li>
            <p>A</p>
            <Form.Item
              name="a"
              rules={[
                {
                  required: true,
                  message: 'Iltimos javobning A variantini kiriting',
                },
              ]}
            >
              <TextArea
                id={'a' + input.id}
                autoSize
                name="a"
                onChange={handleQuestionChange}
              />
            </Form.Item>

            <Popover
              content={
                input.a_photo ? (
                  imgPreview(input.a_photo, 250, 'a_photo')
                ) : (
                  <Empty description={<p>Hozircha rasm yo'q</p>} />
                )
              }
              placement="top"
            >
              <label
                style={input.a_photo ? { background: 'none' } : null}
                htmlFor={`a_photo${index}`}
                className="photoLabel"
              >
                <input
                  accept="image/*"
                  name="a_photo"
                  id={`a_photo${index}`}
                  type="file"
                  onChange={handleQuestionChange}
                  className="d-none photoInput"
                />
                {input.a_photo ? imgPreview(input.a_photo, 30) : <MountainIcon />}
              </label>
            </Popover>

            <Radio value={1}></Radio>
          </li>

          {previewShown && input.a && input.a.length > 0 ? (
            <div className="preview">
              <Mathjax expression={preview.a} />
            </div>
          ) : null}

          <li>
            <p>B</p>
            <Form.Item
              name="b"
              rules={[
                {
                  required: true,
                  message: 'Iltimos javobning B variantini kiriting',
                },
              ]}
            >
              <TextArea
                id={'b' + input.id}
                autoSize
                name="b"
                onChange={handleQuestionChange}
              />
            </Form.Item>

            <Popover
              content={
                input.b_photo ? (
                  imgPreview(input.b_photo, 250, 'b_photo')
                ) : (
                  <Empty description={<p>Hozircha rasm yo'q</p>} />
                )
              }
              placement="top"
            >
              <label
                style={input.b_photo ? { background: 'none' } : null}
                htmlFor={`b_photo${index}`}
                className="photoLabel"
              >
                <input
                  accept="image/*"
                  name="b_photo"
                  id={`b_photo${index}`}
                  type="file"
                  onChange={handleQuestionChange}
                  className="d-none photoInput"
                />
                {input.b_photo ? imgPreview(input.b_photo, 30) : <MountainIcon />}
              </label>
            </Popover>

            <Radio value={2}></Radio>
          </li>

          {previewShown && input.b && input.b.length > 0 ? (
            <div className="preview">
              <Mathjax expression={preview.b} />
            </div>
          ) : null}

          <li>
            <p>C</p>
            <Form.Item
              name="c"
              rules={[
                {
                  required: true,
                  message: 'Iltimos javobning C variantini kiriting',
                },
              ]}
            >
              <TextArea
                id={'c' + input.id}
                autoSize
                name="c"
                onChange={handleQuestionChange}
              />
            </Form.Item>

            <Popover
              content={
                input.c_photo ? (
                  imgPreview(input.c_photo, 250, 'c_photo')
                ) : (
                  <Empty description={<p>Hozircha rasm yo'q</p>} />
                )
              }
              placement="top"
            >
              <label
                style={input.c_photo ? { background: 'none' } : null}
                htmlFor={`c_photo${index}`}
                className="photoLabel"
              >
                <input
                  accept="image/*"
                  name="c_photo"
                  id={`c_photo${index}`}
                  type="file"
                  onChange={handleQuestionChange}
                  className="d-none photoInput"
                />
                {input.c_photo ? imgPreview(input.c_photo, 30) : <MountainIcon />}
              </label>
            </Popover>

            <Radio value={3}></Radio>
          </li>
          {previewShown && input.c && input.c.length > 0 ? (
            <div className="preview">
              <Mathjax expression={preview.c} />
            </div>
          ) : null}

          <li>
            <p>D</p>
            <Form.Item
              name="d"
              rules={[
                {
                  required: true,
                  message: 'Iltimos javobning D variantini kiriting',
                },
              ]}
            >
              <TextArea
                id={'d' + input.id}
                autoSize
                name="d"
                onChange={handleQuestionChange}
              />
            </Form.Item>

            <Popover
              content={
                input.d_photo ? (
                  imgPreview(input.d_photo, 250, 'd_photo')
                ) : (
                  <Empty description={<p>Hozircha rasm yo'q</p>} />
                )
              }
              placement="top"
            >
              <label
                style={input.d_photo ? { background: 'none' } : null}
                htmlFor={`d_photo${index}`}
                className="photoLabel"
              >
                <input
                  accept="image/*"
                  name="d_photo"
                  id={`d_photo${index}`}
                  type="file"
                  onChange={handleQuestionChange}
                  className="d-none photoInput"
                />
                {input.d_photo ? imgPreview(input.d_photo, 30) : <MountainIcon />}
              </label>
            </Popover>

            <Radio value={4}></Radio>
          </li>
          {previewShown && input.d && input.d.length > 0 ? (
            <div className="preview">
              <Mathjax expression={preview.d} />
            </div>
          ) : null}
        </Radio.Group>
      </ol>
    </Form>
  )
}

const state2props = (state) => {
  return {
    questionsArrayLength: state.questions.length,
  }
}

const dispatch2props = {
  removeQuestion,
  clearOnUnmount,
  saveQuestionChange,
  removeQuestionPhoto,
}

const areEqual = (prev, next) => {
  return (
    prev.input === next.input &&
    prev.loading === next.loading &&
    prev.previewShown === next.previewShown &&
    prev.questionsArrayLength === next.questionsArrayLength &&
    prev.index === next.index
  )
}
export default connect(
  state2props,
  dispatch2props
)(React.memo(QuestionAdd, areEqual))
