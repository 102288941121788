import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import StatsDiagram from './StatsDiagram'
import { actions } from '../../../redux/actions'
import { DatePicker } from 'antd'
import { DiagramPie } from './DiagramPie'
import { arrayReducer } from '../../../service/common/arrayReducer'

const { RangePicker } = DatePicker

const { fetchTestStats, getTestStats, TEST_STATS_URL } = actions

const TestStats = ({ fetchTestStats, testType, testSubject, getTestStats }) => {
  useEffect(
    () => fetchTestStats(TEST_STATS_URL),
    // eslint-disable-next-line
    []
  )

  const handleRangeChange = (sort, dateArray) => {
    getTestStats({ sort, begin: dateArray[0], end: dateArray[1] }, TEST_STATS_URL)
    if (dateArray[0] === '' && dateArray[1] === '') {
      fetchTestStats()
    }
  }

  return (
    <div className="stats_content">
      <div className="stats_view">
        <StatsDiagram title={'Test turlari kesimida'}>
          <>
            <div className="diagram_filter">
              <span>Muddati:</span>
              <RangePicker
                onChange={(_, dateArray) => handleRangeChange('test', dateArray)}
              />
            </div>
            <DiagramPie data={testType} />
          </>
          <p style={{ textAlign: 'center' }}>
            Jami: {arrayReducer(testType, 'value')}
          </p>
        </StatsDiagram>
        <StatsDiagram title={'Fanlar kesimida'}>
          <>
            <div className="diagram_filter">
              <span>Muddati:</span>
              <RangePicker
                onChange={(_, dateArray) => handleRangeChange('subject', dateArray)}
              />
            </div>
            <DiagramPie data={testSubject} />
            <p style={{ textAlign: 'center' }}>
              Jami: {arrayReducer(testSubject, 'value')}
            </p>
          </>
        </StatsDiagram>
      </div>
    </div>
  )
}

const state2props = (state) => ({
  testType: state.stats.tests_for_type,
  testSubject: state.stats.tests_for_subject,
})

export default connect(state2props, { fetchTestStats, getTestStats })(TestStats)
