import React, { useState } from "react";
import { Button } from "antd";
import { PlusOutlined, RollbackOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import TestTableList from "../common/TestTableList";
import TestCreate from "../common/TestCreate";
import { actions } from "../../../redux/actions";
import "../common/list_table.css";
import "../common/common.css";
import "./Dtm.css";
import "./SubjectTests.css";
import "./virtual.css";
import { connect } from "react-redux";
import FilterIcon from "../../../assets/icons/FilterIcon";
import LessonFilterDrawer from "../common/LessonFilterDrawer";

const { clearOnUnmount, setSelect } = actions;

const TestsMainPage = ({ component, editing, clearOnUnmount, setSelect }) => {
  const [openFIlter, setOpenFIlter] = useState(false);
  const [activeId, setActiveId] = useState(0);
  const [activeStatus, setActiveStatus] = useState("");
  const [sectionChange, setSectionChange] = useState([]);
  const { push, location } = useHistory();
  let title = "DTM test";
  // console.log('MAIN', editing);
  if (component === "virtual") {
    title = "Virtual testlar";
  } else if (component === "lesson") {
    title = "Mavzuli testlar";
  }

  const [toShow, setToShow] = useState(editing);
  const handleToShow = () => {
    clearOnUnmount();
    setSelect({});
    setToShow((prev) => !prev);
  };

  const pushBack = () => {
    clearOnUnmount();
    push("/" + location.pathname.split("/")[1]);
  };

  const handleFilter = () => {
    setOpenFIlter(true);
  };
  return (
    <div className={component}>
      <div className="test_head">
        <h1 className={`${component}_title`}>{title}</h1>
        <div>
          <Button
            className="plus-btn"
            onClick={editing ? pushBack : handleToShow}
          >
            {toShow ? <RollbackOutlined /> : <PlusOutlined />}
          </Button>
          {component === "lesson" && (
            <Button
              className="plus-btn"
              style={{ marginLeft: 20 }}
              onClick={handleFilter}
            >
              <FilterIcon /> Filtr
            </Button>
          )}
        </div>
      </div>
      {toShow ? (
        <TestCreate component={component} editing={editing} />
      ) : (
        <TestTableList
          component={component}
          activeId={activeId}
          activeStatus={activeStatus}
          sectionChange={sectionChange}
        />
      )}
      <LessonFilterDrawer
        visible={openFIlter}
        setVisible={setOpenFIlter}
        activeId={activeId}
        setActiveId={setActiveId}
        activeStatus={activeStatus}
        setActiveStatus={setActiveStatus}
        sectionChange={sectionChange}
        setSectionChange={setSectionChange}
      />
    </div>
  );
};

export default connect(null, { clearOnUnmount, setSelect })(TestsMainPage);
