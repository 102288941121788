import { Button, Form, Input, message, Typography } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import phoneNumber from '../../service/common/phoneNumber'
import LoginService from '../../service/login/LoginService'
import './Login.css'

import { BASE_URL } from '../../service/request/Request'

function Login() {
  const [spinner, setSpinner] = useState(false)
  const [form] = Form.useForm()
  let history = useHistory()

  useEffect(() => {
    const user = localStorage.getItem('user')
    if (user) {
      history.push('/dtm')
    }
    // eslint-disable-next-line
  }, [])

  const handleLoginChange = () => {
    form.setFieldsValue({
      login: phoneNumber.addMinuses(form.getFieldValue('login')),
    })
  }

  const onFinish = async (values) => {
    axios
      .post(`${BASE_URL}/login`, {
        ...values,
        login: phoneNumber.removeMinuses(values.login),
      })
      .then((res) => {
        LoginService.login(res.data.user)
        setSpinner(false)
        return history.push('/dtm')
      })
      .catch((err) => {
        console.log(err.message)
        setSpinner(false)
        message.error("Ma'lumotlar xato kiritilgan")
      })
  }

  // const onFinishFailed = (errorInfo) => {
  //   console.log('Failed:', errorInfo)
  // }

  return (
    <div
      style={{
        margin: '0 auto 0 auto',
        paddingTop: '25vh',
        width: '20vw',
        height: '30vh',
      }}
    >
      <Typography className={'loginTitle'}>Tizimga kirish</Typography>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="login"
          rules={[
            {
              required: true,
              message: 'Loginni kiriting',
            },
          ]}
        >
          <Input
            onChange={handleLoginChange}
            placeholder={'Login'}
            style={{
              padding: '0.7vw',
            }}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Parolni kiriting!',
            },
          ]}
        >
          <Input.Password
            placeholder={'Password'}
            style={{
              padding: '0.7vw',
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button
            loading={spinner}
            type="primary"
            htmlType="submit"
            className="submitButton"
            onClick={() => {
              setSpinner(true)
            }}
          >
            Kirish
          </Button>
        </Form.Item>
      </Form>
      <Typography className={'companyName'}>Test.aim.uz</Typography>
    </div>
  )
}

export default Login
