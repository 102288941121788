import React from "react";
import { MdDesktopWindows } from "react-icons/all";
import AvatarImage from "../avatarImage.png";
import { Typography, Input, Avatar } from "antd";
import LoginService from "../service/login/LoginService";

const TopBar = () => {
  const handleSearch = () => {};
  return (
    <div className={"topBarCont"}>
      <div className={"topBarLogo"}>
        <MdDesktopWindows />
        <div className={"topBarText"}>Akam.uz</div>
      </div>

      <Input.Search
        className="topBarSearch"
        placeholder="Qidiruv tizimi"
        onSearch={handleSearch}
      />

      <div className={"topBarUserCont"}>
        <div>
          <Avatar
            alt={"avatarImage"}
            src={
              LoginService.getUser().image
                ? LoginService.getUser().image
                : AvatarImage
            }
            style={{ marginRight: "1rem" }}
            // className={'topBarAvatar'}
          />
        </div>
        <div>
          <Typography className={"topBarUserName"}>
            {LoginService.getUser().full_name}
          </Typography>
          <Typography className={"topBarUserRole"}>
            {LoginService.getUser().role}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
