// const initialState = {}

export const selectReducer = (state = {}, action) => {
  switch (action.type) {
    case 'EDIT_SELECTS':
      // console.log(action.payload)
      return action.payload

    default:
      return state
  }
}
