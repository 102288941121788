import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";
import Request from "../../../../service/request/Request";
import {
  backToInitialState,
  changeMetaSection,
  getSubjects,
  getTests,
  pagesMeta,
  subjectsMeta,
  testsMeta
} from "../../../../redux/metaActions";
import MetaSubject from "./MetaSubject";
import MetaPage from "./MetaPage";
import MetaTest from "./MetaTest";

const MetaCont = () => {
  const [loading, setLoading] = useState(true);
  const selectedSection = useSelector<RootState>((state) => state.metaCont.selectedSection)
  const dispatch = useDispatch();

  useEffect((): any => {
    Request.put('/seo?type=subject')
      .then(res => {
        dispatch(getSubjects(res.data.val))
        return Request.put('/seo?type=test')
      })
      .then((res => {
        dispatch(getTests(res.data.val))
        return Request.get('/seo?type=test')
      }))
      .then((res) => {
        dispatch(testsMeta(res.data.seo))
        return Request.get('/seo?type=page')
      })
      .then((res) => {
        dispatch(pagesMeta(res.data.seo))
        return Request.get('/seo?type=subject')
      })
      .then((res) => {
        dispatch(subjectsMeta(res.data.seo))
        setLoading(false)
      })
    return dispatch(backToInitialState())
    // eslint-disable-next-line
  }, []);


  const showPagesFunc = () => {
    switch (selectedSection) {
      case "subject":
        return <MetaSubject loading={loading} />
      case "page":
        return <MetaPage />
      case "test":
        return <MetaTest />
    }
  }

  const selectPage: (page: string) => void = (page) => {
    dispatch(changeMetaSection(page))
  }

  return (
    <div>
      <div className={'metaTagsCont'}>
        <div
          onClick={() => {
            selectPage('subject')
          }}
          className={selectedSection === 'subject' ? 'metaTagActive' : 'metaTag'}>
          Fanlar
        </div>
        <div
          onClick={() => {
            selectPage('test')
          }}
          className={selectedSection === 'test' ? 'metaTagActive' : 'metaTag'}>
          Testlar
        </div>
        <div
          onClick={() => {
            selectPage('page')
          }}
          className={selectedSection === 'page' ? 'metaTagActive' : 'metaTag'}>
          Sahifalar
        </div>
      </div>
      {showPagesFunc()}
    </div>
  )
}

export default MetaCont