import React, {useState} from "react"
import {Button, Input, message, Popconfirm, Select, Table, Tooltip, Typography} from "antd";
import Column from "antd/es/table/Column";
import {AiOutlineDelete, BsPencil, IoMdClose} from "react-icons/all";
import Modal from "antd/es/modal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";
import {openCreateModalAction} from "../../../../redux/settingsActions";
import Request from "../../../../service/request/Request";
import {getTests, testsMeta} from "../../../../redux/metaActions";
// @ts-ignore
import Chips from "react-chips";
import {getMeta} from "../../../../redux/actionTypes";

const {TextArea} = Input;
const {Option} = Select

interface NewMetaInterface {
  keyWord: string[]
  testId: number | null
  description: string | null
  test?: string
}

interface EditDataType {
  id?: number
  page?: string | number
  meta?: string[]
  description?: string
  val_id?: number
}

const MetaTest = () => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalData, setEditModalData] = useState<EditDataType | null>(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [newMeta, setNewMeta] = useState<NewMetaInterface>({
    keyWord: [],
    testId: null,
    description: null,
    test: ''
  });

  const tableData = useSelector<RootState>((state) => state.metaCont.getTestsMeta)
  const testData: any = useSelector<RootState>((state) => state.metaCont.tests)
  const createModalOpen = useSelector<RootState>((state) => state.settings.openCreateModal)
  const dispatch = useDispatch();


  const newMetaCreate = () => {
    setButtonLoading(true)
    const metaString = newMeta.keyWord.toString()
    Request.post('/seo', {
      type: 'test',
      meta: metaString,
      val: newMeta.testId,
      description: newMeta.description
    })
      .then(res => {
        // @ts-ignore
        let data = [...tableData]
        data.unshift(res.data)
        dispatch(testsMeta(data as getMeta[]))
        dispatch(openCreateModalAction(false))
        const test = testData.filter((subject: any) => subject.id !== newMeta.testId)
        dispatch(getTests(test))
        setButtonLoading(false)
        setNewMeta({
          keyWord: [],
          testId: null,
          description: null,
          test: ''
        })
      })
      .catch(err => {
        console.log(err)
        setButtonLoading(false)
      })
  }

  const editFunc = () => {
    setButtonLoading(true)
    const metaString = editModalData?.meta?.toString()
    Request.post('/seo', {
      id: editModalData?.id,
      type: 'test',
      meta: metaString,
      description: editModalData?.description
    })
      .then(res => {
        console.log(res.data)
        // @ts-ignore
        let data = [...tableData]
        let foundIndex = data.findIndex(x => x.id === editModalData?.id);
        data[foundIndex] = res.data;
        dispatch(testsMeta(data as getMeta[]))
        setEditModalOpen(false)
        setButtonLoading(false)
      })
      .catch(err => {
        console.log(err)
        setButtonLoading(false)
      })
  }

  const deleteFunc: (id: number) => void = (id) => {
    Request.delete(`/seo?id=${id}`)
      .then(res => {
        console.log(res.data)
        // @ts-ignore
        let data = [...tableData]
        data = data.filter((item) => item.id !== id)
        dispatch(testsMeta(data as getMeta[]))
        return Request.put('/seo?type=test')
      })
      .then((res => {
        dispatch(getTests(res.data.val))
      }))
      .catch(err => {
        console.log(err)
        message.error("Xatolik mavjud").then(() => {
        })
      })
  }

  return (
    <div>
      <Table
        size={"middle"}
        dataSource={tableData as any}
        scroll={{y: "47vh"}}
        pagination={{position: ["bottomLeft"]}}
        style={{width: '100%', padding: '3%'}}
      >
        <Column width={'5%'} title="Id" key="id" render={record => (
          <span>{record.id}</span>
        )}/>
        <Column title="Key words" key="meta" dataIndex={'meta'} render={record => (
          <Tooltip title={record}>
            <span>{record.slice(0, 80) + (record.length > 80 ? '...' : "")}</span>
          </Tooltip>
        )}/>
        <Column title="Description" key="description" dataIndex={'description'} render={record => (
          <Tooltip title={record}>
            <span>{record.slice(0, 80) + (record.length > 80 ? '...' : "")}</span>
          </Tooltip>
        )}/>
        <Column width={'20%'} title="Subject" key="page" dataIndex={'page'} render={record => (
          <span>{record}</span>
        )}/>
        <Column width={'7%'} title="Action" key="status" render={record => (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around'
          }}>
            <BsPencil
              className={"settingsDtmActionsIcon"}
              onClick={() => {
                let keys = record.meta?.split(",")
                setEditModalData({
                  ...record,
                  meta: keys
                })
                setEditModalOpen(true)
              }}/>
            <Popconfirm
              title={"Tasdiqlash"}
              okText={"O'chirish"}
              cancelText={"Ortga"}
              onConfirm={() => {
                // message.warn("O'chirish xizmati o'chgan!").then(() => {})
                deleteFunc(record.id)
              }}>
              <AiOutlineDelete className={"settingsDtmActionsIcon"}/>
            </Popconfirm>
          </div>
        )}/>
      </Table>
      <Modal
        visible={editModalOpen}
        closable={false}
        centered={true}
        onCancel={() => {
          setEditModalOpen(false)
        }}
        footer={null}
      >
        <div>
          <div
            onClick={() => {
              setEditModalOpen(false)
            }}
            className={"settingsSubjectEditModalCloseIconCont"}>
            <IoMdClose/>
          </div>
          <Typography
            className={"settingsSubjectEditModalTitle"}>
            Yangilash
          </Typography>
          <div style={{
            display: 'flex',
            alignItems: "center",
            width: '90%',
            margin: '1vw auto'
          }}>
            <label
              className={"nameEditInputLabel"}
              htmlFor={"descriptionEditTextArea"}
              style={{width: '29%'}}>
              Subject
            </label>
            <Typography className={'nameEditTypography'}>
              {editModalData?.page}
            </Typography>
          </div>
          <div style={{
            display: 'flex',
            alignItems: "center",
            width: '90%',
            margin: '1vw auto'
          }}>
            <label
              className={"nameEditInputLabel"}
              htmlFor={"keyWordEditTextArea"}
              style={{width: '42%'}}>
              Key words
            </label>
            <div style={{width: '100%'}}>
              <Chips
                value={editModalData?.meta}
                onChange={(chips: string[]) => {
                  setEditModalData({
                    ...editModalData,
                    meta: chips
                  })
                }}
                suggestions={["Your", "Data", "Here"]}
              />
            </div>
          </div>
          <div style={{
            display: 'flex',
            alignItems: "center",
            width: '90%',
            margin: '1vw auto'
          }}>
            <label
              className={"nameEditInputLabel"}
              htmlFor={"descriptionEditTextArea"}
              style={{width: '42%'}}>
              Description
            </label>
            <div style={{width: '100%'}}>
              <TextArea
                rows={3}
                style={{resize: 'none'}}
                value={editModalData?.description}
                id={"descriptionEditTextArea"}
                onChange={(e) => {
                  setEditModalData({
                    ...editModalData,
                    description: e.target.value
                  })
                }}/>
            </div>
          </div>
          <div style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <Button
              loading={buttonLoading}
              type="primary"
              className={"settingsEditModalSaveButton"}
              onClick={() => {
                // @ts-ignore
                if ((editModalData?.meta?.length > 0) && editModalData?.page && editModalData?.description) {
                  editFunc()
                } else {
                  message.error("Ma'lumotlar xato kiritilgan").then(() => {
                  })
                }
              }}>
              Saqlash
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={createModalOpen as any}
        closable={false}
        centered={true}
        onCancel={() => {
          dispatch(openCreateModalAction(false))
          setNewMeta({
            keyWord: [],
            testId: null,
            description: null,
            test: ''
          })
        }}
        footer={null}
      >
        <div>
          <div
            onClick={() => {
              dispatch(openCreateModalAction(false))
              setNewMeta({
                keyWord: [],
                testId: null,
                description: null,
                test: ''
              })
            }}
            className={"settingsSubjectEditModalCloseIconCont"}>
            <IoMdClose/>
          </div>
          <Typography
            className={"settingsSubjectEditModalTitle"}>
            Meta qo'shish
          </Typography>
          <div style={{
            display: 'flex',
            alignItems: "center",
            width: '90%',
            margin: '1vw auto'
          }}>
            <label
              className={"nameEditInputLabel"}
              htmlFor={"keyWordEditTextArea"}
              style={{width: '42%'}}>
              Key words
            </label>
            <div style={{width: '100%'}}>
              <Chips
                value={newMeta.keyWord}
                onChange={(chips: string[]) => {
                  setNewMeta({
                    ...newMeta,
                    keyWord: chips
                  })
                }}
              />
            </div>
          </div>
          <div style={{
            display: 'flex',
            alignItems: "center",
            width: '90%',
            margin: '1vw auto'
          }}>
            <label
              className={"nameEditInputLabel"}
              htmlFor={"descriptionEditTextArea"}
              style={{width: '42%'}}>
              Description
            </label>
            <div style={{width: '100%'}}>
              <TextArea
                rows={3}
                value={newMeta.description as any}
                onChange={(e) => {
                  setNewMeta({
                    ...newMeta,
                    description: e.target.value
                  })
                }}
                style={{resize: 'none'}}
                id={"descriptionEditTextArea"}/>
            </div>
          </div>
          <div style={{
            display: 'flex',
            alignItems: "center",
            width: '90%',
            margin: '1vw auto'
          }}>
            <label
              className={"nameEditInputLabel"}
              htmlFor={"descriptionEditTextArea"}
              style={{width: '42%'}}>
              Tests
            </label>
            <div style={{width: '100%'}}>
              <Select
                showSearch
                value={newMeta.test}
                onChange={(e, key) => {
                  // @ts-ignore
                  const id = key.id
                  setNewMeta({
                    ...newMeta,
                    testId: id,
                    test: e
                  })
                }}
                style={{width: '100%'}}>
                {testData.map((subject: any) => (
                  <Option value={subject.name} key={subject.id} id={subject.id}>
                    {subject.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <Button
              loading={buttonLoading}
              type="primary"
              className={"settingsEditModalSaveButton"}
              onClick={() => {
                if (newMeta.testId && (newMeta.keyWord.length > 0) && newMeta.description) {
                  newMetaCreate()
                } else {
                  message.error("Ma'lumotlar xato kiritilgan").then(() => {
                  })
                }
              }}>
              Saqlash
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default MetaTest
