import React, { Component } from "react";
import University from "./university/University";
import Subject from "./subject/Subject";
import Year from "./year/Year";
import Lang from "./lang/Lang";
import Theme from "./theme/Theme";
import Request from "../../../service/request/Request";
import { message } from "antd";
import Mistake from "./mistake/Mistake";
import Faculty from "./faculty/Faculty";
import MetaCont from "./meta/MetaCont";
import { connect } from "react-redux";
import { openCreateModalAction } from "../../../redux/settingsActions";
import Section from "./section/Section";

class SettingsBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPage: "university",
      data: null,
      loading: true,
    };

    this.selectPageFunc = this.selectPageFunc.bind(this);
    this.editSubjectFunc = this.editSubjectFunc.bind(this);
    this.deleteSubjectFunc = this.deleteSubjectFunc.bind(this);
    this.editYearFunc = this.editYearFunc.bind(this);
    this.deleteYearFunc = this.deleteYearFunc.bind(this);
    this.editLangFunc = this.editLangFunc.bind(this);
    this.deleteLangFunc = this.deleteLangFunc.bind(this);
    this.editThemeFunc = this.editThemeFunc.bind(this);
    this.deleteThemeFunc = this.deleteThemeFunc.bind(this);
    this.editMistakeFunc = this.editMistakeFunc.bind(this);
    this.deleteMistakeFunc = this.deleteMistakeFunc.bind(this);
    this.editFacultyFunc = this.editFacultyFunc.bind(this);
    this.deleteFacultyFunc = this.deleteFacultyFunc.bind(this);
    this.editUniversityFunc = this.editUniversityFunc.bind(this);
    this.deleteUniversityFunc = this.deleteUniversityFunc.bind(this);
    this.closeAddNewItemModal = this.closeAddNewItemModal.bind(this);
    this.addNewItemFunc = this.addNewItemFunc.bind(this);
  }

  componentDidMount() {
    switch (this.state.selectedPage) {
      case "university":
        Request.get("/university-all")
          .then((res) => {
            this.setState({
              data: res.data.universities,
              loading: false,
            });
          })
          .catch((err) => {
            console.log(err);
            message.error("Xatolik mavjud").then(() => {});
          });
        break;
      default:
        this.setState({
          data: null,
        });
        break;
    }
  }

  selectPageFunc(page) {
    this.setState({
      selectedPage: page,
      data: null,
      loading: true,
    });
    switch (page) {
      case "university":
        Request.get("/university-all")
          .then((res) => {
            // console.log(res.data.universities)
            this.setState({
              data: res.data.universities,
              loading: false,
            });
          })
          .catch((err) => {
            console.log(err);
            message.error("Xatolik mavjud").then(() => {});
          });
        break;
      case "faculty":
        Request.get("/course-all")
          .then((res) => {
            // console.log(res.data.courses)
            this.setState({
              data: res.data.courses,
              loading: false,
            });
          })
          .catch(() => {
            message.error("Xatolik mavjud").then(() => {});
          });
        break;
      case "subject":
        Request.get("/subject-all")
          .then((res) => {
            this.setState({
              data: res.data.subjects,
              loading: false,
            });
          })
          .catch((err) => {
            message.error("Fanlarni olishda xatolik mavjud!").then(() => {});
            console.log(err);
          });
        break;
      case "lang":
        Request.get("/language-all")
          .then((res) => {
            this.setState({
              data: res.data.language,
              loading: false,
            });
          })
          .catch((err) => {
            message.error("Tillarni olishda xatolik mavjud!").then(() => {});
            console.log(err);
          });
        break;
      case "year":
        Request.get("/year-all")
          .then((res) => {
            this.setState({
              data: res.data.years,
              loading: false,
            });
          })
          .catch((err) => {
            message.error("Yillarni olishda xatolik mavjud!").then(() => {});
            console.log(err);
          });
        break;
      case "theme":
        Request.get("/lesson-all")
          .then((res) => {
            // console.log(res.data.lessons)
            this.setState({
              data: res.data.lessons,
              loading: false,
            });
          })
          .catch((err) => {
            message.error("Mavzularni olishda xatolik mavjud!").then(() => {});
            console.log(err);
          });
        break;
      case "section":
        Request.get("/section-all")
          .then((res) => {
            console.log(res.data);
            this.setState({
              data: res.data.sections,
              loading: false,
            });
          })
          .catch((err) => {
            message.error("Bo'limlarni olishda xatolik mavjud!").then(() => {});
            console.log(err);
          });
        break;
      case "mistake":
        Request.get("/error-all")
          .then((res) => {
            // console.log(res.data.errors)
            this.setState({
              data: res.data.errors,
              loading: false,
            });
          })
          .catch((err) => {
            message.error("Mavzularni olishda xatolik mavjud!").then(() => {});
            console.log(err);
          });
        break;
      default:
        this.setState({
          data: null,
        });
        break;
    }
  }

  showSelectPageFunc() {
    switch (this.state.selectedPage) {
      case "university":
        return (
          <University
            rerenderFunc={this.selectPageFunc}
            loading={this.state.loading}
            addNewItemFunc={this.addNewItemFunc}
            closeAddNewItemModal={this.closeAddNewItemModal}
            addNewSubjectModal={this.props.openCreateModal}
            deleteUniversityFunc={this.deleteUniversityFunc}
            editUniversityFunc={this.editUniversityFunc}
            data={this.state.data}
          />
        );
      case "faculty":
        return (
          <Faculty
            rerenderFunc={this.selectPageFunc}
            loading={this.state.loading}
            addNewItemFunc={this.addNewItemFunc}
            closeAddNewItemModal={this.closeAddNewItemModal}
            addNewSubjectModal={this.props.openCreateModal}
            deleteFacultyFunc={this.deleteFacultyFunc}
            editFacultyFunc={this.editFacultyFunc}
            data={this.state.data}
          />
        );
      case "subject":
        return (
          <Subject
            loading={this.state.loading}
            addNewItemFunc={this.addNewItemFunc}
            closeAddNewItemModal={this.closeAddNewItemModal}
            addNewSubjectModal={this.props.openCreateModal}
            deleteSubjectFunc={this.deleteSubjectFunc}
            editSubjectFunc={this.editSubjectFunc}
            data={this.state.data}
          />
        );
      case "lang":
        return (
          <Lang
            loading={this.state.loading}
            addNewItemFunc={this.addNewItemFunc}
            closeAddNewItemModal={this.closeAddNewItemModal}
            addNewSubjectModal={this.props.openCreateModal}
            deleteLangFunc={this.deleteLangFunc}
            editLangFunc={this.editLangFunc}
            data={this.state.data}
          />
        );
      case "year":
        return (
          <Year
            loading={this.state.loading}
            addNewItemFunc={this.addNewItemFunc}
            closeAddNewItemModal={this.closeAddNewItemModal}
            addNewSubjectModal={this.props.openCreateModal}
            deleteYearFunc={this.deleteYearFunc}
            editYearFunc={this.editYearFunc}
            data={this.state.data}
          />
        );
      case "theme":
        return (
          <Theme
            loading={this.state.loading}
            addNewItemFunc={this.addNewItemFunc}
            closeAddNewItemModal={this.closeAddNewItemModal}
            addNewSubjectModal={this.props.openCreateModal}
            deleteThemeFunc={this.deleteThemeFunc}
            editThemeFunc={this.editThemeFunc}
            data={this.state.data}
          />
        );
      case "section":
        return (
          <Section
            loading={this.state.loading}
            addNewItemFunc={this.addNewItemFunc}
            closeAddNewItemModal={this.closeAddNewItemModal}
            addNewSubjectModal={this.props.openCreateModal}
            deleteSectionFunc={this.deleteSectionFunc}
            editSectionFunc={this.editSectionFunc}
            data={this.state.data}
          />
        );
      case "mistake":
        return (
          <Mistake
            loading={this.state.loading}
            addNewItemFunc={this.addNewItemFunc}
            closeAddNewItemModal={this.closeAddNewItemModal}
            addNewSubjectModal={this.props.openCreateModal}
            deleteMistakeFunc={this.deleteMistakeFunc}
            editMistakeFunc={this.editMistakeFunc}
            data={this.state.data}
          />
        );
      case "meta":
        return <MetaCont />;
      default:
        return <div>Page not fount</div>;
    }
  }

  editSubjectFunc(data) {
    Request.put("/subject-edit", data)
      .then(() => {
        // console.log(res.data)
        this.selectPageFunc("subject");
      })
      .catch((err) => {
        message.error("Fan o'zgartirishda xatolik mavjud!").then(() => {});
        console.log(err);
      });
  }

  deleteSubjectFunc(data) {
    Request.post("/subject-delete", data)
      .then(() => {
        // console.log(res.data)
        message.success("Fan o'chirildi!").then(() => {});
        this.selectPageFunc("subject");
      })
      .catch((err) => {
        message.error("Fan o'chirishda xatolik mavjud!").then(() => {});
        console.log(err);
      });
  }

  editYearFunc(data) {
    Request.put("/year-edit", data)
      .then(() => {
        message.success("Yil o'zgartirildi").then(() => {});
        this.selectPageFunc("year");
      })
      .catch((err) => {
        message.error("Yil o'zgartirishda xatolik mavjud").then(() => {});
        console.log(err);
      });
  }

  deleteYearFunc(data) {
    Request.post("/year-delete", data)
      .then(() => {
        // console.log(res.data)
        message.success("Yil o'chirildi!").then(() => {});
        this.selectPageFunc("year");
      })
      .catch((err) => {
        message.error("Yil o'chirishda xatolik mavjud!").then(() => {});
        console.log(err);
      });
  }

  editThemeFunc(data) {
    console.log(data);
    Request.put("/lesson-edit", {
      id: data.id,
      name: data.lesson,
      subject_id: data.subject_id,
    })
      .then(() => {
        message.success("Mavzu o'zgartirildi").then(() => {});
        this.selectPageFunc("theme");
      })
      .catch((err) => {
        message.error("Mavzu o'zgartirishda xatolik mavjud").then(() => {});
        console.log(err);
      });
  }

  deleteThemeFunc(data) {
    Request.post("/lesson-delete", data)
      .then(() => {
        // console.log(res.data)
        message.success("Mavzu o'chirildi!").then(() => {});
        this.selectPageFunc("theme");
      })
      .catch((err) => {
        message.error("Mavzu o'chirishda xatolik mavjud!").then(() => {});
        console.log(err);
      });
  }

  editSectionFunc(data) {
    console.log(data);
    Request.put("/section-edit", {
      id: data.id,
      name: data.section,
      subject_id: data.subject_id,
    })
      .then(() => {
        message.success("Bo'lim o'zgartirildi").then(() => {});
        this.selectPageFunc("section");
      })
      .catch((err) => {
        // message.error("Bo'lim o'zgartirishda xatolik mavjud");
        console.log(err);
      });
  }

  deleteSectionFunc(data) {
    Request.post("/section-delete", data)
      .then(() => {
        // console.log(res.data)
        message.success("Bo'lim o'chirildi!").then(() => {});
        this.selectPageFunc("theme");
      })
      .catch((err) => {
        message.error("Bo'lim o'chirishda xatolik mavjud!").then(() => {});
        console.log(err);
      });
  }

  editLangFunc(data) {
    Request.put("/language-edit", data)
      .then(() => {
        message.success("Til o'zgartirildi").then(() => {});
        this.selectPageFunc("lang");
      })
      .catch((err) => {
        message.error("Til o'zgartirishda xatolik mavjud").then(() => {});
        console.log(err);
      });
  }

  deleteLangFunc(data) {
    Request.post("/language-delete", data)
      .then(() => {
        // console.log(res.data)
        message.success("Til o'chirildi!").then(() => {});
        this.selectPageFunc("lang");
      })
      .catch((err) => {
        message.error("Til o'chirishda xatolik mavjud!").then(() => {});
        console.log(err);
      });
  }

  editMistakeFunc(data) {
    console.log(data);
    Request.put("/error-edit", data)
      .then(() => {
        // message.success("Xatolik o'zgartirildi")
        this.selectPageFunc("mistake");
      })
      .catch((err) => {
        message.error("Xatolikni o'zgartirishda xatolik mavjud").then(() => {});
        console.log(err);
      });
  }

  deleteMistakeFunc(data) {
    Request.post("/error-delete", data)
      .then(() => {
        // console.log(res.data)
        message.success("Xatolik o'chirildi!").then(() => {});
        this.selectPageFunc("mistake");
      })
      .catch((err) => {
        message.error("Xatolikni o'chirishda xatolik mavjud!").then(() => {});
        console.log(err);
      });
  }

  editFacultyFunc(data) {
    console.log(data);
    Request.put("/course-edit", data)
      .then(() => {
        message.success("Yo'nalish o'zgartirildi").then(() => {});
        this.selectPageFunc("faculty");
      })
      .catch((err) => {
        message
          .error("Yo'nalishni o'zgartirishda xatolik mavjud")
          .then(() => {});
        console.log(err);
      });
  }

  deleteFacultyFunc(data) {
    Request.post("/course-delete", data)
      .then(() => {
        // console.log(res.data)
        message.success("Yo'nalish o'chirildi!").then(() => {});
        this.selectPageFunc("faculty");
      })
      .catch((err) => {
        message.error("Yo'nalishni o'chirishda xatolik mavjud!").then(() => {});
        console.log(err);
      });
  }

  editUniversityFunc(data) {
    Request.put("/university-create", data)
      .then(() => {
        message.success("Ta'lim muassasini o'zgartirildi").then(() => {});
        this.selectPageFunc("university");
      })
      .catch((err) => {
        message
          .error("Ta'lim muassasini o'zgartirishda xatolik mavjud")
          .then(() => {});
        console.log(err);
      });
  }

  deleteUniversityFunc(data) {
    Request.post("/university-delete", data)
      .then(() => {
        // console.log(res.data)
        message.success("Ta'lim muassasasi o'chirildi!").then(() => {});
        this.selectPageFunc("university");
      })
      .catch((err) => {
        message
          .error("Ta'lim muassasasini o'chirishda xatolik mavjud!")
          .then(() => {});
        console.log(err);
      });
  }

  closeAddNewItemModal() {
    this.props.openCreateModalAction(false);
  }

  addNewItemFunc(value) {
    switch (this.state.selectedPage) {
      case "university":
        Request.post("/university-create", value)
          .then(() => {
            this.props
              .openCreateModalAction(false)
              .message.success("Ta'lim muassasi qo'shildi!")
              .then(() => {});
            this.selectPageFunc("university");
          })
          .catch((err) => {
            console.log(err);
            message
              .error("Ta'lim muassasi qo'shishda xatolik mavjud!")
              .then(() => {});
          });
        break;
      case "faculty":
        Request.post("/course-create", value)
          .then(() => {
            this.props.openCreateModalAction(false);
            message.success("Yo'nalish qo'shildi!").then(() => {});
            this.selectPageFunc("faculty");
          })
          .catch((err) => {
            console.log(err);
            message
              .error("Yo'nalish qo'shishda xatolik mavjud!")
              .then(() => {});
          });
        break;
      case "subject":
        Request.post("/subject-create", {
          name: value,
        })
          .then(() => {
            this.props.openCreateModalAction(false);
            message.success("Yangi fan qo'shildi!").then(() => {});
            this.selectPageFunc("subject");
          })
          .catch((err) => {
            console.log(err);
            message.error("Fan qo'shishda xatolik mavjud!").then(() => {});
          });
        break;
      case "lang":
        Request.post("/language-create", {
          name: value,
        })
          .then(() => {
            this.props.openCreateModalAction(false);
            message.success("Til qo'shildi!").then(() => {});
            this.selectPageFunc("lang");
          })
          .catch((err) => {
            console.log(err);
            message.error("Til qo'shishda xatolik mavjud!").then(() => {});
          });
        break;
      case "year":
        Request.post("/year-create", {
          name: value,
        })
          .then(() => {
            this.props.openCreateModalAction(false);
            message.success("Yil qo'shildi!").then(() => {});
            this.selectPageFunc("year");
          })
          .catch((err) => {
            console.log(err);
            message.error("Yil qo'shishda xatolik mavjud!").then(() => {});
          });
        break;
      case "theme":
        Request.post("/lesson-create", value)
          .then(() => {
            this.props.openCreateModalAction(false);
            message.success("Mavzu qo'shildi!").then(() => {});
            this.selectPageFunc("theme");
          })
          .catch((err) => {
            console.log(err);
            message.error("Mavzu qo'shishda xatolik mavjud!").then(() => {});
          });
        break;
      case "section":
        Request.post("/section-create", value)
          .then(() => {
            this.props.openCreateModalAction(false);
            message.success("Bo'lim qo'shildi!").then(() => {});
            this.selectPageFunc("theme");
          })
          .catch((err) => {
            console.log(err);
            message.error("Bo'lim qo'shishda xatolik mavjud!").then(() => {});
          });
        break;
      case "mistake":
        // console.log(value)
        Request.post("/error-create", {
          name: value,
        })
          .then(() => {
            this.props.openCreateModalAction(false);
            message.success("Xatolik qo'shildi!").then(() => {});
            this.selectPageFunc("mistake");
          })
          .catch((err) => {
            console.log(err);
            message.error("Xatolik qo'shishda xatolik mavjud!").then(() => {});
          });
        break;
      default:
        console.log("error");
        break;
    }
  }

  render() {
    const { selectedPage } = this.state;
    const selectPageFunc = this.selectPageFunc;
    return (
      <div className={"bodyCont"}>
        <div className={"settingsMenuCont"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className={
                selectedPage === "university"
                  ? "settingsMenuItem active"
                  : "settingsMenuItem"
              }
              onClick={() => selectPageFunc("university")}
            >
              OTM
            </div>
            <div
              className={
                selectedPage === "faculty"
                  ? "settingsMenuItem active"
                  : "settingsMenuItem"
              }
              onClick={() => selectPageFunc("faculty")}
            >
              Yo‘nalish
            </div>
            <div
              className={
                selectedPage === "subject"
                  ? "settingsMenuItem active"
                  : "settingsMenuItem"
              }
              onClick={() => selectPageFunc("subject")}
            >
              Fan
            </div>
            <div
              className={
                selectedPage === "section"
                  ? "settingsMenuItem active"
                  : "settingsMenuItem"
              }
              onClick={() => selectPageFunc("section")}
            >
              Bo'limlar
            </div>
            <div
              className={
                selectedPage === "theme"
                  ? "settingsMenuItem active"
                  : "settingsMenuItem"
              }
              onClick={() => selectPageFunc("theme")}
            >
              Mavzular
            </div>
            <div
              className={
                selectedPage === "lang"
                  ? "settingsMenuItem active"
                  : "settingsMenuItem"
              }
              onClick={() => selectPageFunc("lang")}
            >
              Til
            </div>
            <div
              className={
                selectedPage === "year"
                  ? "settingsMenuItem active"
                  : "settingsMenuItem"
              }
              onClick={() => selectPageFunc("year")}
            >
              Yil
            </div>

            <div
              className={
                selectedPage === "mistake"
                  ? "settingsMenuItem active"
                  : "settingsMenuItem"
              }
              onClick={() => selectPageFunc("mistake")}
            >
              Xatolik
            </div>
            <div
              className={
                selectedPage === "meta"
                  ? "settingsMenuItem active"
                  : "settingsMenuItem"
              }
              onClick={() => selectPageFunc("meta")}
            >
              Meta taglar
            </div>
          </div>
          <div
            onClick={() => {
              this.props.openCreateModalAction(true);
            }}
            className={"settingsPlusButton"}
          >
            +
          </div>
        </div>
        {this.showSelectPageFunc()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  openCreateModal: state.settings.openCreateModal,
});

const mapDispatchToProps = {
  openCreateModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsBody);
