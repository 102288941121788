import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  message,
  Popconfirm,
  Select,
  Table,
  Typography,
} from "antd";
import Column from "antd/es/table/Column";
import { AiOutlineDelete, BsPencil, IoMdClose } from "react-icons/all";
import Modal from "antd/es/modal/Modal";
import Request from "../../../../service/request/Request";

const { Option } = Select;

function Section({
  data,
  editSectionFunc,
  deleteSectionFunc,
  addNewSubjectModal,
  closeAddNewItemModal,
  addNewItemFunc,
  loading,
}) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalData, setEditModalData] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [newSection, setNewSection] = useState({
    subject_id: null,
    name: null,
  });

  useEffect(() => {
    Request.get("/subject-all")
      .then((res) => {
        // console.log(res.data.subjects)
        setSubjects(res.data.subjects);
      })
      .catch((err) => {
        console.log(err);
        message.error("Fanlarni olishda xatolik mavjud");
      });
  }, []);

  return (
    <div>
      <Table
        showSizeChanger={true}
        loading={loading}
        size={"middle"}
        dataSource={data}
        scroll={{ y: "53vh" }}
        pagination={{ position: ["none", "bottomLeft"] }}
        style={{ width: "100%", padding: "3%" }}
        // rowClassName={() => {
        //     return "noAktivStatus"
        // }}
      >
        <Column
          title="№"
          key="id"
          render={(record) => <span>{record.id}</span>}
        />
        <Column
          title="Fan"
          key="subject"
          render={(record) => <span>{record.subject}</span>}
        />
        <Column
          title="Bo'lim"
          key="section"
          render={(record) => <span>{record.section}</span>}
        />
        <Column
          title="Action"
          key="status"
          render={(record) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "70%",
                justifyContent: "space-between",
              }}
            >
              <BsPencil
                className={"settingsDtmActionsIcon"}
                onClick={() => {
                  setEditModalData(record);
                  setEditModalOpen(true);
                }}
              />
              <Popconfirm
                title={"Tasdiqlash"}
                okText={"O'chirish"}
                cancelText={"Ortga"}
                onConfirm={() => {
                  message.warn("O'chirish xizmati o'chgan!");
                  // deleteSectionFunc(record)
                }}
              >
                <AiOutlineDelete className={"settingsDtmActionsIcon"} />
              </Popconfirm>
            </div>
          )}
        />
      </Table>
      <Modal
        visible={editModalOpen}
        closable={false}
        centered={true}
        onCancel={() => {
          setEditModalOpen(false);
        }}
        footer={null}
      >
        <div>
          <div
            onClick={() => {
              setEditModalOpen(false);
            }}
            className={"settingsSubjectEditModalCloseIconCont"}
          >
            <IoMdClose />
          </div>
          <Typography className={"settingsSubjectEditModalTitle"}>
            Yangilash
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "80%",
              margin: "1vw auto",
            }}
          >
            <label
              className={"nameEditInputLabel"}
              htmlFor={"nameEditInputSubject"}
            >
              Fan
            </label>
            <Select
              value={editModalData && editModalData.subject}
              style={{ width: "90%" }}
              onChange={(e, data) => {
                setEditModalData({
                  ...editModalData,
                  subject_id: e,
                  subject: data.name,
                });
              }}
            >
              {subjects.map((subject) => (
                <Option value={subject.id} key={subject.id} name={subject.name}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "80%",
              margin: "1vw auto",
            }}
          >
            <label className={"nameEditInputLabel"} htmlFor={"nameEditInput"}>
              Bo'lim
            </label>
            <Input
              value={editModalData && editModalData.section}
              id={"nameEditInput"}
              onChange={(e) => {
                setEditModalData({
                  ...editModalData,
                  section: e.target.value,
                });
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="primary"
              className={"settingsEditModalSaveButton"}
              onClick={() => {
                if (editModalData.section && editModalData.subject_id) {
                  editSectionFunc(editModalData);
                  setEditModalOpen(false);
                } else {
                  message.error("Ma'lumotlar xato kiritilgan!");
                }
              }}
            >
              Saqlash
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={addNewSubjectModal}
        closable={false}
        centered={true}
        onCancel={() => {
          closeAddNewItemModal();
        }}
        footer={null}
      >
        <div>
          <div
            onClick={() => {
              closeAddNewItemModal();
            }}
            className={"settingsSubjectEditModalCloseIconCont"}
          >
            <IoMdClose />
          </div>
          <Typography className={"settingsSubjectEditModalTitle"}>
            Bo'lim qo'shish
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "80%",
              margin: "1vw auto",
            }}
          >
            <label
              className={"nameEditInputLabel"}
              htmlFor={"nameEditInputSubject"}
            >
              Fan
            </label>
            <Select
              style={{ width: "90%" }}
              onChange={(e) => {
                setNewSection({
                  ...newSection,
                  subject_id: e,
                });
              }}
            >
              {subjects.map((subject) => (
                <Option value={subject.id} key={subject.id} name={subject.name}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "80%",
              margin: "1vw auto",
            }}
          >
            <label className={"nameEditInputLabel"} htmlFor={"nameEditInput"}>
              Bo'lim
            </label>
            <Input
              id={"nameEditInput"}
              onChange={(e) => {
                setNewSection({
                  ...newSection,
                  name: e.target.value,
                });
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="primary"
              className={"settingsEditModalSaveButton"}
              onClick={() => {
                if (newSection.name && newSection.subject_id) {
                  addNewItemFunc(newSection);
                } else {
                  message.error("Ma'lumotlar xato kiritilgan!");
                }
              }}
            >
              Saqlash
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Section;
