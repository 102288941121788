import types from './types'
import {MetaActions,backEndType,getMeta} from './actionTypes.js'
import {Dispatch} from "redux";

export const changeMetaSection = (selectedSection: string) => {
  return (dispatch: Dispatch<MetaActions>) => {
    dispatch({
      type: types.META_CHANGE_SECTION,
      payload: selectedSection
    })
  }
}

export const getSubjects = (subjects: backEndType[]) => {
  return (dispatch: Dispatch<MetaActions>) => {
    dispatch({
      type: types.META_GET_SUBJECTS,
      payload: subjects
    })
  }
}

export const getTests = (tests: backEndType[]) => {
  return (dispatch: Dispatch<MetaActions>) => {
    dispatch({
      type: types.META_GET_TESTS,
      payload: tests
    })
  }
}

export const testsMeta = (testsMeta: getMeta[]) => {
  return (dispatch: Dispatch<MetaActions>) => {
    dispatch({
      type: types.META_TESTS,
      payload: testsMeta
    })
  }
}

export const subjectsMeta = (subjectsMeta: getMeta[]) => {
  return (dispatch: Dispatch<MetaActions>) => {
    dispatch({
      type: types.META_SUBJECTS,
      payload: subjectsMeta
    })
  }
}

export const pagesMeta = (pagesMeta: getMeta[]) => {
  return (dispatch: Dispatch<MetaActions>) => {
    dispatch({
      type: types.META_PAGES,
      payload: pagesMeta
    })
  }
}

export const backToInitialState = () => ({
  type: types.META_BACK_TO_INITIAL,
  payload: null
})