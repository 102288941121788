import { BackTop, Button, Pagination } from 'antd'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { actions } from '../../../redux/actions'
import { isFile } from '../../../service/common/isFile'
import Request from '../../../service/request/Request'
import { MathjaxContext } from './Mathjax'
import QuestionAdd from './QuestionAdd'

const { addBlankQuestion, confirmQuestion, saveFetchedQuestion } = actions
const initialState = [{ correctAnswer: 1, isChecked: false }]

const QuestionList = (props) => {
  const {
    collectionId,
    reduxQuestions,
    addBlankQuestion,
    confirmQuestion,
    saveFetchedQuestion,
    editing,
  } = props
  const [hasSuccess, setHasSuccess] = useState(editing)
  const [loading, setLoading] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [shownQuestions, setShownQuestions] = useState([0, 30])
  const handleConfirm = (index) => {
    confirmQuestion(index)

    const question = reduxQuestions[index]
    const data = {
      question: {
        id: question.id,
        text: question.question,
      },
      answers: {
        a: {
          text: question.a,
          isCorrect: question.correctAnswer === 1,
        },
        b: {
          text: question.b,
          isCorrect: question.correctAnswer === 2,
        },
        c: {
          text: question.c,
          isCorrect: question.correctAnswer === 3,
        },
        d: {
          text: question.d,
          isCorrect: question.correctAnswer === 4,
        },
      },
    }
    const formData = new FormData()
    formData.append('question', JSON.stringify(data))
    formData.append('id', collectionId)
    console.log('question', JSON.parse(formData.get('question')))

    question.question_photo &&
      isFile(question.question_photo) &&
      formData.append(`q`, question.question_photo, 'q.png')
    question.a_photo &&
      isFile(question.a_photo) &&
      formData.append(`a`, question.a_photo, 'a.png')
    question.b_photo &&
      isFile(question.b_photo) &&
      formData.append(`b`, question.b_photo, 'b.png')
    question.c_photo &&
      isFile(question.c_photo) &&
      formData.append(`c`, question.c_photo, 'c.png')
    question.d_photo &&
      isFile(question.d_photo) &&
      formData.append(`d`, question.d_photo, 'd.png')
    const copy = loading.slice()
    copy[index] = true
    setLoading(copy)
    Request.post('/add-question', formData)
      .then((res) => {
        // console.log(res.data);
        saveFetchedQuestion(index, res.data)
        const copy = loading.slice()
        copy[index] = false
        setLoading(copy)
      })
      .catch((err) => {
        const copy = loading.slice()
        copy[index] = false
        setLoading(copy)
        console.log(err.message)
      })

    const checkedQuestions = reduxQuestions.filter(
      (question) => question.isChecked === true
    )
    // console.log('checkedQuestions', checkedQuestions)
    if (checkedQuestions.length === reduxQuestions.length) {
      setHasSuccess(true)
    } else setHasSuccess(false)
  }
  // console.log('object');

  const handleAdd = () => {
    const page = Math.floor(reduxQuestions.length / 30) + 1
    const calcIndex = (page - 1) * 30
    addBlankQuestion(initialState)
    setHasSuccess(false)
    setCurrentPage(page)
    setShownQuestions([calcIndex, calcIndex + 30])
    // without condition and setTimeout fn could have an error
    if (reduxQuestions.length > 0) {
      setTimeout(() => {
        const list = document.getElementById('questionList')
        list.scrollTop = list.scrollHeight
      }, 1)
    } else return
  }

  const handlePaginationChange = (page, pageSize) => {
    const calcIndex = (page - 1) * 30

    setCurrentPage(page)
    setShownQuestions([calcIndex, calcIndex + 30])
  }

  return (
    <div className="questionList" id="questionList">
      <Pagination
        showSizeChanger={false}
        showQuickJumper
        defaultCurrent="1"
        current={currentPage}
        onChange={handlePaginationChange}
        total={reduxQuestions.length}
        pageSize={30}
        className="questionList_pagination"
      />
      <BackTop target={() => document.getElementById('questionList')} duration={1} />
      <MathjaxContext>
        <>
          {reduxQuestions
            .slice(shownQuestions[0], shownQuestions[1])
            .map((question, i) => {
              const calcIndex = (currentPage - 1) * 30
              return (
                <QuestionAdd
                  loading={loading[calcIndex + i]}
                  input={question}
                  key={question.id ? question.id : i + 1}
                  index={calcIndex + i}
                  handleConfirm={handleConfirm}
                />
              )
            })}
        </>
      </MathjaxContext>
      <Button
        disabled={!hasSuccess}
        className="add-btn"
        type="primary"
        onClick={handleAdd}
      >
        + test qo'shish
      </Button>
    </div>
  )
}
const state2props = (state) => {
  return {
    reduxQuestions: state.questions,
  }
}
const dispatch2props = {
  addBlankQuestion,
  confirmQuestion,
  saveFetchedQuestion,
}
export default connect(state2props, dispatch2props)(QuestionList)
