import React from "react";
import { Route, useLocation } from "react-router-dom";
// import IsLogin from "../security/IsLogin";
import Settings from "./content/settings/Settings";
import TestsMainPage from "./content/mainTestsPage/TestsMainPage";
import Users from "./content/users/Users";
import WrongQuestions from "./content/wrongQuestions/WrongQuestions";
import StatisticsPage from "./content/statistics/StatisticsPage";
import News from "./content/news/News";
import NewsEdit from "./content/news/NewsEdit";
import NewsAdd from "./content/news/NewsAdd";
import { allPaths } from "../service/helper/allPaths";

const Content = () => {
  const { pathname } = useLocation();
  const isOnTestPage =
    pathname.includes("anotherTests") ||
    pathname.includes("virtualTests") ||
    pathname.includes("dtm");

  return (
    <div className={`${isOnTestPage ? "scrollable_content" : ""} contentCont`}>
      <Route exact path={["/dtm"]}>
        <TestsMainPage component={"dtm"} />
      </Route>
      <Route exact path={["/virtualTests"]}>
        <TestsMainPage component={"virtual"} />
      </Route>
      <Route exact path="/virtualTests/edit">
        <TestsMainPage editing component={"virtual"} />
      </Route>
      <Route exact path="/anotherTests/edit">
        {/* <IsLogin> */}
        <TestsMainPage editing component={"lesson"} />
        {/* </IsLogin> */}
      </Route>
      <Route exact path="/dtm/edit">
        {/* <IsLogin> */}
        <TestsMainPage editing component={"dtm"} />
        {/* </IsLogin> */}
      </Route>
      <Route exact path={["/anotherTests"]}>
        {/* <IsLogin> */}
        <TestsMainPage component={"lesson"} />
        {/* </IsLogin> */}
      </Route>
      <Route exact path={["/statistics"]}>
        {/* <IsLogin> */}
        <StatisticsPage />
        {/* </IsLogin> */}
      </Route>
      <Route exact path={["/users"]}>
        {/* <IsLogin> */}
        <Users />
        {/* </IsLogin> */}
      </Route>
      <Route exact path={["/wrongQuestions"]}>
        {/* <IsLogin> */}
        <WrongQuestions />
        {/* </IsLogin> */}
      </Route>
      <Route exact path={["/settings"]}>
        {/* <IsLogin> */}
        <Settings />
        {/* </IsLogin> */}
      </Route>
      <Route exact path={allPaths.NEWS}>
        <News />
      </Route>
      <Route exact path={allPaths.NEWS_ID}>
        <NewsEdit />
      </Route>
      <Route exact path={allPaths.ADD_NEWS}>
        <NewsAdd />
      </Route>
    </div>
  );
};

export default Content;
