import types from './types'
import {SettingsAction} from './actionTypes.js'


interface SettingsType  {
  openCreateModal: boolean
}

const initialState = {
  openCreateModal: false
}

const settingsReducer = (state: SettingsType = initialState, action: SettingsAction ) => {
  switch (action.type) {
    case types.SETTINGS_OPEN_CREATE_MODAL:
      return { ...state, openCreateModal: action.payload}
    default:
      return state
  }
}

export default settingsReducer
