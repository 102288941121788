import { Button, Form, message, Modal, Select } from "antd";
import React, { FC, useEffect, useState } from "react";
import Request from "../../../service/request/Request";

interface IProps {
  visible: boolean;
  data: number[];
  setVisible: (visible: boolean) => void;
}

const CreateSectionModal: FC<IProps> = ({ data, setVisible, visible }) => {
  const [sectionConnect, setSectionConnect] = useState(false);
  const [form] = Form.useForm();

  const [sectionAllData, setSectionAllData] = useState({
    data: [{ id: 0, section: "" }],
    loading: true,
  });

  useEffect(() => {
    Request.get("/section-all")
      .then((res) => {
        setSectionAllData({
          data: res.data.sections,
          loading: false,
        });
      })
      .catch((err) => {
        message.error("Bo'limni olishda xatolik mavjud!");
      });
  }, []);

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onFinish = (values: any) => {
    setSectionConnect(true);
    Request.post("/lesson-attach-section", {
      test_ids: data,
      section_id: Number(values.section),
    })
      .then((res) => {
        setSectionConnect(false);
        message.success("Muvaffaqiyatli biriktirildi");
        handleCancel();
      })
      .catch((err) => {
        setSectionConnect(false);
        message.error("Biriktirishda xatolik!");
      });
  };

  return (
    <Modal
      footer={false}
      title={false}
      visible={visible}
      onCancel={handleCancel}
      wrapClassName="createSectionModal"
    >
      <h1>Bo'limga biriktirish</h1>
      <Form labelCol={{ span: 4 }} form={form} onFinish={onFinish}>
        <Form.Item label="Bo'lim" name={"section"}>
          <Select style={{ width: 358 }} loading={sectionAllData.loading}>
            {sectionAllData.data.map((item) => (
              <Select.Option key={item.id} value={item?.id}>
                {item?.section}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button htmlType="submit" type="primary" loading={sectionConnect}>
          Saqlash
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateSectionModal;
