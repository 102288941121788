import { Button, Input, message, Select, Spin } from "antd";
import React, { useState, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ImageResize } from "quill-image-resize-module-ts";

import { allPaths } from "../../../service/helper/allPaths";
import { allReqUrl } from "../../../service/helper/allReqUrl";
import Request from "../../../service/request/Request";

const { Option } = Select;

type CategoryType = {
  category_id: number;
  name: string;
}[];

Quill.register("modules/imageResize", ImageResize);

function NewsEdit() {
  // URL pathname
  const { pathname } = useLocation();

  // history
  const { push } = useHistory();

  // Article id
  const articleData = useSelector((state: any) => state.articleIdValue.data);

  // quill value
  const [quillValue, setQuillValue] = useState<string>(articleData.description);

  // change Article name value
  const [articleName, setArticleName] = useState<string>(articleData.name);

  // article categorys value
  const [category, setCategory] = useState<CategoryType>([]);

  // category id
  const [categoryId, setCategoryId] = useState<number>(articleData.category_id);

  // loading
  const [loader, setLoader] = useState<boolean>(false);

  // loading
  const [loaderSave, setLoaderSave] = useState<boolean>(false);

  // lang value
  const [lang, setLang] = useState<number>(articleData.language_id);

  // quill formats
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "size",
    "color",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align"
  ];

  // quill modules
  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ size: ["small", false, "large", "huge"] }, { color: [] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
          { align: [] }
        ],
        ["link", "image", "video"],
        ["clean"]
      ]
    },
    clipboard: { matchVisual: false },
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"]
    }
  };

  // change content
  const changeContent = (e: string) => {
    setQuillValue(e);
  };

  // change Article name
  const changeArticleName = (e: any) => {
    setArticleName(e?.target?.value);
  };

  // article categorys
  useEffect(() => {
    if (pathname.slice(0, 5) === allPaths.NEWS) {
      Request.getArticlesCategory(allReqUrl.ALL_CATEGORY)
        .then((data) => {
          setCategory(data.data);
          setCategoryId(data?.data[0]?.category_id);
        })
        .catch((err) => console.log(err));
    }
  }, [pathname]);

  // change categorys
  const changeCategory = (e: number) => {
    setCategoryId(e);
  };

  // Submit article
  const submitPutArticle = () => {
    if (!articleName || !quillValue) {
      message.info("Maqola nomi yoki sarlavhasi kiritilmagan");
    } else {
      setLoader(true);
      let data = {};
      if (lang === 2) {
        data = {
          name: articleName,
          category_id: categoryId,
          description: quillValue,
          status: "active",
          language_id: lang,
          article_id: articleData.article_id
        };
      } else {
        data = {
          name: articleName,
          category_id: categoryId,
          description: quillValue,
          status: "active",
          language_id: lang,
          article_id: articleData.article_id
        };
      }
      Request.putArticle(allReqUrl.UPDATE_ARTICLES, data)
        .then(() => {
          message.success("Maqola o'zgartirildi!");
          setLoader(false);
          if (!loader) {
            push(allPaths.NEWS);
          }
        })
        .catch(() => {
          message.error("Maqola o'zgartirishda xatolik mavjud");
          setLoader(false);
        });
    }
  };

  // save Article
  const savePutArticle = () => {
    if (!articleName || !quillValue) {
      message.info("Maqola nomi yoki sarlavhasi kiritilmagan");
    } else {
      setLoaderSave(true);
      let data = {};
      if (lang === 2) {
        data = {
          name: articleName,
          category_id: categoryId,
          description: quillValue,
          status: "passive",
          language_id: lang,
          article_id: articleData.article_id
        };
      } else {
        data = {
          name: articleName,
          category_id: categoryId,
          description: quillValue,
          status: "passive",
          language_id: lang,
          article_id: articleData.article_id
        };
      }
      Request.putArticle(allReqUrl.UPDATE_ARTICLES, data)
        .then(() => {
          message.success("Maqola saqlab qo'yildi!");
          setLoaderSave(false);
          if (!loaderSave) {
            push(allPaths.NEWS);
          }
        })
        .catch(() => {
          message.error("Maqolani saqlashda xatolik mavjud");
          setLoaderSave(false);
        });
    }
  };

  // change language
  const changeLang = (value: number) => {
    setLang(value);
  };
  console.log(articleData);

  return (
    <div className="edit_news">
      <h2>Maqolani tahrirlash</h2>
      <div className="add_news_content">
        <div className="add_news_content_title">
          <div className="add_news_content_title_name">
            <label htmlFor="title">Maqola nomi</label>
            <Input
              id="title"
              value={articleName}
              onChange={changeArticleName}
            />
          </div>
          <div className="add_news_content_title_lang">
            <label htmlFor="lang">Til</label>
            <Select value={lang} onChange={changeLang}>
              <Option value={1}>O'zbek</Option>
              <Option value={2}>Русский язык</Option>
            </Select>
          </div>
        </div>
        <div className="add_news_content_text">
          <div className="add_news_content_text_left">
            <ReactQuill
              theme="snow"
              value={quillValue}
              onChange={changeContent}
              formats={formats}
              modules={modules}
            />
          </div>
          <div className="add_news_content_text_right">
            <div className="add_news_content_text_right_top">
              <label>Maqola turi</label>
              <Select value={categoryId} onChange={changeCategory}>
                {category.map((item) => (
                  <Option value={item.category_id}>{item.name}</Option>
                ))}
              </Select>
            </div>
            <div className="add_news_content_text_right_bottom">
              <Button type="primary" onClick={submitPutArticle}>
                <Spin spinning={loader}>Chop etish</Spin>
              </Button>
              <Button onClick={savePutArticle}>
                <Spin spinning={loaderSave}>Saqlab qo'yish</Spin>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(NewsEdit);
