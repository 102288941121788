import React, { FC } from "react";

const RefreshCircleIcon: FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
        stroke="#0C4292"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00732 10.8825C6.14232 11.1075 6.30732 11.3175 6.49482 11.505C7.87482 12.885 10.1173 12.885 11.5048 11.505C12.0673 10.9425 12.3898 10.2299 12.4948 9.50244"
        stroke="#0C4292"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.50488 8.49749C5.60988 7.76249 5.93239 7.05746 6.49489 6.49496C7.87489 5.11496 10.1174 5.11496 11.5049 6.49496C11.6999 6.68996 11.8574 6.89997 11.9924 7.11747"
        stroke="#0C4292"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.86499 12.885V10.8825H7.86748"
        stroke="#0C4292"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1348 5.11499V7.11748H10.1323"
        stroke="#0C4292"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshCircleIcon;
