import {
  Button,
  Input,
  message,
  Popconfirm,
  Select,
  Switch,
  Table,
  Typography,
} from 'antd'
import Modal from 'antd/es/modal/Modal'
import Column from 'antd/es/table/Column'
import React, { useEffect, useState } from 'react'
import { AiOutlineDelete, BsPencil, IoMdClose } from 'react-icons/all'
import Request from '../../../../service/request/Request'

const { Option } = Select

function Faculty(props) {
  const [subjects, setSubjects] = useState([])
  const [editData, setEditData] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [defaultSubjects, setDefaultSubjects] = useState({
    first: null,
    second: null,
    third: null,
  })

  const {
    data,
    loading,
    rerenderFunc,
    editFacultyFunc,
    addNewSubjectModal,
    closeAddNewItemModal,
    addNewItemFunc,
  } = props

  const [newFaculty, setNewFaculty] = useState({
    name: null,
    code: null,
    subject1: null,
    subject2: null,
    subject3: null,
    subject4: null,
    subject5: null,
  })

  useEffect(() => {
    Request.get('/subject-all').then((res) => {
      let defaultSubjectsLet = {
        first: null,
        second: null,
        third: null,
      }
      res.data.subjects.map((subject) => {
        if (subject.name === 'Matematika') {
          defaultSubjectsLet.first = subject
        } else if (subject.name === 'Ona tili') {
          defaultSubjectsLet.second = subject
        } else if (subject.name === "O'zbekiston tarixi") {
          defaultSubjectsLet.third = subject
        }
        return true
      })
      setDefaultSubjects(defaultSubjectsLet)
      setSubjects(res.data.subjects)
    })
  }, [])

  return (
    <div>
      <Table
        showSizeChanger={true}
        loading={loading}
        size={'middle'}
        dataSource={data}
        scroll={{ y: '53vh' }}
        pagination={{ position: ['none', 'bottomLeft'] }}
        style={{ width: '100%', padding: '3%' }}
      >
        <Column title="Id" key="id" render={(record) => <span>{record.id}</span>} />
        <Column
          title="Kodi"
          key="code"
          render={(record) => <span>{record.code}</span>}
        />
        <Column
          title="Yo'nalish nomi"
          key="name"
          render={(record) => <span>{record.name}</span>}
        />
        <Column
          title="Fanlar"
          key="subject"
          render={(record) => (
            <>
              <div>{record.subject1_name}</div>
              <div>{record.subject2_name}</div>
            </>
          )}
        />
        <Column
          title="Status"
          key="status"
          render={(record) => (
            <Switch
              checked={record.status === 1}
              onChange={(e) => {
                console.log(e)
                Request.post('/course-change-status', {
                  id: record.id,
                  status: e ? 1 : 0,
                })
                  .then((res) => {
                    // console.log(res)
                    rerenderFunc('faculty')
                  })
                  .catch((err) => {
                    message.error('Xatolik mavjud!')
                  })
              }}
              size="small"
            />
          )}
        />
        <Column
          width={'10%'}
          title="Action"
          key="status"
          render={(record) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <BsPencil
                onClick={() => {
                  Request.post('/course-one', {
                    id: record.id,
                  })
                    .then((res) => {
                      setEditData(res.data.course)
                      setEditModal(true)
                    })
                    .catch((err) => {
                      message.error('Xatolik mavjud!')
                    })
                }}
                className={'settingsDtmActionsIcon'}
              />
              <Popconfirm
                onConfirm={() => {
                  message.warn("O'chirish xizmati o'chgan!")
                  // deleteFacultyFunc(record)
                }}
                title="Tasdiqlash"
                okText="O'chirish"
                cancelText="Ortga qaytish"
              >
                <AiOutlineDelete className={'settingsDtmActionsIcon'} />
              </Popconfirm>
            </div>
          )}
        />
      </Table>
      <Modal
        visible={editModal}
        closable={false}
        onCancel={() => {
          setEditModal(false)
        }}
        footer={null}
      >
        <div>
          <div
            onClick={() => {
              setEditModal(false)
            }}
            className={'settingsSubjectEditModalCloseIconCont'}
          >
            <IoMdClose />
          </div>
          <Typography className={'settingsSubjectEditModalTitle'}>
            Ta’lim yo’nalishini o'zgartirish
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'codeEdit'}>
              Kodi
            </label>
            <Input
              id={'codeEdit'}
              value={editData && editData.code}
              onChange={(e) => {
                setEditData({
                  ...editData,
                  code: e.target.value,
                })
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'nameEditInputEdit'}>
              Nomi
            </label>
            <Input
              width={'80%'}
              id={'nameEditInputEdit'}
              value={editData && editData.name}
              onChange={(e) => {
                setEditData({
                  ...editData,
                  name: e.target.value,
                })
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'firstSubjectEdit'}>
              Fan-1
            </label>
            <Select
              id={'firstSubjectEdit'}
              style={{
                width: '70%',
              }}
              value={editData && editData.subject1_name}
              onChange={(e, data) => {
                setEditData({
                  ...editData,
                  subject1: e,
                  subject1_name: data.name,
                })
              }}
            >
              {subjects.map((subject) => (
                <Option value={subject.id} name={subject.name} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'secondSubject'}>
              Fan-2
            </label>
            <Select
              id={'secondSubject'}
              style={{
                width: '70%',
              }}
              value={editData && editData.subject2_name}
              onChange={(e, data) => {
                setEditData({
                  ...editData,
                  subject2: e,
                  subject2_name: data.name,
                })
              }}
            >
              {subjects.map((subject) => (
                <Option value={subject.id} name={subject.name} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'thirdSubject'}>
              Fan-3
            </label>
            <Select
              id={'thirdSubject'}
              style={{
                width: '70%',
              }}
              disabled
              value={editData && editData.subject3_name}
              onChange={(e, data) => {
                setEditData({
                  ...editData,
                  subject3: e,
                  subject3_name: data.name,
                })
              }}
            >
              {subjects.map((subject) => (
                <Option value={subject.id} name={subject.name} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'fourthSubject'}>
              Fan-4
            </label>
            <Select
              id={'fourthSubject'}
              style={{
                width: '70%',
              }}
              disabled
              value={editData && editData.subject4_name}
              onChange={(e, data) => {
                setEditData({
                  ...editData,
                  subject4: e,
                  subject4_name: data.name,
                })
              }}
            >
              {subjects.map((subject) => (
                <Option value={subject.id} name={subject.name} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'fifthSubject'}>
              Fan-5
            </label>
            <Select
              id={'fifthSubject'}
              style={{
                width: '70%',
              }}
              disabled
              value={editData && editData.subject5_name}
              onChange={(e, data) => {
                setEditData({
                  ...editData,
                  subject5: e,
                  subject5_name: data.name,
                })
              }}
            >
              {subjects.map((subject) => (
                <Option value={subject.id} name={subject.name} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              type="primary"
              className={'settingsEditModalSaveButton'}
              onClick={() => {
                if (
                  editData.name &&
                  editData.subject1 &&
                  editData.subject2 &&
                  editData.subject3 &&
                  editData.subject4 &&
                  editData.subject5 &&
                  editData.code
                ) {
                  let data = {
                    id: editData.id,
                    name: editData.name,
                    code: editData.code,
                    subject1: editData.subject1,
                    subject2: editData.subject2,
                    subject3: editData.subject3,
                    subject4: editData.subject4,
                    subject5: editData.subject5,
                  }
                  editFacultyFunc(data)
                  setEditModal(false)
                } else {
                  message.error("Ma'lumotlar to'liq emas!")
                }
              }}
            >
              Saqlash
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={addNewSubjectModal}
        closable={false}
        onCancel={() => {
          closeAddNewItemModal()
        }}
        footer={null}
      >
        <div>
          <div
            onClick={() => {
              closeAddNewItemModal()
            }}
            className={'settingsSubjectEditModalCloseIconCont'}
          >
            <IoMdClose />
          </div>
          <Typography className={'settingsSubjectEditModalTitle'}>
            Ta’lim yo’nalishini qo‘shish
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'code'}>
              Kodi
            </label>
            <Input
              id={'code'}
              onChange={(e) => {
                setNewFaculty({
                  ...newFaculty,
                  code: e.target.value,
                })
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'nameEditInput'}>
              Nomi
            </label>
            <Input
              width={'80%'}
              id={'nameEditInput'}
              onChange={(e) => {
                setNewFaculty({
                  ...newFaculty,
                  name: e.target.value,
                })
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'firstSubject'}>
              Fan-1
            </label>
            <Select
              id={'firstSubject'}
              style={{
                width: '70%',
              }}
              onChange={(e) => {
                setNewFaculty({
                  ...newFaculty,
                  subject1: e,
                })
              }}
            >
              {subjects.map((subject) => (
                <Option value={subject.id} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'secondSubject'}>
              Fan-2
            </label>
            <Select
              id={'secondSubject'}
              style={{
                width: '70%',
              }}
              onChange={(e) => {
                setNewFaculty({
                  ...newFaculty,
                  subject2: e,
                })
              }}
            >
              {subjects.map((subject) => (
                <Option value={subject.id} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'thirdSubject'}>
              Fan-3
            </label>
            <Select
              id={'thirdSubject'}
              style={{
                width: '70%',
              }}
              disabled
              value={defaultSubjects.first && defaultSubjects.first.name}
              onChange={(e) => {
                setNewFaculty({
                  ...newFaculty,
                  subject3: e,
                })
              }}
            >
              {subjects.map((subject) => (
                <Option value={subject.id} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'fourthSubject'}>
              Fan-4
            </label>
            <Select
              id={'fourthSubject'}
              style={{
                width: '70%',
              }}
              disabled
              value={defaultSubjects.second && defaultSubjects.second.name}
              onChange={(e) => {
                setNewFaculty({
                  ...newFaculty,
                  subject4: e,
                })
              }}
            >
              {subjects.map((subject) => (
                <Option value={subject.id} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'fifthSubject'}>
              Fan-5
            </label>
            <Select
              id={'fifthSubject'}
              style={{
                width: '70%',
              }}
              disabled
              value={defaultSubjects.third && defaultSubjects.third.name}
              onChange={(e) => {
                setNewFaculty({
                  ...newFaculty,
                  subject5: e,
                })
              }}
            >
              {subjects.map((subject) => (
                <Option value={subject.id} key={subject.id}>
                  {subject.name}
                </Option>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              type="primary"
              className={'settingsEditModalSaveButton'}
              onClick={() => {
                if (
                  newFaculty.name &&
                  newFaculty.code &&
                  newFaculty.subject1 &&
                  newFaculty.subject2
                ) {
                  let data = newFaculty
                  data.subject3 = defaultSubjects.first.id
                  data.subject4 = defaultSubjects.second.id
                  data.subject5 = defaultSubjects.third.id
                  addNewItemFunc(data)
                } else {
                  message.error("Ma'lumotlar xato to'ldirilgan")
                }
              }}
            >
              Saqlash
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Faculty
