import React from "react";

const FilterIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 6.99996C10.5 6.84525 10.4385 6.69688 10.3291 6.58748C10.2197 6.47808 10.0714 6.41663 9.91667 6.41663H4.08333C3.92862 6.41663 3.78025 6.47808 3.67085 6.58748C3.56146 6.69688 3.5 6.84525 3.5 6.99996C3.5 7.15467 3.56146 7.30304 3.67085 7.41244C3.78025 7.52184 3.92862 7.5833 4.08333 7.5833H9.91667C10.0714 7.5833 10.2197 7.52184 10.3291 7.41244C10.4385 7.30304 10.5 7.15467 10.5 6.99996Z"
        fill="#2B65F0"
      />
      <path
        d="M12.25 3.49996C12.25 3.34525 12.1885 3.19688 12.0791 3.08748C11.9697 2.97808 11.8214 2.91663 11.6667 2.91663H2.33333C2.17862 2.91663 2.03025 2.97808 1.92085 3.08748C1.81146 3.19688 1.75 3.34525 1.75 3.49996C1.75 3.65467 1.81146 3.80304 1.92085 3.91244C2.03025 4.02183 2.17862 4.08329 2.33333 4.08329H11.6667C11.8214 4.08329 11.9697 4.02183 12.0791 3.91244C12.1885 3.80304 12.25 3.65467 12.25 3.49996Z"
        fill="#2B65F0"
      />
      <path
        d="M5.24984 9.91663C5.09513 9.91663 4.94675 9.97808 4.83736 10.0875C4.72796 10.1969 4.6665 10.3453 4.6665 10.5C4.6665 10.6547 4.72796 10.803 4.83736 10.9124C4.94675 11.0218 5.09513 11.0833 5.24984 11.0833H8.74983C8.90454 11.0833 9.05292 11.0218 9.16231 10.9124C9.27171 10.803 9.33317 10.6547 9.33317 10.5C9.33317 10.3453 9.27171 10.1969 9.16231 10.0875C9.05292 9.97808 8.90454 9.91663 8.74983 9.91663H5.24984Z"
        fill="#2B65F0"
      />
    </svg>
  );
};

export default FilterIcon;
