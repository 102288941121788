import { CloseOutlined } from '@ant-design/icons'
import {
  Button,
  Image,
  Input,
  message,
  Popconfirm,
  Switch,
  Table,
  Typography,
} from 'antd'
import Modal from 'antd/es/modal/Modal'
import Column from 'antd/es/table/Column'
import React, { useEffect, useState } from 'react'
import { AiOutlineDelete, BiImage, BsPencil, IoMdClose } from 'react-icons/all'
import { isFile } from '../../../../service/common/isFile'
import Request, { BASE_IMAGE_URL } from '../../../../service/request/Request'

function University(props) {
  const {
    data,
    loading,
    rerenderFunc,
    addNewSubjectModal,
    closeAddNewItemModal,
    editUniversityFunc,
    addNewItemFunc,
  } = props

  const [addNewUniversity, setAddNewUniversity] = useState({
    name: null,
    phone: null,
    map: null,
    address: null,
    photo: null,
  })

  const [openEditModal, setOpenEditModal] = useState(false)
  const [editData, setEditData] = useState({})

  const validateUpload = (e) =>
    e.target.files[0].type === 'image/png' ||
    e.target.files[0].type === 'image/jg' ||
    e.target.files[0].type === 'image/jpeg'

  const upload = (e, type) => {
    if (e.target.files[0].size <= 10000000) {
      let file = e.target.files[0]
      if (type === 'new') {
        setAddNewUniversity({
          ...addNewUniversity,
          photo: file,
        })
      } else if (type === 'change') {
        setEditData({
          ...editData,
          image: file,
        })
      }
    } else {
      message.error('Rasm hajmi katta')
    }
  }

  useEffect(() => {
    setAddNewUniversity({
      name: null,
      phone: null,
      map: null,
      address: null,
      photo: null,
    })
  }, [addNewSubjectModal])

  const imgPreview = (img, width = 250, targetName) => {
    let src = ''
    if (isFile(img)) {
      src = URL.createObjectURL(img)
    } else {
      src = BASE_IMAGE_URL + 'university/' + img
      // console.log(src)
    }

    return (
      <div className="img">
        {width >= 250 ? (
          <Image src={src} width={width} />
        ) : (
          <img src={src} width={width} alt="university" />
        )}

        {width >= 250 ? (
          <Button danger size="small" shape="circle" onClick={() => {}}>
            <CloseOutlined />
          </Button>
        ) : null}
      </div>
    )
  }

  return (
    <div>
      <Table
        showSizeChanger={true}
        loading={loading}
        size={'small'}
        dataSource={data}
        style={{
          width: '95%',
          margin: '0 auto',
        }}
        scroll={{ y: '56vh' }}
        pagination={{ position: ['none', 'bottomLeft'] }}
      >
        <Column title="Id" key="id" render={(record) => <span>{record.id}</span>} />
        <Column
          title="Ta’lim muassasasi nomi"
          key="name"
          render={(record) => <span>{record.name}</span>}
        />
        <Column
          title="Manzil"
          key="address"
          render={(record) => <span>{record.address}</span>}
        />
        <Column
          title="Aloqa"
          key="phone"
          render={(record) => <span>{record.phone}</span>}
        />
        <Column
          title="Status"
          key="status"
          render={(record) => (
            <Switch
              size="small"
              checked={record.status === 1}
              onChange={(e) => {
                console.log(e)
                Request.post('/university-change-status', {
                  id: record.id,
                  status: e ? 1 : 0,
                })
                  .then((res) => {
                    // console.log(res)
                    rerenderFunc('university')
                  })
                  .catch((err) => {
                    message.error('Xatolik mavjud!')
                  })
              }}
            />
          )}
        />
        <Column
          width={'6%'}
          title="Action"
          key="status"
          render={(record) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'space-between',
              }}
            >
              <BsPencil
                onClick={() => {
                  Request.post('/university-one', {
                    id: record.id,
                  })
                    .then((res) => {
                      setEditData(res.data.university)
                      setOpenEditModal(true)
                    })
                    .catch((err) => {
                      console.log(err)
                      message.error('Xatolik mavjud')
                    })
                }}
                className={'settingsDtmActionsIcon'}
              />
              <Popconfirm
                title={'Tasdiqlash'}
                okText={"O'chirish"}
                cancelText={'Ortga'}
                onConfirm={() => {
                  message.warn("O'chirish xizmati o'chgan!")
                  // deleteUniversityFunc(record)
                }}
              >
                <AiOutlineDelete className={'settingsDtmActionsIcon'} />
              </Popconfirm>
            </div>
          )}
        />
      </Table>
      <Modal
        visible={addNewSubjectModal}
        closable={false}
        onCancel={() => {
          closeAddNewItemModal()
        }}
        footer={null}
      >
        <div>
          <div
            onClick={() => {
              closeAddNewItemModal()
            }}
            className={'settingsSubjectEditModalCloseIconCont'}
          >
            <IoMdClose />
          </div>
          <Typography className={'settingsSubjectEditModalTitle'}>
            Ta’lim muassasasi qo‘shish
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'name'}>
              Nomi
            </label>
            <Input
              id={'name'}
              value={addNewUniversity.name}
              onChange={(e) => {
                setAddNewUniversity({
                  ...addNewUniversity,
                  name: e.target.value,
                })
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'address'}>
              Manzil
            </label>
            <Input
              value={addNewUniversity.address}
              id={'address'}
              onChange={(e) => {
                setAddNewUniversity({
                  ...addNewUniversity,
                  address: e.target.value,
                })
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'map'}>
              Xarita
            </label>
            <Input
              value={addNewUniversity.map}
              id={'map'}
              onChange={(e) => {
                setAddNewUniversity({
                  ...addNewUniversity,
                  map: e.target.value,
                })
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'phone'}>
              Aloqa
            </label>
            <Input
              value={addNewUniversity.phone}
              id={'phone'}
              placeholder={'+998XXXXXXXXX'}
              onChange={(e) => {
                setAddNewUniversity({
                  ...addNewUniversity,
                  phone: e.target.value,
                })
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <input
              type="file"
              accept="image/gif, image/jpeg, image/png"
              onChange={(e) => {
                let files = e.target.files
                if (files.length === 1 && validateUpload(e)) {
                  upload(e, 'new')
                }
              }}
              style={{ display: 'none' }}
              id="ImgUpload"
            />
            <label htmlFor="ImgUpload" className="FlatEditUploadImgButton">
              <BiImage className="FlatEditUploadImgIcon" />
              <span>Rasm</span>
            </label>
            {addNewUniversity.photo ? (
              imgPreview(addNewUniversity.photo, 40)
            ) : (
              <img
                alt="university"
                style={{
                  height: '50px',
                }}
                src={
                  'https://www.diabetesqualified.com.au/wp-content/uploads/2016/11/noImg.jpg'
                }
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              type="primary"
              className={'settingsEditModalSaveButton'}
              onClick={() => {
                if (
                  addNewUniversity.name &&
                  addNewUniversity.map &&
                  addNewUniversity.address &&
                  addNewUniversity.phone
                ) {
                  const formData = new FormData()
                  formData.append(
                    'university',
                    JSON.stringify({
                      name: addNewUniversity.name,
                      map: addNewUniversity.map,
                      address: addNewUniversity.address,
                      phone: addNewUniversity.phone,
                    })
                  )
                  if (addNewUniversity.photo) {
                    formData.append('image', addNewUniversity.photo)
                  }
                  addNewItemFunc(formData)
                } else {
                  message.error("Ma'lumotlar xato to'ldirilgan")
                }
              }}
            >
              Saqlash
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={openEditModal}
        closable={false}
        onCancel={() => {
          setOpenEditModal(false)
        }}
        footer={null}
      >
        <div>
          <div
            onClick={() => {
              setOpenEditModal(false)
            }}
            className={'settingsSubjectEditModalCloseIconCont'}
          >
            <IoMdClose />
          </div>
          <Typography
            className={'settingsSubjectEditModalTitle'}
            style={{ fontSize: '1.7vw' }}
          >
            Ta’lim muassasasini o'zgartirish
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'name'}>
              Nomi
            </label>
            <Input
              id={'name'}
              value={editData.name}
              onChange={(e) => {
                setEditData({
                  ...editData,
                  name: e.target.value,
                })
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'address'}>
              Manzil
            </label>
            <Input
              value={editData.address}
              id={'address'}
              onChange={(e) => {
                setEditData({
                  ...editData,
                  address: e.target.value,
                })
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'map'}>
              Xarita
            </label>
            <Input
              value={editData.map}
              id={'map'}
              onChange={(e) => {
                setEditData({
                  ...editData,
                  map: e.target.value,
                })
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              margin: '1vw auto',
            }}
          >
            <label className={'nameEditInputLabel'} htmlFor={'phone'}>
              Aloqa
            </label>
            <Input
              value={editData.phone}
              id={'phone'}
              placeholder={'+998XXXXXXXXX'}
              onChange={(e) => {
                setEditData({
                  ...editData,
                  phone: e.target.value,
                })
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <input
              type="file"
              accept="image/gif, image/jpeg, image/png"
              onChange={(e) => {
                let files = e.target.files
                if (files.length === 1 && validateUpload(e)) {
                  upload(e, 'change')
                }
              }}
              style={{ display: 'none' }}
              id="ImgUpload"
            />
            <label htmlFor="ImgUpload" className="FlatEditUploadImgButton">
              <BiImage className="FlatEditUploadImgIcon" />
              <span>Rasm</span>
            </label>
            {editData.image ? (
              imgPreview(editData.image, 40)
            ) : (
              <img
                alt="university"
                style={{
                  height: '50px',
                }}
                src={
                  'https://www.diabetesqualified.com.au/wp-content/uploads/2016/11/noImg.jpg'
                }
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              type="primary"
              className={'settingsEditModalSaveButton'}
              onClick={() => {
                // console.log(editData)
                if (editData.image) {
                  if (
                    editData.name &&
                    editData.map &&
                    editData.address &&
                    editData.phone &&
                    isFile(editData.image)
                  ) {
                    const formData = new FormData()
                    formData.append(
                      'university',
                      JSON.stringify({
                        name: editData.name,
                        map: editData.map,
                        address: editData.address,
                        phone: editData.phone,
                        id: editData.id,
                      })
                    )
                    formData.append('image', editData.image)
                    editUniversityFunc(formData)
                  } else if (
                    editData.name &&
                    editData.map &&
                    editData.address &&
                    editData.phone &&
                    !isFile(editData.image)
                  ) {
                    const formData = new FormData()
                    formData.append(
                      'university',
                      JSON.stringify({
                        name: editData.name,
                        map: editData.map,
                        address: editData.address,
                        phone: editData.phone,
                        id: editData.id,
                        image: editData.image,
                      })
                    )
                    editUniversityFunc(formData)
                  } else {
                    message.error("Ma'lumotlar xato to'ldirilgan")
                  }
                } else {
                  if (
                    editData.name &&
                    editData.map &&
                    editData.address &&
                    editData.phone
                  ) {
                    const formData = new FormData()
                    formData.append(
                      'university',
                      JSON.stringify({
                        name: editData.name,
                        map: editData.map,
                        address: editData.address,
                        phone: editData.phone,
                        id: editData.id,
                      })
                    )
                    editUniversityFunc(formData)
                  } else {
                    message.error("Ma'lumotlar xato to'ldirilgan")
                  }
                }
              }}
            >
              Saqlash
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default University
