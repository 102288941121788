
class LoginService {
    login(input) {
        if(input.auth_key) {
            localStorage.setItem("user", JSON.stringify({...input}));
            return true
        } else {
            return false
        }
    }

    logOut() {
        localStorage.removeItem('user')
    }

    getUser() {
        return JSON.parse(localStorage.getItem("user"))
    }

    isLogIn() {
        const user = this.getUser()
        return !!user;
    }
}

export default new LoginService();
