import React, { Component } from 'react'
import { Button } from 'antd'
import { AiOutlinePlus } from 'react-icons/ai'
import { connect } from 'react-redux'

import Request from '../../../service/request/Request'
import UsersModal from './UsersModal'
import { actions } from '../../../redux/actions'
import UserTable from './UserTable'

const { addUser } = actions

class UsersBody extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedPage: 'Superadmin',
      editItem: false,
      loading: false,
      moment: null,
      inputToModal: { creating: true },
    }

    this.selectPageFunc = this.selectPageFunc.bind(this)

    this.toggleModal = this.toggleModal.bind(this)
    this.handelSubmit = this.handelSubmit.bind(this)
    this.handleAddNewUser = this.handleAddNewUser.bind(this)
  }

  selectPageFunc(page) {
    switch (page) {
      case 'Superadmin':
        this.setState({
          selectedPage: page,
        })
        break

      case 'Admin':
        this.setState({
          selectedPage: page,
        })
        break
      case 'teacher':
        this.setState({
          selectedPage: page,
        })
        break
      case 'student':
        this.setState({
          selectedPage: page,
        })
        break

      default:
        this.setState({
          data: null,
        })
        break
    }
  }

  showSelectPageFunc() {
    switch (this.state.selectedPage) {
      case 'Superadmin':
        return <UserTable role={2} roleTitle="Superadmin" />

      case 'Admin':
        return <UserTable role={3} roleTitle="Superadmin" />

      case 'teacher':
        return <UserTable role={4} roleTitle="O'qituvchi" />

      case 'student':
        return <UserTable role={5} roleTitle="O'quvchi" />

      default:
        return <div>Page not fount</div>
    }
  }

  handelSubmit(inputData = {}, image) {
    let role
    if (this.state.selectedPage === 'Superadmin') {
      // console.log('superadmin role')
      role = 2
    } else if (this.state.selectedPage === 'Admin') {
      // console.log('Admin role')
      role = 3
    } else if (this.state.selectedPage === 'teacher') {
      // console.log('Admin role')
      role = 4
    } else if (this.state.selectedPage === 'student') {
      // console.log('Admin role')
      role = 5
    }
    const formData = new FormData()
    formData.append('user', JSON.stringify(inputData))
    formData.append('role', role)
    formData.append('image', image)
    Request.post('/users-create', formData)
      .then((res) => {
        console.log(res.data)
        this.props.addUser({
          ...inputData,
          image,
          status: 1,
          id: res.data.id,
          usersIndex: null,
        })
        this.toggleModal()
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  toggleModal() {
    this.setState({ editItem: !this.state.editItem })
    // console.log(this.state.editItem)
  }

  handleAddNewUser() {
    this.setState({ loading: true })
    Request.get('/address-city').then((res) => {
      console.log(res.data.cities)
      this.setState({
        inputToModal: { ...this.state.inputToModal, cities: res.data.cities },
      })
      this.setState({ loading: false })
      this.toggleModal()
    })
  }

  render() {
    const { selectedPage } = this.state
    const selectPageFunc = this.selectPageFunc
    return (
      <div className={'bodyCont'}>
        <div className={'settingsMenuCont'}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              className={
                selectedPage === 'Superadmin'
                  ? 'settingsMenuItem active'
                  : 'settingsMenuItem'
              }
              onClick={() => selectPageFunc('Superadmin')}
            >
              Super Admin
            </div>
            <div
              className={
                selectedPage === 'Admin'
                  ? 'settingsMenuItem active'
                  : 'settingsMenuItem'
              }
              onClick={() => selectPageFunc('Admin')}
            >
              Admin
            </div>
            <div
              className={
                selectedPage === 'teacher'
                  ? 'settingsMenuItem active'
                  : 'settingsMenuItem'
              }
              onClick={() => selectPageFunc('teacher')}
            >
              O'qituvchi
            </div>
            <div
              className={
                selectedPage === 'student'
                  ? 'settingsMenuItem active'
                  : 'settingsMenuItem'
              }
              onClick={() => selectPageFunc('student')}
            >
              O'quvchi
            </div>
          </div>
          <Button
            loading={this.state.loading}
            icon={<AiOutlinePlus />}
            onClick={this.handleAddNewUser}
            className={'usersPlusBtn'}
          />
        </div>
        {this.showSelectPageFunc()}
        <UsersModal
          title={`${this.state.selectedPage} Qo'shish`}
          isOpen={this.state.editItem}
          toggleModal={this.toggleModal}
          inputData={this.state.inputToModal}
          handleSubmit={this.handelSubmit}
        />
      </div>
    )
  }
}

const mapDispatchToProps = {
  addUser,
}

export default connect(null, mapDispatchToProps)(UsersBody)
