import React from 'react';
import {Typography} from "antd";
import SettingsBody from "./SettingsBody";

import "./Settings.css"

const Settings = () => {
  return (
    <div>
      <Typography className={"sectionTitle"} style={{marginTop: '3vh'}}>
        Sozlamalar
      </Typography>
      <SettingsBody/>
    </div>
  );
}

export default Settings;
