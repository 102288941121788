const types = {
  QUESTION_ADD: "QUESTION/ADD",
  QUESTION_CLEAR: "QUESTION/CLEAR",
  QUESTION_REMOVE: "QUESTION/REMOVE",
  QUESTION_PHOTO_REMOVE: "QUESTION/PHOTO_REMOVE",
  QUESTION_CHANGE: "QUESTION/CHANGE",
  QUESTION_CONFIRM: "QUESTION/CONFIRM",
  QUESTION_SET_IMPORT: "QUESTION/SET_IMPORT",
  QUESTION_SET_FETCHED: "QUESTION/SET_FETCHED",
  QUESTION_EDIT: "QUESTION/EDIT",

  // USERS TYPES //
  USERS_FETCH: "USERS/FETCH",
  USERS_DELETE: "USERS/DELETE",
  USERS_ADD: "USERS/ADD",
  USERS_CHANGE_STATUS: "USERS/STATUS_CHANGE",

  // Settings types //
  SETTINGS_OPEN_CREATE_MODAL: "SETTINGS_OPEN_CREATE_MODAL",

  // Meta types //
  META_CHANGE_SECTION: "META_CHANGE_SECTION",
  META_GET_SUBJECTS: "META_GET_SUBJECTS",
  META_GET_TESTS: "META_GET_TESTS",
  META_SUBJECTS: "META_SUBJECTS",
  META_TESTS: "META_TESTS",
  META_PAGES: "META_PAGES",
  META_BACK_TO_INITIAL: "META_BACK_TO_INITIAL"
};

export const TOGGLE_STATS_MODAL = "STATS/TOGGLE_MODAL";
export const STATS_SET_TEST_STATS = "STATS/SET_TEST_STATS";
export const STATS_SET_TEST_TYPE = "STATS/SET_TEST_TYPE";
export const STATS_SET_TEST_SUBJECT = "STATS/SET_TEST_SUBJECT";
export const STATS_FETCH_STAFF = "STATS/FETCH_STAFF";
export const STATS_GET_STAFF = "STATS/GET_STAFF";
export const STATS_GET_STAFF_MODAL = "STATS/GET_STAFF_MODAL";
export const SET_FILTER_DATA = "SET_FILTER_DATA";
export const CLEAR_FILTER_DATA = "CLEAR_FILTER_DATA";
export const STATS_CLEAR_STAFF_MODAL = "STATS_CLEAR_STAFF_MODAL";

export const ARTICLE_ID = "ARTICLE_ID";

export default types;
