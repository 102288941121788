import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TestTableInModal from './TestTableInModal'

const StatsTableModal = ({ isModalOpen, handleCloseModal }) => {
  const [tableType, setTableType] = useState('dtm')
  const [testData, setTestData] = useState([])
  const [currentCount, setCurrentCount] = useState(0)
  const staffTableData = useSelector((state) => state.stats.staffTableData)
  const handleTypeToggle = (type) => {
    setTableType(type)
    setTestData(staffTableData[type])
    setCurrentCount(staffTableData[type + '_total'])
  }

  useEffect(() => {
    setTestData(staffTableData[tableType])
    setCurrentCount(staffTableData[tableType + '_total'])
    return () => setTestData([])
  }, [staffTableData, tableType])

  return (
    <Modal
      className="stats_modal modal"
      visible={isModalOpen}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      footer={false}
      title={false}
    >
      <div className={'stats_modal_body'}>
        <div className="modal_controls">
          <div
            className={`${tableType === 'dtm' ? 'active' : ''} settingsMenuItem`}
            onClick={() => handleTypeToggle('dtm')}
          >
            Dtm
          </div>
          <div
            className={`${tableType === 'virtual' ? 'active' : ''} settingsMenuItem`}
            onClick={() => handleTypeToggle('virtual')}
          >
            Virtual
          </div>
          <div
            className={`${tableType === 'mavzuli' ? 'active' : ''} settingsMenuItem`}
            onClick={() => handleTypeToggle('mavzuli')}
          >
            Mavzuli
          </div>
        </div>
        <div className="modal_info">
          <p>{staffTableData?.name}</p>
          <p>{`Jami: ${currentCount} ta`}</p>
        </div>
        <TestTableInModal type={tableType} data={testData} />
      </div>
    </Modal>
  )
}

export default StatsTableModal
