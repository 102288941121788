import React, { Component } from "react";
import { message, Select, Typography } from "antd";
// import {AiOutlineSearch} from "react-icons/all";

// import wrongQuestionsData from './WrongQuestionsTable/WrongQuestions.json'
import WrongQuestionsTable from "./WrongQuestionsTable/WrongQuestionsTable";
import Request from "../../../service/request/Request";
import "../settings/Settings.css";
import "../users/Users.css";
import "./wrongQuestions.css";
// const { Option } = Select

class WrongQuestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editItem: false,
      data: [],
      switchLoading: [],
      tableLoading: true,
      allSubject: []
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleStatusSwitch = this.handleStatusSwitch.bind(this);
  }
  componentDidMount() {
    Request.get("/feedback-all").then((res) => {
      this.setState({ data: res.data.feedbacks, tableLoading: false });
    });
    Request.get("/subject-all").then((res) => {
      this.setState({ allSubject: res.data.subjects });
    });
  }

  handleDelete = (index, id) => {
    const copy = this.state.data.slice();
    copy.splice(index, 1);
    Request.post("/feedback-delete", {
      id: this.state.data[index].id
    }).then((res) => {
      message.success(res.data.message);
      this.setState({ data: copy });
    });
  };

  handleStatusSwitch = (index, id) => {
    const copy = this.state.data.slice();
    const switchLoading = this.state.switchLoading.slice();
    copy[index].status = parseInt(copy[index].status) > 0 ? 0 : 1;
    switchLoading[index] = true;
    this.setState({ switchLoading });

    Request.post("/feedback-change-status", {
      id,
      status: copy[index].status
    }).then((res) => {
      message.info(res.data.message);
      this.setState({ data: copy });
      switchLoading[index] = false;
      this.setState({ switchLoading });
    });
  };

  handleChangeSubject = (value) => {
    this.setState({ tableLoading: true });
    Request.get(`/feedback-all?subject_id=${value}`).then((res) => {
      this.setState({ data: res.data.feedbacks, tableLoading: false });
    });
  };

  render() {
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: "40px",
            width: "80vw"
          }}
        >
          <Typography className={"sectionTitle"} style={{ marginTop: "3vh" }}>
            Xatoliklar
          </Typography>
          <Select
            placeholder="Fanlardan birini tanlang"
            style={{
              width: "220px"
            }}
            onChange={this.handleChangeSubject}
          >
            {this.state.allSubject.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={"bodyCont"}>
          <WrongQuestionsTable
            switchLoading={this.state.switchLoading}
            tableLoading={this.state.tableLoading}
            handleDelete={this.handleDelete}
            data={this.state.data}
            handleStatusSwitch={this.handleStatusSwitch}
          />
        </div>
      </div>
    );
  }
}

export default WrongQuestions;
