import { ARTICLE_ID } from "./types";

const initialState = {
  data: {}
};

const articleIdReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ARTICLE_ID:
      return { ...state, data: payload };
    default:
      return state;
  }
};

export default articleIdReducer;
