// import Request from '../service/request/Request'
import types from './types'

const { USERS_FETCH, USERS_DELETE, USERS_ADD, USERS_CHANGE_STATUS } = types

export const usersReducer = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case USERS_FETCH:
      return payload

    case USERS_DELETE:
      const copy = state.slice()
      copy.splice(payload, 1)
      return copy

    case USERS_ADD:
      const copy1 = state.slice()
      if (payload.index !== null) {
        copy1.splice(payload.index, 1, payload.data)
        return copy1
      }
      copy1.push(payload.data)
      return copy1

    case USERS_CHANGE_STATUS:
      const copy2 = state.slice()
      // console.log('paylad.status', payload.status)
      copy2.splice(payload.index, 1, {
        ...copy2[payload.index],
        status: payload.status,
      })
      return copy2

    default:
      return state
  }
}
