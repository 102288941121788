import { message } from "antd";
import { addIdToData, renameData } from "../service/common/addIdToData";
import { arrayReducer } from "../service/common/arrayReducer";
import { isEmptyObject } from "../service/common/isEmptyObject";
import Request from "../service/request/Request";
import types, {
  TOGGLE_STATS_MODAL,
  STATS_SET_TEST_STATS,
  STATS_SET_TEST_TYPE,
  STATS_SET_TEST_SUBJECT,
  STATS_FETCH_STAFF,
  STATS_GET_STAFF_MODAL,
  SET_FILTER_DATA,
  CLEAR_FILTER_DATA,
  STATS_CLEAR_STAFF_MODAL,
  ARTICLE_ID
} from "./types";
const {
  QUESTION_ADD,
  QUESTION_CLEAR,
  QUESTION_REMOVE,
  QUESTION_PHOTO_REMOVE,
  QUESTION_CHANGE,
  QUESTION_CONFIRM,
  QUESTION_SET_IMPORT,
  QUESTION_SET_FETCHED,
  QUESTION_EDIT,
  // users page types
  USERS_FETCH,
  USERS_DELETE,
  USERS_ADD,
  USERS_CHANGE_STATUS
  //stats page types
} = types;

const OPERATOR_STATS_URL = "/operator-statistics";
const OPERATOR_STATS_MODAL_URL = "/operator-statistics-modal";

const setFilterData = (filter) => ({
  type: SET_FILTER_DATA,
  payload: { filter }
});
export const toggleStatsModal = (isOpen) => ({
  type: TOGGLE_STATS_MODAL,
  payload: { isOpen }
});

export const getSortedData = (filter) => {
  return async (dispatch) => {
    try {
      dispatch(setFilterData(filter));
      const { data } = await Request.post(OPERATOR_STATS_URL, filter);
      const result = addIdToData(data.data);
      dispatch({
        type: STATS_FETCH_STAFF,
        payload: { tests_for_type: result }
      });
    } catch (e) {
      console.error(e.message);
    }
  };
};

export const fetchStaffStats = () => {
  return async (dispatch) => {
    try {
      const { data } = await Request.get(OPERATOR_STATS_URL);
      const result = addIdToData(data.tests_for_type);
      dispatch({
        type: STATS_FETCH_STAFF,
        payload: { tests_for_type: result }
      });
    } catch (e) {
      console.error(e.message);
    }
  };
};

const actions = {
  TEST_STATS_URL: "/test-type-statistics",
  QUESTION_STATS_URL: "/question-statistics",

  addBlankQuestion(question) {
    return {
      type: QUESTION_ADD,
      payload: question
    };
  },

  clearOnUnmount(initialState = []) {
    return {
      type: QUESTION_CLEAR,
      payload: initialState
    };
  },

  removeQuestion(index) {
    return {
      type: QUESTION_REMOVE,
      payload: { index }
    };
  },

  removeQuestionPhoto(index, targetName) {
    return {
      type: QUESTION_PHOTO_REMOVE,
      payload: { index, targetName }
    };
  },

  saveQuestionChange(input = {}, index) {
    return {
      type: QUESTION_CHANGE,
      payload: { index, input }
    };
  },

  confirmQuestion(index) {
    return {
      type: QUESTION_CONFIRM,
      payload: { index }
    };
  },

  saveImportQuestion(questions) {
    return (dispatch) => {
      dispatch({
        type: QUESTION_SET_IMPORT,
        payload: { questions }
      });
    };
  },

  saveFetchedQuestion(index, question) {
    return {
      type: QUESTION_SET_FETCHED,
      payload: { index, question }
    };
  },

  setEditingQuestions(data) {
    return {
      type: QUESTION_EDIT,
      payload: { data }
    };
  },
  //not good practice   question EDIT ACTION
  setSelect(data) {
    return {
      type: "EDIT_SELECTS",
      payload: data
    };
  },

  // USERS ACTIONS
  setUsers(role = 0) {
    if (role > 0) {
      return async (dispatch) => {
        try {
          const res = await Request.post("/users-all", { role });
          dispatch({ type: USERS_FETCH, payload: res.data.users });
        } catch (e) {
          console.log(e.message);
        }
      };
    }
    return { type: USERS_FETCH, payload: [] };
  },

  deleteUser(index, id, role) {
    return async (dispatch) => {
      try {
        const res = await Request.post("/users-delete", {
          role,
          id: parseInt(id)
        });
        dispatch({ type: USERS_DELETE, payload: index });
        message.success(res.data.message);
      } catch (e) {
        console.log(e.message);
      }
    };
  },

  addUser(data) {
    return {
      type: USERS_ADD,
      payload: { data, index: data.usersIndex }
    };
  },

  setUsersStatus({ role, id, value, index }) {
    return async (dispatch) => {
      try {
        const res = await Request.post("/users-change-status", {
          role,
          id,
          status: value
        });
        dispatch({
          type: USERS_CHANGE_STATUS,
          payload: { status: res.data.status, index }
        });
        message.info(res.data.message);
      } catch (err) {
        console.log(err.message);
      }
    };
  },

  getTestStats(filter, url) {
    return async (dispatch) => {
      try {
        const { data } = await Request.post(url, filter);
        if (filter.sort === "test") {
          const result = addIdToData(renameData(data.data));
          dispatch({
            type: STATS_SET_TEST_TYPE,
            payload: { tests_for_type: result }
          });
        } else if (filter.sort === "subject") {
          const resultSubject = addIdToData(data.data);
          dispatch({
            type: STATS_SET_TEST_SUBJECT,
            payload: { tests_for_subject: resultSubject }
          });
        }
      } catch (e) {
        console.error(e.message);
      }
    };
  },

  fetchTestStats(url) {
    return async (dispatch) => {
      try {
        const {
          data: { tests_for_type, tests_for_subject }
        } = await Request.get(url);
        const resultType = addIdToData(renameData(tests_for_type));
        const resultSubject = addIdToData(tests_for_subject);

        dispatch({
          type: STATS_SET_TEST_STATS,
          payload: {
            tests_for_type: resultType,
            tests_for_subject: resultSubject
          }
        });
      } catch (e) {
        console.error(e.message);
      }
    };
  },

  clearFilter: (fieldName) => {
    return (dispatch, getStats) => {
      const { filterData } = getStats()?.stats;

      if (fieldName === "date") {
        delete filterData?.begin;
        delete filterData?.end;
      } else if (fieldName === "subject") {
        delete filterData?.subject;
      }
      dispatch({ type: CLEAR_FILTER_DATA, payload: { filterData } });

      if (isEmptyObject(filterData)) {
        dispatch(fetchStaffStats());
      } else {
        dispatch(getSortedData(filterData));
      }
    };
  },

  getModalData(user_id, name) {
    return async (dispatch, getState) => {
      try {
        const { filterData } = getState().stats;
        const { data } = await Request.post(OPERATOR_STATS_MODAL_URL, {
          ...filterData,
          user_id
        });
        const dtm_total = arrayReducer(data.dtm, "question_count");
        const virtual_total = arrayReducer(data.virtual, "question_count");
        const mavzuli_total = arrayReducer(data.mavzuli, "question_count");
        dispatch({
          type: STATS_GET_STAFF_MODAL,
          // payload: { data },
          payload: {
            data: {
              ...data,
              dtm_total: dtm_total,
              virtual_total: virtual_total,
              mavzuli_total: mavzuli_total,
              name: name
            }
          }
        });
        dispatch(toggleStatsModal(true));
      } catch (e) {
        console.error(e.message);
      }
    };
  },
  clearModalData() {
    return { type: STATS_CLEAR_STAFF_MODAL };
  },

  articleIdAction(data) {
    return {
      type: ARTICLE_ID,
      payload: data
    };
  }
};

export { actions };
