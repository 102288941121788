import { combineReducers } from "redux";
import { questionReducer } from "./questionReducer";
import { selectReducer } from "./selectReducer";
import statsReducer from "./statsReducer";
import { usersReducer } from "./usersReducer";
import settingsReducer from "./settingsReducer";
import metaReducer from "./metaReducer";
import articleIdReducer from "./articleIdReducer";

export const rootReducer = combineReducers({
  metaCont: metaReducer,
  questions: questionReducer,
  selectValues: selectReducer,
  users: usersReducer,
  stats: statsReducer,
  settings: settingsReducer,
  articleIdValue: articleIdReducer
});

export type RootState = ReturnType<typeof rootReducer>;
