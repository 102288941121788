import React, { useState, useEffect } from 'react'
import { Button, message, Popconfirm, Switch, Table } from 'antd'
import Column from 'antd/es/table/Column'
import { AiOutlineDelete, BsPencil } from 'react-icons/all'

import { actions } from '../../../redux/actions'
import { connect } from 'react-redux'
import UsersModal from './UsersModal'
import Request from '../../../service/request/Request'

const { setUsers, deleteUser, setUsersStatus, addUser } = actions
//identifier for superadmin role
// const role = 3

const UserTable = (props) => {
  const { users, setUsers, deleteUser, role, roleTitle, setUsersStatus, addUser } =
    props
  const [loading, setLoading] = useState(false)
  const [editingUser, setEditingUser] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    setLoading(true)
    setUsers(role)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    return () => {
      setUsers(0)
    }
  }, [setUsers, role])

  const handleEdit = (index, userId) => {
    Request.post('/users-one', { role, id: parseInt(userId) }).then((res) => {
      Request.get('/address-city').then((cityResponse) => {
        setEditingUser({
          ...res.data.user,
          ...res.data.address,
          cities: cityResponse.data.cities,
          usersIndex: index,
        })
        toggleModal()
      })
    })
  }

  const handleDelete = (index, userId) => {
    deleteUser(index, userId, role)
  }

  const handleSave = (data = {}, image) => {
    const formData = new FormData()
    formData.append('user', JSON.stringify(data))
    formData.append('role', role)
    formData.append('image', image)

    Request.post('/users-create', formData)
      .then((res) => {
        addUser({ ...data, status: users[data.usersIndex].status })
        message.success(res.data.message)
        toggleModal()
      })
      .catch((err) => {
        console.log(err.message)
        toggleModal()
      })
  }

  const handleStatusChange = (value, index, id) => {
    setUsersStatus({ value: value > 0 ? 0 : 1, index, id, role })
    // console.log(value, index)
  }

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev)
  }

  return (
    <div>
      <Table
        loading={loading}
        showSizeChanger={true}
        className="table-action-cell"
        size={'middle'}
        dataSource={users}
        scroll={{ y: '53vh' }}
        pagination={{ position: ['none', 'bottomLeft'] }}
        style={{ width: '100%', padding: '3%' }}
      >
        <Column
          title="№"
          key="id"
          render={(record, _, index) => <span>{index + 1}</span>}
        />
        <Column
          title="To'liq Ismi"
          key="full_name"
          render={(record) => <span>{record.full_name}</span>}
        />
        <Column
          title="Login"
          key="login"
          render={(record) => <span>{record.login}</span>}
        />
        <Column
          title="Status"
          key="status"
          render={(record, _, index) => {
            const integerStatus = parseInt(record.status)
            return (
              <Switch
                size="small"
                checked={integerStatus}
                onChange={() => {
                  handleStatusChange(integerStatus, index, record.id)
                }}
              />
            )
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(_, record, index) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              <Button
                // loading={editLoading[index]}
                onClick={() => {
                  handleEdit(index, record.id)
                }}
                icon={<BsPencil />}
                className={'btn'}
              />
              <Popconfirm
                title={"O'chirasizmi?"}
                okText="Ha"
                cancelText="Yo'q"
                onConfirm={() => {
                  handleDelete(index, record.id)
                }}
              >
                <Button icon={<AiOutlineDelete />} className={'btn'} />
              </Popconfirm>
            </div>
          )}
        />
      </Table>
      <UsersModal
        title={`${roleTitle}ni o'zgartirish`}
        inputData={editingUser}
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        handleSubmit={handleSave}
      />
    </div>
  )
}
const mapStateToProps = (state) => ({
  users: state.users,
})

const mapDispatchToProps = {
  setUsers,
  deleteUser,
  setUsersStatus,
  addUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTable)
