import React from 'react'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { compose, createStore, applyMiddleware } from 'redux'

import './Main.scss'
import TopBar from './TopBar'
import Content from './Content'
import Navbar from './Navbar'
import { rootReducer } from '../redux/rootReducer'

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const enhancer = composeEnhancers(
  applyMiddleware(thunk)
  // other store enhancers if any
)
const store = createStore(rootReducer, enhancer)

function Main() {
  return (
    <Provider store={store}>
      <TopBar />
      <div className={'mainSectionCont'}>
        <Navbar />
        <Content />
      </div>
    </Provider>
  )
}

export default Main
