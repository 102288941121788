import React from 'react'

const StatsDiagram = ({ title, children }) => {
  // console.log(data)
  return (
    <div className="stats_diagram diagram">
      <h1 className="diagram_title">{title}</h1>
      {children}
    </div>
  )
}

export default StatsDiagram
